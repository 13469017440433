import React from "react";

import {
  Container,
  Card,
  CardBody,
  Text,
  Heading,
  CardHeader,
  Button,
  CardFooter,
  Image,
  Stack,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

function MiniMiriDryIncubator() {
  return (
    <Container maxW={"container.lg"}>
      <Card>
        <CardHeader>
          <Heading size="lg" bg={"blue.500"} color={"white"} p={5}>
            {" "}
            Mini MIRI® Dry Incubator
          </Heading>
        </CardHeader>
        <CardBody>
          <Card align="center" mb={20}>
            <CardBody>
              <Text pb={10}>
                The Mini MIRI® Dry incubator is a two-chamber mini bench top
                incubator that takes after the classic MIRI® Multiroom
                incubator. This mini version of the MIRI® is a perfect-fit for
                IVF laboratories that prioritize on footprint and affordability.
              </Text>

              <Link
                to={
                  "https://www.esco-medical.com/products/mini-miri-dry-incubator"
                }
              >
                <Button colorScheme="blue">Get Details</Button>
              </Link>
            </CardBody>
            <CardFooter>
              <Image src="https://www.esco-medical.com/images/upload/Mini-miri-dry-min.png"></Image>
            </CardFooter>
          </Card>

          <Card overflow="hidden" variant="outline" mb={10}>
            <CardHeader>
              <Heading color={"blue.500"}>
                Recirculating Gas for Minimal Gas Consumption
              </Heading>
            </CardHeader>
            <CardBody>
              <Text py="2">
                The Mini MIRI® Dry Incubator is a dry IVF bench top incubator
                that can recirculate gas inside the incubator and is compatible
                with a HEPA/VOC filtration system unlike other humidified IVF
                incubators. Gas inserts (whether pure N2 and CO2 or premixed
                gas) first go through the tri-gas mixing chamber to achieve the
                set CO2 and O2 gas concentrations by the user and then into the
                HEPA/VOC filter before entering the incubator chambers. After
                this, gas now enters a UV Sterilization (254nm) table before
                going back to the tri-gas mixing chamber and recirculates the
                gas. This system ensures that there is minimal gas consumption
                even with lid openings of the incubator chambers while
                maintaining the recirculating air clean and safe for the embryo
                samples.
              </Text>
            </CardBody>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "400px" }}
              src="https://www.esco-medical.com/images/upload/data-logger-software-updated-2023-min.jpg"
              alt="      Data Logger Software
      "
            />

            <Stack>
              <CardBody>
                <Heading size="md" color={"blue.500"}>
                  Data Logger Software
                </Heading>
                <Text py="5">
                  The Mini MIRI® Dry Incubator comes with a data logger software
                  that monitors the temperature, gas concentration, gas input
                  pressure, gas flow rates, and alarms. All real-time parameters
                  can be viewed conveniently in graphs when the Mini MIRI® is
                  connected to a PC and the data logger software can also
                  monitor multiple incubators at the same time. Weekly reports
                  can be exported for the users' convenience.
                </Text>
              </CardBody>
            </Stack>
          </Card>
        </CardBody>
        <CardFooter>
          <Link to={"/Contact"}>
            <Button colorScheme="blue">Contact Us</Button>
          </Link>
        </CardFooter>
      </Card>
    </Container>
  );
}

export default MiniMiriDryIncubator;
