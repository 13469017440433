import React from "react";

import {
  Container,
  Card,
  CardBody,
  Text,
  Heading,
  CardHeader,
  Button,
  CardFooter,
  Image,
  Stack,
  SimpleGrid,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

function MiriEvidence() {
  return (
    <Container maxW={"container.lg"}>
      <Card>
        <CardHeader>
          <Heading size="lg" bg={"blue.500"} color={"white"} p={5}>
            {" "}
            MIRI® Evidence
          </Heading>
        </CardHeader>
        <CardBody>
          <Card align="center" mb={20}>
            <CardBody>
              <Text pb={10}>
                MIRI® Evidence is redefining the way traceability in laboratory
                procedures is managed and stored in fertility clinics,
                eliminating errors, preventing system mix-ups, saving time, and
                helping clinics to comply with regulations. This tool provides a
                single, secure platform that ensures all patients are scheduled
                on time and documented properly - saving time and improving
                outcomes across the board.
              </Text>

              <Link to={"https://www.esco-medical.com/products/miri-evidence"}>
                <Button colorScheme="blue">Get Details</Button>
              </Link>
            </CardBody>
            <CardFooter>
              <Image src="https://www.esco-medical.com/images/product-image/miri-evidence-min.png"></Image>
            </CardFooter>
          </Card>

          <Card overflow="hidden" variant="outline" mb={10}>
            <CardHeader>
              <Heading color={"blue.500"}>
                The ultimate traceability tool for fertility clinics
              </Heading>
            </CardHeader>
            <CardBody>
              <Text py="2">
                The MIRI® Time-Lapse Incubator is a multiroom incubator with a
                built-in camera and microscope. Designed and manufactured in EU,
                the MIRI® Time-Lapse Incubator provides high quality time-lapse
                images of embryos developing in “real-time” without having to
                remove the embryos from the safety of the incubation chamber for
                manual microscopy. Time-lapse embryo monitoring provides
                detailed morphokinetic data throughout embryo development, which
                is not available on routine spot microscopic evaluation. This
                allows all important events to be observed, helping to identify
                healthy embryos with the highest probability of implantation,
                with the aim of achieving higher pregnancy rates.
              </Text>
            </CardBody>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="cover"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.esco-medical.com/images/upload/miri-evidence-integration-min.png"
              alt="Integration
          "
            />

            <Stack>
              <CardBody>
                <Heading size="md" color={"blue.500"}>
                  Integration
                </Heading>
                <Text py="5">
                  Integration with other systems is as essential as it saves
                  time and ensures the quality of exchanged information. The
                  integration points range from patient demographic data,
                  financial input/output, and image transfer to the exchange of
                  laboratory results. The MIRI® Evidence similarly permits live
                  video feed during ovum pickup, ICSI, or embryo transfer to a
                  tablet or a display in the operation theatre.
                </Text>
              </CardBody>
            </Stack>
          </Card>

          <Heading py={10} color={"blue.500"}>
            Key Features of Using MIRI® Evidence
          </Heading>

          <SimpleGrid
            spacing={4}
            templateColumns={[
              "repeat(auto-fill, minmax(200px, 1fr))",
              "repeat(auto-fill, minmax(250px, 1fr))",
            ]}
            mb={20}
          >
            <Card>
              <CardHeader>
                <Heading size="md">Tracking</Heading>
              </CardHeader>
              <CardBody>
                <Text>
                  The scanners used with MIRI® Evidence are 2D imagers with no
                  laser from a market-leading supplier and specially made for
                  healthcare. The scanner can be used to scan wristbands,
                  capture images, and photograph ID cards and passports.
                </Text>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <Heading size="md"> Workflow</Heading>
              </CardHeader>
              <CardBody>
                <Text>
                  The MIRI® Evidence workflow offers tools to enhance scheduling
                  and documentation procedures, improving both clinical and
                  administrative workflows in a fertility clinic. Once a
                  patient's workflow has been started, the system automatically
                  allocates the tasks. The combination of events determines the
                  type of treatment.
                </Text>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <Heading size="md">Compliance</Heading>
              </CardHeader>
              <CardBody>
                <Text>
                  MIRI® Evidence Tracking has been made to comply with the EDQM
                  guidelines and EU regulations. The workflow and tracking
                  system assists the fertility clinic in documenting all
                  procedures in the laboratory and office, thus excluding
                  double-witnessing and saving time.
                </Text>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <Heading size="md">Mix-up prevention</Heading>
              </CardHeader>
              <CardBody>
                <Text>
                  Oocytes, embryos, and semen samples are tracked and validated
                  in and out of the containers eliminating the need for human
                  double-witnessing. The validation guarantees that the correct
                  sample is being processed and prevents patient samples from
                  being mixed up.
                </Text>
              </CardBody>
            </Card>
          </SimpleGrid>
        </CardBody>
        <CardFooter>
          <Link to={"/Contact"}>
            <Button colorScheme="blue">Contact Us</Button>
          </Link>
        </CardFooter>
      </Card>
    </Container>
  );
}

export default MiriEvidence;
