import "./App.css";

import { HashRouter as Router, Routes, Route } from "react-router-dom";

import Header from "./Components/Header/Header";
import Home from "./Components/Home/Home";
import Products from "./Components/Products/Products";
import Services from "./Components/Services/Services";
import Contact from "./Components/Contact/Contact";
import Footer from "./Components/Footer/Footer";

// Sample Preparation
import BiologicalSafetyCabinet from "./Components/Products/Preparation/BiologicalSafetyCabinet";
import AnimalResearchWorkstation from "./Components/Products/Preparation/AnimalResearchWorkstation";
import LaminarFlowCabinet from "./Components/Products/Preparation/LaminarFlowCabinet";
import Centrifuge from "./Components/Products/Preparation/Centrifuge";
import Cytotoxic from "./Components/Products/Preparation/Cytotoxic";

import Co2Incubator from "./Components/Products/Cultivation/Co2Incubator";
import LaboratoryShaker from "./Components/Products/Cultivation/LaboratoryShaker";

import RefrigeratorsAndFreezers from "./Components/Products/Storage/RefrigeratorsAndFreezers";
import LexiconTemperatureFreezer from "./Components/Products/Storage/LexiconTemperatureFreezer";

import Protection from "./Components/Products/Protection/Protection";

import ChemicalResearch from "./Components/Products/Chemical/ChemicalResearch";

import LaboratoryThermostatic from "./Components/Products/Equipment/LaboratoryThermostatic";

// Handling & Analysis
import ThermalCyclerMicroplate from "./Components/Products/Handling&Analysis/ThermalCyclerMicroplate";
import PcrCabinets from "./Components/Products/Handling&Analysis/PcrCabinets";

// IVF Medical
import MultiZoneArt from "./Components/Products/IvfMedical/MultiZoneArt";
import MiriMultiRoom from "./Components/Products/IvfMedical/MiriMultiRoom";
import MiniMiriHumidity from "./Components/Products/IvfMedical/MiniMiriHumidity";
import MiriTimeLapse from "./Components/Products/IvfMedical/MiriTimeLapse";
import TableTopCentrifuge from "./Components/Products/IvfMedical/TableTopCentrifuge";
import CellCultureCO2Incubator from "./Components/Products/IvfMedical/CellCultureCO2Incubator";
import MIRIIIMultiroomIncubator from "./Components/Products/IvfMedical/MIRIIIMultiroomIncubator";
import CultureCoin from "./Components/Products/IvfMedical/CultureCoin";
import MiriEvidence from "./Components/Products/IvfMedical/MiriEvidence";
import MiniMiriDryIncubator from "./Components/Products/IvfMedical/MiniMiriDryIncubator";
import MiriHumidityMultiroom from "./Components/Products/IvfMedical/MiriHumidityMultiroom";

/* ESCO HealthCare */
import EscoHealthcareSolutions from "./Components/Products/ESCOHealthcare/EscoHealthcareSolutions";
import PharmaIsolators from "./Components/Products/ESCOHealthcare/PharmaIsolators";
import AirStreamLaminarFlow from "./Components/Products/IvfMedical/AirStreamLaminarFlow";

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Products" element={<Products />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/Contact" element={<Contact />} />

        {/* Preparation */}
        <Route
          path="/BiologicalSafetyCabinet"
          element={<BiologicalSafetyCabinet />}
        />
        <Route
          path="/AnimalResearchWorkstation"
          element={<AnimalResearchWorkstation />}
        />
        <Route path="/LaminarFlowCabinet" element={<LaminarFlowCabinet />} />
        <Route path="/Centrifuge" element={<Centrifuge />} />
        <Route path="/Cytotoxic" element={<Cytotoxic />} />

        {/* Cultivation */}
        <Route path="/Co2Incubator" element={<Co2Incubator />} />
        <Route path="/LaboratoryShaker" element={<LaboratoryShaker />} />

        {/* Storage */}
        <Route
          path="/RefrigeratorsAndFreezers"
          element={<RefrigeratorsAndFreezers />}
        />
        <Route
          path="/LexiconTemperatureFreezer"
          element={<LexiconTemperatureFreezer />}
        />

        {/* Protection */}
        <Route path="/Protection" element={<Protection />} />

        {/* Chemical Research */}
        <Route path="/ChemicalResearch" element={<ChemicalResearch />} />

        {/* Equipment */}
        <Route
          path="/LaboratoryThermostatic"
          element={<LaboratoryThermostatic />}
        />

        {/* Handling & Analysis */}
        <Route
          path="/ThermalCyclerMicroplate"
          element={<ThermalCyclerMicroplate />}
        />
        <Route path="/PcrCabinets" element={<PcrCabinets />} />

        {/* IVF Medical */}
        <Route path="/MultiZoneArt" element={<MultiZoneArt />} />
        <Route path="/MiriMultiRoom" element={<MiriMultiRoom />} />
        <Route path="/MiniMiriHumidity" element={<MiniMiriHumidity />} />
        <Route path="/MiriTimeLapse" element={<MiriTimeLapse />} />

        <Route path="/TableTopCentrifuge" element={<TableTopCentrifuge />} />
        <Route
          path="/CellCultureCO2Incubator"
          element={<CellCultureCO2Incubator />}
        />
        <Route
          path="/AirStreamLaminarFlow"
          element={<AirStreamLaminarFlow />}
        />
        <Route
          path="/MIRIIIMultiroomIncubator"
          element={<MIRIIIMultiroomIncubator />}
        />

        <Route path="/CultureCoin" element={<CultureCoin />} />
        <Route path="/MiriEvidence" element={<MiriEvidence />} />
        <Route
          path="/MiniMiriDryIncubator"
          element={<MiniMiriDryIncubator />}
        />
        <Route
          path="/MiriHumidityMultiroom"
          element={<MiriHumidityMultiroom />}
        />

        {/* ESCO HealthCare */}
        <Route
          path="/EscoHealthcareSolutions"
          element={<EscoHealthcareSolutions />}
        />
        <Route path="/PharmaIsolators" element={<PharmaIsolators />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
