import {
  Heading,
  Container,
  Image,
  Stack,
  CardBody,
  Text,
  CardFooter,
  Button,
  Card,
  CardHeader,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

function LaboratoryThermostatic() {
  return (
    <Container maxW={"container.lg"}>
      <Card>
        <CardHeader>
          <Heading size="lg" bg={"blue.500"} color={"white"} p={5}>
            {" "}
            Laboratory Thermostatic Products
          </Heading>
        </CardHeader>
        <CardBody>
          <Text mb={20}>
            Thermostatic devices are widely used in research and healthcare
            industry. They are designed to establish and maintain a desired
            temperature automatically or thru manual adjustment by change in
            temperature signals. These devices are relay actuated by thermal
            conduction or convection. Proper maintenance of the unit will ensure
            optimum performance and reduced risk of downtime caused by equipment
            failure.
          </Text>
          {/* Co2 Incubator */}
          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/CCL_HHS_02-min.png"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  CO₂ Incubator
                </Heading>

                <Text mb={10}>
                  Given the precise measurement and control of temperature and
                  CO2 level, as well as the multiple contamination control me.
                </Text>
              </CardBody>

              <CardFooter>
                <a href="https://www.escolifesciences.com/services/co2-incubator">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </a>
              </CardFooter>
            </Stack>
          </Card>

          {/* Lab Oven & Incubator */}
          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/isotherm-natural-convection-lab-incubator.png"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  Lab Oven & Incubator
                </Heading>

                <Text mb={10}>
                  Esco Isotherm® world-class laboratory ovens and incubators—
                  great choice for high-forced volume thermal convection
                </Text>
              </CardBody>

              <CardFooter>
                <a href="https://www.escolifesciences.com/services/lab-oven-and-incubator">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </a>
              </CardFooter>
            </Stack>
          </Card>
        </CardBody>
        <CardFooter>
          <Link to={"Contact"}>
            <Button colorScheme="blue">Contact Us</Button>
          </Link>
        </CardFooter>
      </Card>
    </Container>
  );
}

export default LaboratoryThermostatic;
