import React from "react";

import {
  Container,
  Card,
  CardBody,
  Text,
  Heading,
  CardHeader,
  Button,
  CardFooter,
  Image,
  Stack,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";

import { Link } from "react-router-dom";
import GoToTop from "../../GoToTop/GoToTop";

function AirStreamLaminarFlow() {
  return (
    <Container maxW={"container.lg"}>
      <Card>
        <CardHeader>
          <Heading size="lg" bg={"blue.500"} color={"white"} p={5}>
            {" "}
            AirStream Laminar Flow Bench
          </Heading>
        </CardHeader>
        <CardBody>
          <Card align="center" mb={20}>
            <CardBody>
              <Text mb={10}>
                Vertical laminar flow workstation providing sample protection.
                It offers ISO Class 3 workzone with 10x efficiency of its HEPA
                filters. It has the latest generation of DC ECM motor to improve
                energy efficiency. In Andrology, laminar flow benches can be
                used to handle sperm samples of patients.
              </Text>
              <Link
                to={
                  "https://www.esco-medical.com/products/airstream-laminar-flow-bench#ordering-information"
                }
              >
                <Button colorScheme="blue">Get Details</Button>
              </Link>
            </CardBody>
            <CardFooter>
              <Image src="https://www.esco-medical.com/images/product-image/airstream-laminar-flow-bench.png"></Image>
            </CardFooter>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="cover"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.esco-medical.com/images/upload/product-protection-min.png"
              alt="Product protection"
            />

            <Stack>
              <CardBody>
                <Heading size={"md"} color={"blue.500"}>
                  Product Protection
                </Heading>
                <UnorderedList py={5}>
                  <ListItem>
                    Esco Airstream Laminar Flow Clean Benches have been tested
                    for cross-contamination and product protection using
                    microbiological test method adopted from EN12469
                  </ListItem>
                  <ListItem>
                    ISOCIDE™ Powder Coat (silver-impregnated) that inhibits
                    microbial growth to improve safety.
                  </ListItem>
                  <ListItem>
                    Each clean bench is individually factory tested for safety
                    and performance in accordance with international standards.
                  </ListItem>
                </UnorderedList>
              </CardBody>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="cover"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.esco-medical.com/images/upload/less-energy-more-savings-transparent-min.png"
              alt="Energy efficient"
            />

            <Stack>
              <CardBody>
                <Heading size={"md"} color={"blue.500"}>
                  Energy Efficient
                </Heading>
                <Text>
                  Energy efficient DC ECM Motor offering 70% energy savings as
                  compared with AC Motor. It has a stable airflow despite
                  voltage fluctuations and filter loading.
                </Text>
              </CardBody>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="cover"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.esco-medical.com/images/upload/iso-class-3-workzone-min.jpg"
              alt="Time-Lapse Monitoring"
            />

            <Stack>
              <CardBody>
                <Heading size="md" color={"blue.500"}>
                  ISO Class 3 workzone
                </Heading>
                <Text py="2">
                  10x filtration efficiency of HEPA filter creates ISO Class 3
                  workzone instead of industry-standard ISO Class 5.
                </Text>
              </CardBody>
            </Stack>
          </Card>
        </CardBody>
        <CardFooter>
          <Link to={"/Contact"}>
            <Button colorScheme="blue">Contact Us</Button>
          </Link>
        </CardFooter>
      </Card>
      <GoToTop />
    </Container>
  );
}

export default AirStreamLaminarFlow;
