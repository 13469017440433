import React from "react";
import {
  Container,
  Heading,
  Card,
  CardFooter,
  CardBody,
  CardHeader,
  Text,
  Button,
  ListItem,
  Image,
  Stack,
  UnorderedList,
  SimpleGrid,
  Tr,
  Td,
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Th,
  Tbody,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

function MultiZoneArt() {
  return (
    <Container maxW={"container.lg"}>
      <Card>
        <CardHeader>
          <Heading size="lg" bg={"blue.500"} color={"white"} p={5}>
            {" "}
            IVF Medical
          </Heading>
        </CardHeader>
        <CardBody>
          <SimpleGrid
            spacing={6}
            templateColumns={[
              "repeat(auto-fill, minmax(200px, 1fr))",
              "repeat(auto-fill, minmax(250px, 1fr))",
            ]}
            mb={20}
          >
            <Card>
              <CardBody>
                <Image
                  src="https://nvakins.in/wp-content/uploads/2022/02/multi-zone-art-workstation-front-right-min-hd-600x600.png"
                  alt="Multi-Zone ART Workstation"
                  borderRadius="lg"
                />
                <Stack mt="6" spacing="3">
                  <Heading size="md">Multi-Zone ART Workstation</Heading>
                  <Text>
                    Vertical laminar flow workstations with multiple heating
                    zones that enable precise temperature control across the
                    work surface, with the provision to have a built-in
                    microscope and integrated MIRI® chambers. Available in
                    different sizes and configurations.
                  </Text>
                </Stack>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <Image
                  src="https://www.esco-medical.com/images/upload/multi-zone-heating-system-2-min.jpg"
                  alt="Multi-Zone Heating System"
                  borderRadius="lg"
                />
                <Stack mt="6" spacing="3">
                  <Heading size="md">Multi-Zone Heating System</Heading>
                  <Text>
                    Multiple independent heating zones, each with its own
                    heating elements and sensors, with no crossover heating
                    across zones. Heated glass stage has a dedicated and
                    uninterrupted power supply to further ensure temperature
                    control and recovery.
                  </Text>
                </Stack>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <Image
                  src="https://www.esco-medical.com/images/upload/choice-of-4-temperature-modes-min.png"
                  alt="Choice Of 4 Temperature Modes"
                  borderRadius="lg"
                />
                <Stack mt="6" spacing="3">
                  <Heading size="md">Choice Of 4 Temperature Modes</Heading>
                  <Text>
                    Choice Of 4 Temperature Modes You can turn on all zones,
                    turn off the right zones, turn off the right and middle
                    zones or all zones are turned off.
                  </Text>
                </Stack>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <Image
                  src="https://www.esco-medical.com/images/upload/microscope-integration-provision-2-min.jpg"
                  alt="Microscope Integration Provision"
                  borderRadius="lg"
                />
                <Stack mt="6" spacing="3">
                  <Heading size="md">Microscope Integration Provision</Heading>
                  <Text>
                    Provision to integrate most popular stereoscopic and
                    inverted microscopes.
                  </Text>
                </Stack>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <Image
                  src="https://www.esco-medical.com/images/upload/humidification-system-min.png"
                  alt="Humidification System"
                  borderRadius="lg"
                />
                <Stack mt="6" spacing="3">
                  <Heading size="md">Humidification System</Heading>
                  <Text>
                    Built-in humidification system with digital control of
                    pre-mixed gas. Gas outlet port located on the table surface;
                    used with the provided plastic cover it helps keep samples
                    under controlled conditions
                  </Text>
                </Stack>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <Image
                  src="https://www.esco-medical.com/images/upload/touchscreen-monitor-2-min.jpg"
                  alt="Touchscreen Monitor"
                  borderRadius="lg"
                />
                <Stack mt="6" spacing="3">
                  <Heading size="md">Touchscreen Monitor</Heading>
                  <Text>
                    Fully functional Windows PC with a touch screen provides
                    real-time information on all work area parameters e.g. zone
                    temperature performance, gas pressure and flow rate.
                  </Text>
                </Stack>
              </CardBody>
            </Card>
          </SimpleGrid>

          {/* Table */}

          <TableContainer mb={20}>
            <Table variant="simple">
              <TableCaption bg={"blue.500"} color={"white"}>
                Models
              </TableCaption>
              <Thead>
                <Tr>
                  <Th>MODEL</Th>
                  <Th>Description</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td fontWeight={"extrabold"} color={"blue.500"}>
                    ART Workstation Basic Configuration and Multi-Purpose
                  </Td>
                </Tr>
                <Tr>
                  <Td>MAW-3D8 </Td>
                  <Td>
                    Esco Multi-Zone ART Workstation, 3ft (0.9m), 230V 50/60Hz
                  </Td>
                </Tr>
                <Tr>
                  <Td>MAW-4D8 </Td>
                  <Td>
                    Esco Multi-Zone ART Workstation, 4ft (1.2m), 230V 50/60Hz
                  </Td>
                </Tr>
                <Tr>
                  <Td>MAW-4D9 </Td>
                  <Td>
                    Esco Multi-Zone ART Workstation, 4ft (1.2m), 110V 50/60Hz
                  </Td>
                </Tr>
                <Tr>
                  <Td>MAW-6D8-MONO </Td>
                  <Td>
                    Esco Multi-Zone ART Workstation, 6ft (1.8m), 220V 50/60Hz
                  </Td>
                </Tr>
                <Tr>
                  <Td>MAW-6D9-MONO </Td>
                  <Td>
                    Esco Multi-Zone ART Workstation, 6ft (1.8m), 110V 50/60Hz
                  </Td>
                </Tr>
                <Tr>
                  <Td>MAW-6D8-DUAL </Td>
                  <Td>
                    Esco Multi-Zone ART Workstation, Double Heated Zone, 6ft
                    (1.8m), 220V 50/60Hz
                  </Td>
                </Tr>
                <Tr>
                  <Td>MAW-6D9-DUAL </Td>
                  <Td>
                    Esco Multi-Zone ART Workstation, Double Heated Zone, 6ft
                    (1.8m), 110V 50/60Hz
                  </Td>
                </Tr>
                <Tr>
                  <Td>MAW-6D8-MP </Td>
                  <Td>
                    Esco Multi-Zone ART Workstation, Multi-Purpose, 6ft (1.8m),
                    220V 50/60Hz
                  </Td>
                </Tr>
                <Tr>
                  <Td>MAW-6D9-MP </Td>
                  <Td>
                    Esco Multi-Zone ART Workstation, Multi-Purpose, 6ft (1.8m),
                    110V 50/60Hz
                  </Td>
                </Tr>
                <Tr>
                  <Td fontWeight={"extrabold"} color={"blue.500"}>
                    ART Workstation with MIRI® Chambers
                  </Td>
                </Tr>
                <Tr>
                  <Td>MAW-4D8-MC </Td>
                  <Td>
                    Esco Multi-Zone ART Workstation, MIRI® Chambers, 4ft (1.2m),
                    230V 50/60Hz
                  </Td>
                </Tr>
                <Tr>
                  <Td>MAW-4D9-MC </Td>
                  <Td>
                    Esco Multi-Zone ART Workstation, MIRI® Chambers, 4ft (1.2m),
                    110V 50/60Hz
                  </Td>
                </Tr>

                <Tr>
                  <Td>MAW-6D8-MONO-MC </Td>
                  <Td>
                    Esco Multi-Zone ART Workstation, MIRI® Chambers, 6ft (1.8m),
                    220V 50/60Hz
                  </Td>
                </Tr>

                <Tr>
                  <Td>MAW-6D9-MONO-MC </Td>
                  <Td>
                    Esco Multi-Zone ART Workstation, MIRI® Chambers, 6ft (1.8m),
                    110V 50/60Hz
                  </Td>
                </Tr>

                <Tr>
                  <Td>MAW-6D8-DUAL-MC </Td>
                  <Td>
                    Esco Multi-Zone ART Workstation, Double Heated Zone, MIRI®
                    Chambers, 6ft (1.8m), 220V 50/60Hz
                  </Td>
                </Tr>

                <Tr>
                  <Td>MAW-6D9-DUAL-MC </Td>
                  <Td>
                    Esco Multi-Zone ART Workstation, Double Heated Zone, MIRI®
                    Chambers, 6ft (1.8m), 110V 50/60Hz
                  </Td>
                </Tr>

                <Tr>
                  <Td>MAW-6D8-MP-MC </Td>
                  <Td>
                    Esco Multi-Zone ART Workstation, Multi-Purpose, MIRI®
                    Chambers, 6ft (1.8m), 220V 50/60Hz
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>

          <Heading mb={10} fontSize={"xl"}>
            Recommended List Of Microscopes And Base Stands
          </Heading>
          <UnorderedList>
            <ListItem>
              INVERTED MICROSCOPE FOR MP SERIES WORKSTATION ONLY
            </ListItem>
            <ListItem>Olympus IX83P1ZF: One-Deck</ListItem>
            <ListItem>
              Olympus IX73P1F: One-Deck (Customization on workstation needed)
            </ListItem>
            <ListItem>Olympus IX73P2F: Two-Decks</ListItem>
            <ListItem>
              Olympus IX53P1F: One-Deck (Customization on workstation needed)
            </ListItem>
            <ListItem>Nikon Ti-U</ListItem>
            <ListItem>Nikon Ti-S</ListItem>
            <ListItem>Nikon Ti-E</ListItem>
            <ListItem>Zeiss Axio Vert.A1</ListItem>
            <ListItem>Zeiss Axio Vert.A1 FL</ListItem>
          </UnorderedList>
        </CardBody>
        <CardFooter>
          <Link to={"/Contact"}>
            <Button colorScheme="blue">Contact Us</Button>
          </Link>
        </CardFooter>
      </Card>
    </Container>
  );
}

export default MultiZoneArt;
