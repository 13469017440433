import React from "react";
import {
  Card,
  CardBody,
  Container,
  Text,
  Heading,
  Button,
  SimpleGrid,
  CardHeader,
  CardFooter,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";

import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import GoToTop from "../GoToTop/GoToTop";

function Products() {
  return (
    <Container maxW={"container.lg"}>
      <Card mb={10} bg={"#125076"} color={"white"}>
        <CardBody>
          <Heading textAlign={"center"}>Products</Heading>
        </CardBody>
      </Card>

      <SimpleGrid
        spacing={10}
        templateColumns={[
          "repeat(auto-fill, minmax(200px, 1fr))",
          "repeat(auto-fill, minmax(250px, 1fr))",
        ]}
        mb={20}
      >
        <Card bg={"#0084ff14"}>
          <CardHeader>
            <Heading size="md" color={"blue.500"}>
              {" "}
              Sample Preparation
            </Heading>
          </CardHeader>
          <CardBody>
            <UnorderedList>
              <Link to={"/BiologicalSafetyCabinet"}>
                <ListItem
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  Biological Safety Cabinet
                </ListItem>{" "}
              </Link>
              <Link to="/AnimalResearchWorkstation">
                <ListItem
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  Animal Research Workstation
                </ListItem>
              </Link>
              <Link to="/LaminarFlowCabinet">
                <ListItem
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  Laminar Flow Cabinet
                </ListItem>
              </Link>
              <Link to="/Centrifuge">
                <ListItem
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  Centrifuge
                </ListItem>
              </Link>
              <Link to="/Cytotoxic">
                <ListItem
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  Cytotoxic Cabinet
                </ListItem>
              </Link>
            </UnorderedList>
          </CardBody>
          <CardFooter>
            <Link to={"/Contact"}>
              <Button colorScheme={"blue"}>Get a quote</Button>
            </Link>
          </CardFooter>
        </Card>

        <Card>
          <CardHeader>
            <Heading size="md" color={"blue.500"}>
              {" "}
              Sample Cultivation
            </Heading>
          </CardHeader>
          <CardBody>
            <UnorderedList>
              <Link to={"/Co2Incubator"}>
                <ListItem
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  CO2 Incubators
                </ListItem>
              </Link>
              <Link to={"/LaboratoryShaker"}>
                <ListItem
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  Laboratory Shaker
                </ListItem>
              </Link>
            </UnorderedList>
          </CardBody>
          <CardFooter>
            <Link to={"/Contact"}>
              <Button colorScheme={"blue"}>Get a quote</Button>
            </Link>
          </CardFooter>
        </Card>

        <Card bg={"#0084ff14"}>
          <CardHeader>
            <Heading size="md" color={"blue.500"}>
              {" "}
              Sample Storage
            </Heading>
          </CardHeader>
          <CardBody>
            <UnorderedList>
              <Link to={"/RefrigeratorsAndFreezers"}>
                <ListItem
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  Lab Refrigerators & Freezers
                </ListItem>
              </Link>
              <Link to={"/LexiconTemperatureFreezer"}>
                <ListItem
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  Lexicon® II Ultra-Low Temperature Freezer
                </ListItem>
              </Link>
            </UnorderedList>
          </CardBody>
          <CardFooter>
            <Link to={"/Contact"}>
              <Button colorScheme={"blue"}>Get a quote</Button>
            </Link>
          </CardFooter>
        </Card>

        <Card>
          <CardHeader>
            <Heading size="md" color={"blue.500"}>
              {" "}
              Sample Protection
            </Heading>
          </CardHeader>
          <CardBody>
            <UnorderedList>
              <Link to={"/Protection"}>
                <ListItem
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  Remote Monitoring Datalogging Programming
                </ListItem>
              </Link>

              <Link to={"/Protection"}>
                <ListItem
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  Intelligent Remote Monitoring Application
                </ListItem>
              </Link>

              <Link to={"/Protection"}>
                <ListItem
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  Wireless Monitoring System
                </ListItem>
              </Link>
            </UnorderedList>
          </CardBody>
          <CardFooter>
            <Link to={"/Contact"}>
              <Button colorScheme={"blue"}>Get a quote</Button>
            </Link>
          </CardFooter>
        </Card>

        <Card bg={"#0084ff14"}>
          <CardHeader>
            <Heading size="md" color={"blue.500"}>
              {" "}
              Chemical Research
            </Heading>
          </CardHeader>
          <CardBody>
            <UnorderedList>
              <Link to={"/ChemicalResearch"}>
                <ListItem
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  Filtered Storage Cabinet
                </ListItem>
              </Link>

              <Link to={"/ChemicalResearch"}>
                <ListItem
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  Ducted Fume Hood
                </ListItem>
              </Link>

              <Link to={"/ChemicalResearch"}>
                <ListItem
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  Ductless Fume Hood
                </ListItem>
              </Link>
            </UnorderedList>
          </CardBody>
          <CardFooter>
            <Link to={"/Contact"}>
              <Button colorScheme={"blue"}>Get a quote</Button>
            </Link>
          </CardFooter>
        </Card>

        <Card>
          <CardHeader>
            <Heading size="md" color={"blue.500"}>
              {" "}
              General Equipment
            </Heading>
          </CardHeader>
          <CardBody>
            <UnorderedList>
              <Link to={"/LaboratoryThermostatic"}>
                <ListItem
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  Laboratory Thermostatic Products
                </ListItem>
              </Link>
            </UnorderedList>
          </CardBody>
          <CardFooter>
            <Link to={"/Contact"}>
              <Button colorScheme={"blue"}>Get a quote</Button>
            </Link>
          </CardFooter>
        </Card>

        <Card bg={"#0084ff14"}>
          <CardHeader>
            <Heading size="md" color={"blue.500"}>
              {" "}
              Sample Handling & Analysis
            </Heading>
          </CardHeader>
          <CardBody>
            <UnorderedList>
              <Link to={"/ThermalCyclerMicroplate"}>
                <ListItem
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  Conventional Thermal Cycler Microplate Shaker
                </ListItem>
              </Link>

              <Link to={"/PcrCabinets"}>
                <ListItem
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  PCR Cabinates
                </ListItem>
              </Link>
            </UnorderedList>
          </CardBody>
          <CardFooter>
            <Link to={"/Contact"}>
              <Button colorScheme={"blue"}>Get a quote</Button>
            </Link>
          </CardFooter>
        </Card>

        <Card>
          <CardHeader>
            <Heading size="md" color={"blue.500"}>
              {" "}
              IVF Medical
            </Heading>
          </CardHeader>
          <CardBody>
            <UnorderedList>
              <Link to={"/MultiZoneArt"}>
                <ListItem
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  Multi-Zone ART Workstation
                </ListItem>
              </Link>
              <Link to={"/MiriMultiRoom"}>
                <ListItem
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  MIRI® Multiroom Incubator
                </ListItem>
              </Link>
              <Link to={"/MiniMiriHumidity"}>
                <ListItem
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  Mini MIRI® Humidity Incubator
                </ListItem>
              </Link>
              <Link to={"/MiriTimeLapse"}>
                <ListItem
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  MIRI® Time-Lapse Incubator
                </ListItem>
              </Link>

              <Link to={"/AirStreamLaminarFlow"}>
                <ListItem
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  Airstream Laminar Flow Bench
                </ListItem>
              </Link>

              <Link to={"/TableTopCentrifuge"}>
                <ListItem
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  Table Top Ventilated Centrifuge
                </ListItem>
              </Link>

              <Link to={"/MIRIIIMultiroomIncubator"}>
                <ListItem
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  MIRI II Multiroom Incubator
                </ListItem>
              </Link>

              <Link to={"/CellCultureCO2Incubator"}>
                <ListItem
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  Cell Culture CO₂ Incubator
                </ListItem>
              </Link>

              <Link to={"/CultureCoin"}>
                <ListItem
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  Culture Coin
                </ListItem>
              </Link>

              <Link to={"/MiriEvidence"}>
                <ListItem
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  MIRI Evidence
                </ListItem>
              </Link>

              <Link to={"/MiniMiriDryIncubator"}>
                <ListItem
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  Mini MIRI Dry Incubator
                </ListItem>
              </Link>

              <Link to={"/MiriHumidityMultiroom"}>
                <ListItem
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  MIRI Humidity Multiroom Incubator
                </ListItem>
              </Link>
            </UnorderedList>
          </CardBody>
          <CardFooter>
            <Link to={"/Contact"}>
              <Button colorScheme={"blue"}>Get a quote</Button>
            </Link>
          </CardFooter>
        </Card>

        <Card bg={"#0084ff14"}>
          <CardHeader>
            <Heading size="md" color={"blue.500"}>
              {" "}
              ESCO Healthcare Solutions
            </Heading>
          </CardHeader>
          <CardBody>
            <UnorderedList>
              <Link to={"/EscoHealthcareSolutions"}>
                <ListItem
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  ESCO Pharma
                </ListItem>
              </Link>
              <Link to={"/EscoHealthcareSolutions"}>
                <ListItem
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  Esco TaPestie Rx
                </ListItem>
              </Link>
              <Link to={"/EscoHealthcareSolutions"}>
                <ListItem
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  Esco VacciXcell
                </ListItem>
              </Link>

              <Link to={"/PharmaIsolators"}>
                <ListItem
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  Pharma Isolators
                </ListItem>
              </Link>

              <Link to={"/EscoHealthcareSolutions"}>
                <ListItem
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  Ventilated Balance Enclosure
                </ListItem>
              </Link>

              <Link to={"/EscoHealthcareSolutions"}>
                <ListItem
                  as={motion.div}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  Bioreactor
                </ListItem>
              </Link>
            </UnorderedList>
          </CardBody>
          <CardFooter>
            <Link to={"/Contact"}>
              <Button colorScheme={"blue"}>Get a quote</Button>
            </Link>
          </CardFooter>
        </Card>
      </SimpleGrid>

      <GoToTop />
    </Container>
  );
}

export default Products;
