import {
  Container,
  Heading,
  Card,
  CardHeader,
  CardBody,
  Text,
  CardFooter,
  Button,
  ListItem,
  Box,
  UnorderedList,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

import GoToTop from "../../GoToTop/GoToTop";

function ChemicalResearch() {
  return (
    <Container maxW={"container.lg"}>
      <Card>
        <CardHeader>
          <Heading size="lg" bg={"blue.500"} color={"white"} p={5}>
            {" "}
            Chemical Research
          </Heading>
        </CardHeader>
        <CardBody>
          <Card mb={20}>
            <CardHeader>
              <Heading size="md"> Filtered Storage Cabinet</Heading>
            </CardHeader>
            <CardBody>
              <Text mb={20}>
                Fumes from chemical containers often result in the storage
                cabinet’s corrosion and contaminated air in the laboratory. A
                filtered storage cabinet is equipped with carbon and HEPA
                filters that remove toxic chemical fumes and particles to
                provide operator and environment protection.
              </Text>
              <Box mb={20}>
                <Heading fontSize={"md"} mb={5}>
                  Must-Have Features To Consider:
                </Heading>
                <UnorderedList>
                  <ListItem>
                    VOC sensor that provides audible and visual alarms to alert
                    users for filter saturation
                  </ListItem>
                  <ListItem>
                    User-friendly control system interface for hassle-free
                    operation
                  </ListItem>
                  <ListItem>
                    Durable and chemical-resistant polypropylene trays
                  </ListItem>
                  <ListItem>
                    Large tray capacity to accommodate more chemical bottles
                    with different weights
                  </ListItem>
                  <ListItem>
                    Multiple filter configurations compatible for a wide range
                    of chemical storing
                  </ListItem>
                  <ListItem>
                    Industrial grade main body constructed from E.G. Steel with
                    Isocide™ powder coating
                  </ListItem>
                  <ListItem>
                    Acrylic Glass doors with alarms to notify if doors are left
                    opened.
                  </ListItem>
                  <ListItem>
                    FiltraCheck™ free service to qualify your application’s
                    suitability for a ductless solution
                  </ListItem>
                </UnorderedList>
              </Box>
            </CardBody>
            <CardFooter>
              <Link to={"/Contact"}>
                <Button colorScheme="blue">Contact us</Button>
              </Link>
            </CardFooter>
          </Card>

          <Card mb={20}>
            <CardHeader>
              <Heading size="md" id="DuctedFumeHood">
                {" "}
                Ducted Fume Hood
              </Heading>
            </CardHeader>
            <CardBody>
              <Text>
                A Ducted Fume Hood is a type of ventilation system that
                primarily functions to provide personnel protection against
                toxic fumes, vapors, and dust. This containment device is
                connected to a ducting where contaminants pass before being
                exhausted into the environment. Its secondary function includes
                protection against chemical spills, runaway reactions, and fires
                by acting as a physical barrier.
              </Text>
              <br />
              <Text mb={20}>
                Esco Frontier® Ducted Fume hoods superior containment and
                performance comply with ASHRAE 110 and EN 14175 standards. These
                fume hoods are designed to have precisely tuned aerodynamics,
                total systems and design flexibility, aesthetics and ergonomics
                to provide outstanding user safety and convenience.
              </Text>
              <Box mb={20}>
                <Heading fontSize={"md"} mb={5}>
                  Must-Have Features To Consider:
                </Heading>
                <UnorderedList>
                  <ListItem>
                    Compliance to ASHRAE 110 and EN 14175 for superior fume
                    containment
                  </ListItem>
                  <ListItem>
                    Robust construction for withstanding chemicals even at high
                    temperatures
                  </ListItem>
                  <ListItem>
                    Basic rocker switches for hassle-free operation
                  </ListItem>
                  <ListItem>
                    Variety of worktop configurations to accommodate different
                    applications
                  </ListItem>
                  <ListItem>
                    Offers a broad range of accessories and options
                  </ListItem>
                  <ListItem>
                    Unique baffle design to prevent fume accumulation in
                    workzone
                  </ListItem>
                  <ListItem>
                    Equipped with safety features such as: sash stop limit and
                    airflow monitoring system
                  </ListItem>
                  <ListItem>
                    Ergonomic features such as sash sloping, internal lighting
                  </ListItem>
                  <ListItem>
                    FiltraCheck™ free service to evaluate application and
                    recommend the suitable ducted fume hood model
                  </ListItem>
                </UnorderedList>
              </Box>
            </CardBody>
            <CardFooter>
              <Link to={"/Contact"}>
                <Button colorScheme="blue">Contact us</Button>
              </Link>
            </CardFooter>
          </Card>

          <Card mb={20}>
            <CardHeader>
              <Heading size="md"> Ductless Fume Hood</Heading>
            </CardHeader>
            <CardBody>
              <Text>
                Ductless Fume Hood is a viable alternative to conventional fume
                hoods. This type of fume hood utilizes carbon or HEPA filtration
                to protect laboratory personnel from toxic chemical fumes,
                odors, and particles. Known for its plug-and-play feature and
                mobility, Ductless Fume Hood guarantees a hassle-free
                installation and no lab work interruption.
              </Text>
              <br />
              <Text mb={20}>
                Esco Ascent™ Ductless fume hood is known as the “green” solution
                to Modern Chemistry. This cabinet is equipped with Nanocarb™
                filters which removes chemical vapors from the workzone. Also,
                it has multiple models, configurations, and filter options to
                suit user’s application.
              </Text>
              <Box mb={20}>
                <Heading fontSize={"md"} mb={5}>
                  Must-Have Features To Consider:
                </Heading>
                <UnorderedList>
                  <ListItem>
                    Compliance to ASHRAE 110 for superior fume containment
                  </ListItem>
                  <ListItem>Effective carbon filtration efficiency</ListItem>
                  <ListItem>
                    Easy-to-use control system with alarms for unsafe conditions
                  </ListItem>
                  <ListItem>
                    Wide selection of carbon filter types to accommodate a
                    variety of chemical handling
                  </ListItem>
                  <ListItem>
                    Plug-and-play feature which prevents any laboratory downtime
                  </ListItem>
                  <ListItem>
                    Offers a broad range of accessories and options
                  </ListItem>
                  <ListItem>
                    Industry-unique dual diffuser system that helps optimize
                    carbon filter life
                  </ListItem>
                  <ListItem>
                    Energy-efficient design which reduces total carbon footprint
                  </ListItem>
                  <ListItem>
                    Must be mobile, portable, and easily relocated
                  </ListItem>
                  <ListItem>
                    FiltraCheck™ free service to qualify your application’s
                    suitability for a ductless solution
                  </ListItem>
                </UnorderedList>
              </Box>
            </CardBody>
            <CardFooter>
              <Link to={"/Contact"}>
                <Button colorScheme="blue">Contact us</Button>
              </Link>
            </CardFooter>
          </Card>
        </CardBody>
      </Card>
      <GoToTop />
    </Container>
  );
}

export default ChemicalResearch;
