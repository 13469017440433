import {
  Container,
  Card,
  CardHeader,
  Heading,
  CardBody,
  Text,
  CardFooter,
  Button,
  UnorderedList,
  ListItem,
  Box,
  SimpleGrid,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

import GoToTop from "../../GoToTop/GoToTop";

function Co2Incubator() {
  return (
    <Container maxW={"container.lg"}>
      <Card>
        <CardHeader>
          <Heading size="lg" bg={"blue.500"} color={"white"} p={5}>
            {" "}
            Co2 Incubator
          </Heading>
        </CardHeader>
        <CardBody>
          <Text>
            A CO₂ incubator plays an important role in maintaining a cell
            culture’s sterility, temperature, humidity, and pH—parameters that
            are to maintain the culture’s viability.
          </Text>
          <Text mb={20}>
            Esco CO₂ incubators have top-notch features making it a reliable
            choice to cradle precious cells. We understand that every laboratory
            requires different specifications, hence, various options from
            filter installation, sterilization cycle, UV lamp installation,
            chamber material, external construction material, down to O₂ control
            is available for customers to choose from.
          </Text>

          <Box mb={20}>
            <Heading fontSize={"md"} mb={5}>
              Must-Have Features To Consider:
            </Heading>
            <UnorderedList>
              <ListItem>
                Fast CO₂, temperature, and humidity recovery without overshoot
              </ListItem>
              <ListItem>Best uniformity and control among competition</ListItem>
              <ListItem>
                VentiFlow™ forced convection accelerates recovery of chamber air
                to ISO Class 5 Cleanliness after door closing to prevent
                contamination.
              </ListItem>
              <ListItem>
                Filtered air circulates across the humidity pan to accelerate
                the humidifying process.
              </ListItem>
              <ListItem>
                Air flows gently around culture plates, causing no disturbance
                to cell culture.
              </ListItem>
              <ListItem>
                Blower automatically stops when the door is opened, to minimize
                mixing of chamber and room air.
              </ListItem>
              <ListItem>
                Comprehensive user-configurable alarms for all the parameters.
              </ListItem>
              <ListItem>
                CelAlert™ alarm system reminds the user to replace CO₂ tank and
                ULPA filter.
              </ListItem>
              <ListItem>
                Intelligent data and event logger records all incubator
                parameters for on-screen recall.
              </ListItem>
              <ListItem>
                2 MB built-in flash memory guarantees long term storage of data.
              </ListItem>
              <ListItem>
                Diagnostic interface and online quick help provide comprehensive
                solutions to frequently encountered problems.
              </ListItem>
              <ListItem>
                Ductwork, plenums, and shelves are removable without tools.
              </ListItem>
            </UnorderedList>
          </Box>

          <SimpleGrid
            spacing={4}
            templateColumns={[
              "repeat(auto-fill, minmax(200px, 1fr))",
              "repeat(auto-fill, minmax(350px, 1fr))",
            ]}
          >
            <Card>
              <CardHeader>
                <Heading size="md" color={"blue.500"}>
                  {" "}
                  Moist Heat Decontamination
                </Heading>
              </CardHeader>
              <CardBody>
                <Text>
                  CO₂ incubator with 90°C MOIST HEAT DECONTAMINATION cycle has
                  been proven in deactivating normally resistant fungi,
                  bacterial spores and vegetative cells by the Health Protection
                  Agency (HPA) in UK. Chamber is cool and dry at the end of full
                  15-hr decontamination cycle. No further wipe down is needed.
                </Text>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <Heading size="md" color={"blue.500"}>
                  {" "}
                  High Heat Sterilization
                </Heading>
              </CardHeader>
              <CardBody>
                <Text>
                  180°C HIGH HEAT STERILIZATION conforms to the International
                  Standards for dry heat sterilization and proven to effectively
                  deactivate normally-resistant fungi, bacterial spore and
                  vegetative cells. Nontoxic and noncorrosive sterilization that
                  completes within 12 hours leaving the chamber cool and dry at
                  the end of the cycle.
                </Text>
              </CardBody>
            </Card>
          </SimpleGrid>
        </CardBody>
        <CardFooter>
          <Link to={"/Contact"}>
            <Button colorScheme="blue">Contact Us</Button>
          </Link>
        </CardFooter>
      </Card>
      <GoToTop />
    </Container>
  );
}

export default Co2Incubator;
