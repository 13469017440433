import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Button,
  Heading,
  Text,
  Container,
  ListItem,
  ListIcon,
  List,
  Image,
  SimpleGrid,
  Box,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@chakra-ui/react";

import { Link } from "react-router-dom";

import { MdCheckCircle } from "react-icons/md";
import services from "../../Assets/services.svg";

// images
import Installation from "../../Assets/Installation.svg";
import Validations from "../../Assets/Validations.svg";
import Maintenance from "../../Assets/Maintenance.svg";
import Repairs from "../../Assets/Repair.svg";
import Training from "../../Assets/Training.svg";
import TailorMade from "../../Assets/Tailor-made .svg";

function Services() {
  // Installation
  const {
    isOpen: isInstallOpen,
    onOpen: onInstallOpen,
    onClose: onInstallClose,
  } = useDisclosure();

  // Calibration
  const {
    isOpen: isCalibrationOpen,
    onOpen: onCalibrationOpen,
    onClose: onCalibrationClose,
  } = useDisclosure();

  // Maintenance
  const {
    isOpen: isMaintenanceOpen,
    onOpen: onMaintenanceOpen,
    onClose: onMaintenanceClose,
  } = useDisclosure();

  // Breakdown
  const {
    isOpen: isBreakdownOpen,
    onOpen: onBreakdownOpen,
    onClose: onBreakdownClose,
  } = useDisclosure();

  // Training
  const {
    isOpen: isTrainingOpen,
    onOpen: onTrainingOpen,
    onClose: onTrainingClose,
  } = useDisclosure();

  // Tailormade
  const {
    isOpen: isTailormadeOpen,
    onOpen: onTailormadeOpen,
    onClose: onTailormadeClose,
  } = useDisclosure();

  return (
    <Container maxW={"container.lg"} mb={20}>
      <Card mb={10} bg={"#125076"} color={"white"}>
        <CardBody>
          <Heading textAlign={"center"}>Services</Heading>
        </CardBody>
      </Card>
      <Card align="center">
        <CardBody>
          <Text mb={20} fontSize={"lg"} color={"#396e93"}>
            All of our engineers have undergone comprehensive training and have
            gained certification from top manufacturers in the use and upkeep of
            a wide variety of scientific instruments, making our Service Team
            skilled in their respective fields.
            <br />
            <br />
            We are always enhancing our standards and procedures in an effort to
            decrease downtime for our customers.
          </Text>

          <Image
            src={services}
            alt="services"
            objectFit="cover"
            maxW={{ base: "100%", sm: "500px" }}
            mb={10}
          ></Image>

          {/* GridItems */}
          <SimpleGrid columns={["1", "2", "3"]} spacing={10}>
            <Box textAlign={"center"}>
              <Card>
                <CardBody bg={"green.100"}>
                  <Button
                    onClick={onInstallOpen}
                    colorScheme={"green"}
                    variant={"ghost"}
                    width={"full"}
                  >
                    Installation
                  </Button>

                  <Modal
                    onClose={onInstallClose}
                    isOpen={isInstallOpen}
                    size={"xl"}
                  >
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>Installation</ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                        <Text mb={10}>
                          NVAK provides installation and demonstration services
                          for the products it offers. The team of experts at
                          NVAK will ensure that the installation is carried out
                          seamlessly and without any errors. Once the
                          installation is complete, a demo of the product will
                          be provided to the customer to ensure that they are
                          comfortable with its operation.
                        </Text>

                        <Image
                          boxSize="250px"
                          objectFit="contain"
                          src={Installation}
                          alt="Installation"
                          margin={"auto"}
                        />

                        <Heading fontSize={"md"} my={5}>
                          Installation and demo:
                        </Heading>

                        <List spacing={4}>
                          <ListItem>
                            <ListIcon as={MdCheckCircle} color="green.500" />
                            Skilled technicians and engineers for on-site
                            installation.
                          </ListItem>
                          <ListItem>
                            <ListIcon as={MdCheckCircle} color="green.500" />
                            Comprehensive installation process ensuring quality
                            assurance.
                          </ListItem>
                          <ListItem>
                            <ListIcon as={MdCheckCircle} color="green.500" />
                            Demonstration provided to ensure proper
                            understanding and optimal usage.
                          </ListItem>
                          <ListItem>
                            <ListIcon as={MdCheckCircle} color="green.500" />
                            Assistance with configuration and set-up for
                            personalized needs.
                          </ListItem>
                          <ListItem>
                            <ListIcon as={MdCheckCircle} color="green.500" />
                            Verification and testing of equipment functionality.
                          </ListItem>
                        </List>
                      </ModalBody>
                      <ModalFooter>
                        <Button onClick={onInstallClose}>Close</Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                </CardBody>
              </Card>
            </Box>

            <Box textAlign={"center"}>
              <Card>
                <CardBody bg={"green.100"}>
                  <Button
                    onClick={onCalibrationOpen}
                    colorScheme={"green"}
                    width={"full"}
                    variant={"ghost"}
                  >
                    Calibration
                  </Button>

                  <Modal
                    onClose={onCalibrationClose}
                    isOpen={isCalibrationOpen}
                    size={"xl"}
                  >
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>Calibration</ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                        <Text mb={10}>
                          NVAK provides calibration and validation services for
                          various types of equipment. The team of experts at
                          NVAK ensures that the equipment is calibrated and
                          validated to meet the required specifications and
                          standards.
                        </Text>

                        <Image
                          boxSize="250px"
                          objectFit="contain"
                          src={Validations}
                          alt="Validations"
                          margin={"auto"}
                        />

                        <Heading fontSize={"md"} my={5}>
                          Calibration and validation:
                        </Heading>

                        <List spacing={4}>
                          <ListItem>
                            <ListIcon as={MdCheckCircle} color="green.500" />
                            Calibration and validation for various types of
                            equipment such as scales, pressure gauges,
                            thermometers, etc.
                          </ListItem>
                          <ListItem>
                            <ListIcon as={MdCheckCircle} color="green.500" />
                            Ensure equipment is calibrated to meet required
                            specifications and standards.
                          </ListItem>
                          <ListItem>
                            <ListIcon as={MdCheckCircle} color="green.500" />
                            Detailed reports provided for record keeping and
                            compliance purposes.
                          </ListItem>
                          <ListItem>
                            <ListIcon as={MdCheckCircle} color="green.500" />
                            Expert knowledge to assess and identify potential
                            equipment malfunctions.
                          </ListItem>
                        </List>
                      </ModalBody>
                      <ModalFooter>
                        <Button onClick={onCalibrationClose}>Close</Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                </CardBody>
              </Card>
            </Box>

            <Box textAlign={"center"}>
              <Card>
                <CardBody bg={"green.100"}>
                  <Button
                    onClick={onMaintenanceOpen}
                    colorScheme={"green"}
                    width={"full"}
                    variant={"ghost"}
                  >
                    Maintenance
                  </Button>

                  <Modal
                    onClose={onMaintenanceClose}
                    isOpen={isMaintenanceOpen}
                    size={"xl"}
                  >
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>Maintenance</ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                        <Text mb={10}>
                          NVAK offers preventive maintenance services for
                          equipment to ensure that they continue to perform
                          optimally and prevent any potential breakdowns.
                          Regular maintenance schedules are put in place to
                          ensure that the equipment is serviced on a timely
                          basis.
                        </Text>

                        <Image
                          boxSize="250px"
                          objectFit="contain"
                          src={Maintenance}
                          alt="Maintenance"
                          margin={"auto"}
                        />

                        <Heading fontSize={"md"} my={5}>
                          Preventive maintenance:
                        </Heading>

                        <List spacing={4}>
                          <ListItem>
                            <ListIcon as={MdCheckCircle} color="green.500" />
                            Customized preventive maintenance schedules to
                            ensure equipment is maintained optimally.
                          </ListItem>
                          <ListItem>
                            <ListIcon as={MdCheckCircle} color="green.500" />
                            Regular inspections to identify potential issues
                            before they turn into major problems.
                          </ListItem>
                          <ListItem>
                            <ListIcon as={MdCheckCircle} color="green.500" />
                            Lubrication, cleaning, and part replacements when
                            necessary.
                          </ListItem>
                          <ListItem>
                            <ListIcon as={MdCheckCircle} color="green.500" />
                            Reduced downtime, increased equipment longevity, and
                            optimized performance.
                          </ListItem>
                        </List>
                      </ModalBody>
                      <ModalFooter>
                        <Button onClick={onMaintenanceClose}>Close</Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                </CardBody>
              </Card>
            </Box>

            <Box textAlign={"center"}>
              <Card>
                <CardBody bg={"green.100"}>
                  <Button
                    onClick={onBreakdownOpen}
                    colorScheme={"green"}
                    width={"full"}
                    variant={"ghost"}
                  >
                    Breakdown Repairs
                  </Button>

                  <Modal
                    onClose={onBreakdownClose}
                    isOpen={isBreakdownOpen}
                    size={"xl"}
                  >
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>Breakdown Repairs</ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                        <Text mb={10}>
                          NVAK offers breakdown repair services to customers who
                          experience equipment malfunctions. The team of experts
                          at NVAK will diagnose the issue and provide
                          appropriate solutions to get the equipment back up and
                          running as quickly as possible.
                        </Text>

                        <Image
                          boxSize="250px"
                          objectFit="contain"
                          src={Repairs}
                          alt="Repairs"
                          margin={"auto"}
                        />

                        <Heading fontSize={"md"} my={5}>
                          Breakdown repairs:
                        </Heading>

                        <List spacing={4}>
                          <ListItem>
                            <ListIcon as={MdCheckCircle} color="green.500" />
                            Troubleshooting, diagnostics, and identification of
                            equipment malfunctions.
                          </ListItem>
                          <ListItem>
                            <ListIcon as={MdCheckCircle} color="green.500" />
                            Quick response times to minimize downtime and
                            production loss.
                          </ListItem>
                          <ListItem>
                            <ListIcon as={MdCheckCircle} color="green.500" />
                            Replacement of faulty parts and components.
                          </ListItem>
                          <ListItem>
                            <ListIcon as={MdCheckCircle} color="green.500" />
                            Quality assurance and testing to ensure equipment
                            functions optimally.
                          </ListItem>
                        </List>
                      </ModalBody>
                      <ModalFooter>
                        <Button onClick={onBreakdownClose}>Close</Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                </CardBody>
              </Card>
            </Box>

            <Box textAlign={"center"}>
              <Card>
                <CardBody bg={"green.100"}>
                  <Button
                    onClick={onTrainingOpen}
                    colorScheme={"green"}
                    width={"full"}
                    variant={"ghost"}
                  >
                    Training
                  </Button>

                  <Modal
                    onClose={onTrainingClose}
                    isOpen={isTrainingOpen}
                    size={"xl"}
                  >
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>Training</ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                        <Text mb={10}>
                          NVAK offers training services to customers to ensure
                          that they are able to operate and maintain their
                          equipment effectively. The training is customized to
                          suit the specific needs of the customer and is
                          conducted by experienced professionals.
                        </Text>

                        <Image
                          boxSize="250px"
                          objectFit="contain"
                          src={Training}
                          alt="Training"
                          margin={"auto"}
                        />

                        <Heading fontSize={"md"} my={5}>
                          Training:
                        </Heading>

                        <List spacing={4}>
                          <ListItem>
                            <ListIcon as={MdCheckCircle} color="green.500" />
                            Customized training to meet the specific needs of
                            the customer.
                          </ListItem>
                          <ListItem>
                            <ListIcon as={MdCheckCircle} color="green.500" />
                            Comprehensive understanding of equipment
                            functionality.
                          </ListItem>
                          <ListItem>
                            <ListIcon as={MdCheckCircle} color="green.500" />
                            Safety procedures and guidelines provided.
                          </ListItem>
                          <ListItem>
                            <ListIcon as={MdCheckCircle} color="green.500" />
                            Best practices for optimal usage and maintenance.
                          </ListItem>
                          <ListItem>
                            <ListIcon as={MdCheckCircle} color="green.500" />
                            Support and assistance after the training for any
                            questions or concerns.
                          </ListItem>
                        </List>
                      </ModalBody>
                      <ModalFooter>
                        <Button onClick={onTrainingClose}>Close</Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                </CardBody>
              </Card>
            </Box>

            <Box textAlign={"center"}>
              <Card>
                <CardBody bg={"green.100"}>
                  <Button
                    onClick={onTailormadeOpen}
                    colorScheme={"green"}
                    width={"full"}
                    variant={"ghost"}
                  >
                    Tailormade Solutions
                  </Button>

                  <Modal
                    onClose={onTailormadeClose}
                    isOpen={isTailormadeOpen}
                    size={"xl"}
                  >
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>Tailormade Solutions</ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                        <Text mb={10}>
                          NVAK offers customized applications and tailor-made
                          solutions to meet the specific needs of its customers.
                          The team of experts at NVAK will work closely with
                          customers to understand their requirements and provide
                          solutions that are tailored to their specific needs.
                        </Text>

                        <Image
                          boxSize="250px"
                          objectFit="contain"
                          src={TailorMade}
                          alt="TailorMade"
                          margin={"auto"}
                        />

                        <Heading fontSize={"md"} my={5}>
                          Customized applications and tailor-made solutions:
                        </Heading>

                        <List spacing={4}>
                          <ListItem>
                            <ListIcon as={MdCheckCircle} color="green.500" />
                            Expert consultation to understand customer needs and
                            requirements.
                          </ListItem>
                          <ListItem>
                            <ListIcon as={MdCheckCircle} color="green.500" />
                            Development of customized applications and software
                            solutions to suit the specific needs of the
                            customer.
                          </ListItem>
                          <ListItem>
                            <ListIcon as={MdCheckCircle} color="green.500" />
                            Hardware modifications and upgrades to meet specific
                            requirements.
                          </ListItem>
                          <ListItem>
                            <ListIcon as={MdCheckCircle} color="green.500" />
                            Integration of third-party equipment with existing
                            systems.
                          </ListItem>
                          <ListItem>
                            <ListIcon as={MdCheckCircle} color="green.500" />
                            Ongoing support and assistance to ensure optimal
                            usage and performance.
                          </ListItem>
                        </List>
                      </ModalBody>
                      <ModalFooter>
                        <Button onClick={onTailormadeClose}>Close</Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                </CardBody>
              </Card>
            </Box>
          </SimpleGrid>
        </CardBody>
        <CardFooter>
          <Link to={"/Contact"}>
            <Button colorScheme="blue">Contact us</Button>
          </Link>
        </CardFooter>
      </Card>
    </Container>
  );
}

export default Services;
