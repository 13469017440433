import React from "react";

import {
  Container,
  Card,
  CardBody,
  Text,
  Heading,
  CardHeader,
  Button,
  CardFooter,
  Image,
  Stack,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";

import { Link } from "react-router-dom";
import GoToTop from "../../GoToTop/GoToTop";

function MIRIIIMultiroomIncubator() {
  return (
    <Container maxW={"container.lg"}>
      <Card>
        <CardHeader>
          <Heading size="lg" bg={"blue.500"} color={"white"} p={5}>
            {" "}
            MIRI® II Multiroom Incubator
          </Heading>
        </CardHeader>
        <CardBody>
          <Card align="center" mb={20}>
            <CardBody>
              <Text mb={10}>
                The Esco Medical MIRI® II-12 Multiroom Incubator is intended to
                be used to provide a stable culture environment at or near body
                temperature and CO2 and N2 gasses for the development of gametes
                and embryos during In Vitro Fertilization (IVF) and Assisted
                Reproduction Technology (ART) treatments.
              </Text>
              <Link
                to={
                  "https://www.esco-medical.com/products/miri-ii-multiroom-incubator"
                }
              >
                <Button colorScheme="blue">Get Details</Button>
              </Link>
            </CardBody>
            <CardFooter>
              <Image src="https://www.esco-medical.com/images/product-image/miri-2-12-chambers-front-010422-min-hd.png"></Image>
            </CardFooter>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "400px" }}
              src="https://www.esco-medical.com/images/upload/miri-2-12-chambers-front-right-010422-cropped-min.png"
              alt="MIRI® II-12 Multiroom Incubator"
            />

            <Stack>
              <CardBody>
                <Heading size={"md"} color={"blue.500"} mb={5}>
                  MIRI® II-12 Multiroom Incubator
                </Heading>
                <Text mb={5}>
                  To ensure maximum performance and ensure an environment with
                  the least amount of stress for embryos, the Esco MIRI® II-12
                  Multiroom Incubator dedicates one chamber for each patient and
                  has 24 completely separate PID temperature controllers.
                </Text>
                <Text>
                  The compartments are separate from one another and do not
                  affect each other's temperatures in any way. There is no
                  effect on the remainder of the system from any disruption in
                  one chamber, such as a temperature reduction after opening the
                  lid. The temperature in the culture chambers and the lids can
                  be controlled and regulated as well. The top and the bottom of
                  each compartment are separated with a PET layer so that the
                  lid temperature would not affect the bottom. <br /> <br />
                  For validation purposes, each compartment has a PT-1000 sensor
                  built-in. The circuitry is separated from the unit's
                  electronics, so it remains a genuinely separate validation
                  system. <br /> <br />
                  The incubator needs 100% CO2 and 100% N2 to control the CO2
                  and O2 concentrations in the culture chambers. A dual-beam
                  infra-red CO2 sensor with extremely low drift rates controls
                  the CO2 levels. On the other hand, a chemical, medical-grade
                  oxygen sensor controls the levels of the O2 in the system.
                </Text>
                <Text py={5}>
                  Gas recovery time is less than 3 minutes after opening the
                  lid. The MIRI® II-12 incubator is fitted with 12 gas sample
                  ports that allow the user to validate gas concentration by
                  sampling gas from the individual compartment.
                </Text>
                <Text>
                  The incubator also features a recirculated gas system where
                  gas is continuously put into the compartment and taken out at
                  the same rate. Gas is cleaned via a 254 nm UVC light with
                  direct gas contact between the bulb and gas. The UVC light has
                  filters that inhibit any 185 nm radiation that would produce
                  dangerous ozone. The filters are located under the UVC light.
                  The complete gas repletion in the system takes less than 5
                  minutes.
                </Text>
              </CardBody>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "400px" }}
              src="https://www.esco-medical.com/images/upload/miri-2-airflow%20diagram.png"
              alt="Recirculating Gas for Minimal Gas Consumption
              "
            />

            <Stack>
              <CardBody>
                <Heading size={"md"} color={"blue.500"}>
                  Recirculating Gas for Minimal Gas Consumption
                </Heading>

                <Text py={5}>
                  The high performance CO2 and O2 sensors and built-in gas mixer
                  enable precise adjustment of the gas phase composition and
                  environment within the chambers. Pure gas is used with the
                  MIRI® II-12.
                  <br />
                  <br />
                  Incubator steady-state gas condition can be reached when 100%
                  CO2 and 100% N2 are used. This is crucial because it will
                  maintain the pH during embryo development.
                </Text>

                <Text>
                  The incubator has a complete gas control system that consists
                  of the following:
                </Text>

                <UnorderedList py={5}>
                  <ListItem>
                    Pressure regulator (preventing dangerous gas pressure
                    problems)
                  </ListItem>
                  <ListItem>
                    Gas flow sensors (actual consumption can be accumulated)
                  </ListItem>
                  <ListItem>
                    Gas pressure sensors (allows the user to know the pressure
                    and the variation can be logged to avoid dangerous
                    conditions)
                  </ListItem>
                  <ListItem>Gas filters (to prevent valve problems)</ListItem>
                </UnorderedList>
              </CardBody>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "400px" }}
              src="https://www.esco-medical.com/images/upload/miri-2-heated-lid-min.png"
              alt="   Heated Lid"
            />

            <Stack>
              <CardBody>
                <Heading size="md" color={"blue.500"}>
                  Heated Lid
                </Heading>
                <Text py="2">
                  Prevents condensation, enhances consistency throughout each
                  chamber, and improves temperature regulation and recovery.
                </Text>
              </CardBody>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "400px" }}
              src="https://www.esco-medical.com/images/upload/miri-2-Heated-Bottoms-min.png"
              alt="  Heated Bottom"
            />

            <Stack>
              <CardBody>
                <Heading size="md" color={"blue.500"}>
                  Heated Bottom
                </Heading>
                <Text py="2">
                  Allows for reliable temperature regulation by providing direct
                  heat transfer to the culture dishes.
                </Text>
              </CardBody>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "400px" }}
              src="https://www.esco-medical.com/images/upload/miri-2-Heated-Bottoms-min.png"
              alt="pH System"
            />

            <Stack>
              <CardBody>
                <Heading size="md" color={"blue.500"}>
                  Built-in pH System and Optional SAFE Sens Integration
                </Heading>
                <Text py="2">
                  The user can calibrate the pH meter and measure the media's pH
                  using the MIRI® II-12's built-in pH system. <br />
                  <br /> The most sophisticated non-invasive embryo monitoring
                  system on the market is now possible thanks to the integration
                  of SAFE Sens technology into the Esco MIRI® II-12 incubator.
                  No other technology can provide you with as much real-time
                  knowledge about the growth and culture of your embryos.
                </Text>
              </CardBody>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "400px" }}
              src="https://www.esco-medical.com/images/upload/heating-optimization-plates-min.png"
              alt="  Heating Optimization Plates"
            />

            <Stack>
              <CardBody>
                <Heading size="md" color={"blue.500"}>
                  Heating Optimization Plates
                </Heading>
                <Text py="2">
                  A heating optimization plate is included in each chamber to
                  help heat transfer directly to the culture dishes. Each
                  chamber contains inserts that suit different-sized dishes.
                </Text>
              </CardBody>
            </Stack>
          </Card>
        </CardBody>
        <CardFooter>
          <Link to={"/Contact"}>
            <Button colorScheme="blue">Contact Us</Button>
          </Link>
        </CardFooter>
      </Card>
      <GoToTop />
    </Container>
  );
}

export default MIRIIIMultiroomIncubator;
