import React from "react";
import {
  Container,
  Heading,
  Card,
  CardHeader,
  CardBody,
  Text,
  CardFooter,
  Button,
  UnorderedList,
  ListItem,
  Image,
  SimpleGrid,
} from "@chakra-ui/react";

import { Link } from "react-router-dom";
import GoToTop from "../../GoToTop/GoToTop";

function Cytotoxic() {
  return (
    <Container maxW={"container.lg"}>
      <Card>
        <CardHeader>
          <Heading size="lg" bg={"blue.500"} color={"white"} p={5}>
            Cytoculture® Cytotoxic Safety Cabinet
          </Heading>
        </CardHeader>
        <CardBody>
          <Text mb={10}>
            Esco’s Cytoculture® Cytotoxic Safety Cabinet is the premium solution
            for cytotoxic/antineoplastic drug processing; providing the highest
            level of patient, pharmacist, and environmental protection. This
            revolutionary product builds on Esco’s experience of more than 20
            years as a global leader in biological safety containment
            technology.
          </Text>

          <Text mb={20}>
            The unique demands of handling and preparing cytotoxic drugs for use
            in chemotherapy require a specialized cabinet. With this in mind,
            Esco has developed a highly specialized cabinet designed especially
            for the preparation of these potentially dangerous drugs.
          </Text>

          <Image
            src="https://www.escopharma.com/images/upload/CYT_airflow-min.png"
            boxSize="fit-content"
            objectFit="cover"
            margin={"auto"}
          />

          <SimpleGrid
            spacing={4}
            templateColumns={[
              "repeat(auto-fill, minmax(200px, 1fr))",
              "repeat(auto-fill, minmax(350px, 1fr))",
            ]}
          >
            <Card>
              <CardHeader>
                <Heading size="md" color={"blue.500"}>
                  {" "}
                  Accessories
                </Heading>
              </CardHeader>
              <CardBody>
                <UnorderedList>
                  <ListItem>Anti-Blowback Valve</ListItem>
                  <ListItem>
                    Carbon Filter Option – alternative option to V-shaped HEPA
                    filter
                  </ListItem>
                  <ListItem>
                    Thimble Exhaust Collar – 10 inches in diameter
                  </ListItem>
                  <ListItem>UV Lamp</ListItem>
                  <ListItem>Electrical outlet</ListItem>
                  <ListItem>Service fixtures</ListItem>
                </UnorderedList>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <Heading size="md" color={"blue.500"}>
                  {" "}
                  Certifications
                </Heading>
              </CardHeader>
              <CardBody>
                <UnorderedList>
                  <ListItem color={"red"}>
                    <a href="https://www.escopharma.com/pdf/brochures/CYT_4A1_HPA_cert.pdf">
                      CYT-4A1 HPA Certificate
                    </a>
                  </ListItem>
                  <ListItem color={"red"}>
                    <a href="https://www.escopharma.com/pdf/brochures/CYT_4A2_HPA.pdf">
                      CYT-4A2 HPA Certificate
                    </a>
                  </ListItem>
                  <ListItem color={"red"}>
                    <a href="https://www.escopharma.com/pdf/brochures/CYT-A-CE-Certificate.pdf">
                      CYT-A CE Certificate
                    </a>
                  </ListItem>
                </UnorderedList>
              </CardBody>
            </Card>
          </SimpleGrid>
        </CardBody>
        <CardFooter>
          <Link to={"/Contact"}>
            <Button colorScheme="blue">Contact Us</Button>
          </Link>
        </CardFooter>
      </Card>

      <GoToTop />
    </Container>
  );
}

export default Cytotoxic;
