import {
  Heading,
  Container,
  Card,
  CardBody,
  CardHeader,
  Text,
  UnorderedList,
  ListItem,
  Image,
} from "@chakra-ui/react";
import React from "react";

import GoToTop from "../../GoToTop/GoToTop";

function EscoHealthcareSolutions() {
  return (
    <Container maxW={"container.lg"}>
      <Card>
        <CardHeader>
          <Heading size="lg" bg={"blue.500"} color={"white"} p={5}>
            ESCO Healthcare Solutions
          </Heading>
        </CardHeader>
        <CardBody>
          <Card mb={10}>
            <CardHeader>
              <Heading size="md" color={"blue.500"}>
                ESCO Pharma
              </Heading>
            </CardHeader>
            <CardBody>
              <Text>
                Esco provides standardized platforms with built-in
                configurations while ensuring operational parameters are not
                compromised. This enables the manufacture of internationally
                compliant pharmaceuticals, nutraceuticals, and cosmeceuticals.
              </Text>
              <br />
              <Text>
                Esco Pharma‘s largest global network of localized application
                specialists and service offices, provide faster service response
                than others; translating into more competitive maintenance costs
                and shorter project life cycles.
              </Text>
            </CardBody>
          </Card>

          <Card mb={10}>
            <CardHeader>
              <Heading size="md" color={"blue.500"}>
                Esco TaPestie Rx
              </Heading>
            </CardHeader>
            <CardBody>
              <Text>
                TaPestle Rx is the pioneer in automation-centered architecture
                in which the process workflow defines the design to provide
                workspace that minimizes human movement waste and allow a
                medical workflow to be carried out in an efficient manner.
              </Text>
              <br />
              <Text>
                TaPestle Rx is built on the legacy of two pharmacists whose oath
                is to consider the welfare of humanity and the relief of human
                suffering. Our primary concern is always fundamental in shaping
                our vision of elevating healthcare by providing access to life
                sciences tools in developing countries.
              </Text>
              <Text>
                As a 100% privately held company with corporate and global
                headquarters in Singapore, TaPestle Rx has the resources,
                in-house technology, expertise, dedication, and distribution
                channels and within the sphere of developing countries to make
                its vision a success.
              </Text>
            </CardBody>
          </Card>

          <Card mb={10}>
            <CardHeader>
              <Heading size="md" color={"blue.500"}>
                Esco VacciXcell
              </Heading>
            </CardHeader>
            <CardBody>
              <Text>
                What started out as a concept became the bioprocessing division
                of Esco Healthcare. Esco VacciXcell became about when Esco
                Lifesciences group, which is headquartered in Singapore, was
                contacted by a neighboring country for vaccine self-sufficiency.
              </Text>
              <br />
              <Text>
                Speed up your bioprocess scale-up development time with Tide
                Motion Technology. Get 100% continuous processing in less than 3
                months. Find out how Vaccixcell bioreactors deliver continued
                cell growth without applying shear stress and without needing
                additional feed control.
              </Text>
            </CardBody>
          </Card>

          <Card mb={10}>
            <CardHeader>
              <Heading size="md" color={"blue.500"}>
                Ventilated Balance Enclosure (VBE)
              </Heading>
            </CardHeader>
            <CardBody>
              <Image
                src="https://www.escolifesciences.com/images/product-img/full/Ventilated-balance-enclosure-min.png"
                boxSize="fit-content"
                objectFit="cover"
                margin={"auto"}
              />
              <Text>
                Esco Pharma’s Ventilated Balance Enclosure (VBE) is specifically
                designed​ for a stable and accurate handling and weighing of
                potent powders while ensuring a high containment level for
                operator​ protection. The aerodynamically designed sash and arm
                rest with its sectionalized baffle, guarantees that the airborne
                powders​ are well contained inside the enclosure and exhausted
                through a HEPA​ filter or directly to the laboratory exhaust.
              </Text>
              <br />
              <UnorderedList>
                <ListItem>
                  Negative pressure application for a high level of operator
                  protection from hazardous airborne particles.
                </ListItem>
                <ListItem>
                  Disposal port is equipped with O-ring to provide a sealed
                  trash bag, as an additional powder containment solution.
                </ListItem>
                <ListItem>
                  VBE is equipped with a filter and a blower module for better
                  airflow​ control.
                </ListItem>
              </UnorderedList>
            </CardBody>
          </Card>

          <Card mb={10}>
            <CardHeader>
              <Heading size="md" color={"blue.500"}>
                Bioreactor
              </Heading>
            </CardHeader>
            <CardBody>
              <Image
                src="https://www.escolifesciences.com/images/product-img/full/vxl-hybrid-bioreactor-system-min.png"
                boxSize="fit-content"
                objectFit="cover"
                margin={"auto"}
              />
              <Text>
                A system or device which supports a biologically active
                environment. The choice of bioreactor system to use highly
                depend on the type of cells to be produced, the desired product,
                the process of the cell culture, and the product density.
              </Text>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
      <GoToTop />
    </Container>
  );
}

export default EscoHealthcareSolutions;
