import {
  Heading,
  Container,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Button,
  Text,
  Image,
  Stack,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import React from "react";

import { Link } from "react-router-dom";

function ThermalCyclerMicroplate() {
  return (
    <Container maxW={"container.lg"}>
      <Card>
        <CardHeader>
          <Heading size="lg" bg={"blue.500"} color={"white"} p={5}>
            {" "}
            Conventional Thermal Cycler Microplate Shaker
          </Heading>
        </CardHeader>
        <CardBody>
          <Text>
            Polymerase Chain Reaction (PCR) thermal cyclers carry out PCR
            procedures in the laboratory. Samples are put on a block and exposed
            to a cycle of different temperatures.
          </Text>
          <br />
          <Text mb={20}>
            Esco’s conventional and real-time thermal cyclers are designed to
            meet critical requirements for various PCR applications. Its
            state-of-the-art Peltier temperature control technology achieves and
            maintains precise control and fast ramp rates with minimal over or
            under-shoot for process speed and accuracy. Esco thermal cyclers
            have different block configurations, adjustable hot lid and
            temperature, applicable for standalone operation, and have low
            footprint suitable for small laboratories.
          </Text>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={20}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/swift-minipro-thermal-cycler.png"
            />

            <Stack>
              <CardBody>
                {/* <Heading size="md" mb={5} color={"blue.500"}>
                  Standard Controller
                </Heading> */}

                <Text mb={10}>
                  Polymerase Chain Reaction amplifies nucleic acids in vitro,
                  emulating the natural DNA replication process. PCR
                  amplification is achieved using a thermal cycler by raising
                  and lowering the temperature of samples in a thermal block in
                  cycles of programmed steps.
                </Text>
              </CardBody>

              <CardFooter>
                {/* <Link to="/Contact">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </Link> */}
              </CardFooter>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={20}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/aeris-pcr-thermal-cycler.png"
            />

            <Stack>
              <CardBody>
                {/* <Heading size="md" mb={5} color={"blue.500"}>
                  Standard Controller
                </Heading> */}

                <Text mb={10}>
                  Polymerase chain reaction refers to a test tube system for DNA
                  replication that allows a “target” DNA sequence to be
                  selectively amplified. Typically, PCR consists of a series of
                  20–40 repeated temperature changes, called cycles, with each
                  cycle, commonly consisting of 2–3 discrete temperature steps.
                </Text>
              </CardBody>

              <CardFooter>
                {/* <Link to="/Contact">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </Link> */}
              </CardFooter>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={20}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/swift-progene-real-time-pcr-thermal-cyclers.png"
            />

            <Stack>
              <CardBody>
                {/* <Heading size="md" mb={5} color={"blue.500"}>
                  Standard Controller
                </Heading> */}

                <Text mb={10}>
                  Real-time PCR thermal cyclers provide a system for the
                  efficient amplification of nucleic acids in vitro, while
                  offering capability to monitoring the PCR reaction in
                  real-time.
                </Text>
              </CardBody>

              <CardFooter>
                {/* <Link to="/Contact">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </Link> */}
              </CardFooter>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={20}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/swift-progene-real-time-pcr-thermal-cyclers.png"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  Swift™ MiniPro® Thermal Cycler
                </Heading>

                <UnorderedList>
                  <ListItem fontWeight={"extrabold"}>SWT-MIP-0.5</ListItem>
                  <ListItem>
                    Excellent temperature accuracy and uniformity
                  </ListItem>
                  <ListItem>
                    High heating and cooling rate up to 5°C/sec
                  </ListItem>
                  <ListItem>Compact footprint</ListItem>
                  <ListItem>Fast setup and run</ListItem>
                  <ListItem>
                    Password protection for secure system access
                  </ListItem>
                  <ListItem>Sample capacity: 18 x 0.5 ml</ListItem>
                </UnorderedList>
              </CardBody>

              <CardFooter>
                <Stack spacing={5} direction={["column", "row"]}>
                  <a href="https://www.escolifesciences.com/products/pcr-thermal-cycler/swift-minipro-thermal-cycler">
                    <Button variant="solid" colorScheme="blue">
                      For more Info
                    </Button>
                  </a>
                  <a href="https://www.escolifesciences.com/form/request-for-quotation/36">
                    <Button variant="solid" colorScheme="blue">
                      Request for Quote
                    </Button>
                  </a>
                </Stack>
              </CardFooter>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={20}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/swift-minipro-thermal-cycler.png"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  Swift™ MiniPro® Thermal Cycler
                </Heading>

                <UnorderedList>
                  <ListItem fontWeight={"extrabold"}>SWT-MIP-0.2</ListItem>
                  <ListItem>
                    Excellent temperature accuracy and uniformity
                  </ListItem>
                  <ListItem>
                    High heating and cooling rate up to 5°C/sec
                  </ListItem>
                  <ListItem>Compact footprint</ListItem>
                  <ListItem>Fast setup and run</ListItem>
                  <ListItem>
                    Password protection for secure system access
                  </ListItem>
                  <ListItem>Sample capacity: 24 x 0.2 ml</ListItem>
                </UnorderedList>
              </CardBody>

              <CardFooter>
                <Stack spacing={5} direction={["column", "row"]}>
                  <a href="https://www.escolifesciences.com/products/pcr-thermal-cycler/swift-minipro-thermal-cycler">
                    <Button variant="solid" colorScheme="blue">
                      For more Info
                    </Button>
                  </a>
                  <a href="https://www.escolifesciences.com/form/request-for-quotation/36">
                    <Button variant="solid" colorScheme="blue">
                      Request for Quote
                    </Button>
                  </a>
                </Stack>
              </CardFooter>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={20}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/aeris-pcr-thermal-cycler.png"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  Aeris™ Thermal Cycler
                </Heading>

                <UnorderedList>
                  <ListItem fontWeight={"extrabold"}>
                    AERIS-MB + AERIS-BG096
                  </ListItem>
                  <ListItem>Multi-block capability</ListItem>
                  <ListItem>
                    Adjustable hot lid temperature and ramp rate
                  </ListItem>
                  <ListItem>
                    Excellent temperature accuracy and uniformity
                  </ListItem>
                  <ListItem>Can perform standalone operation</ListItem>
                  <ListItem>
                    Software allows variety of PCR conditions, can control up to
                    30 units via one PC
                  </ListItem>
                  <ListItem>
                    Password protection for secure system access
                  </ListItem>
                  <ListItem>Sample capacity: 96 x 0.2 ml</ListItem>
                </UnorderedList>
              </CardBody>

              <CardFooter>
                <Stack spacing={5} direction={["column", "row"]}>
                  <a href="https://www.escolifesciences.com/products/pcr-thermal-cycler/aeris-pcr-thermal-cycler">
                    <Button variant="solid" colorScheme="blue">
                      For more Info
                    </Button>
                  </a>
                  <a href="https://www.escolifesciences.com/form/request-for-quotation/36">
                    <Button variant="solid" colorScheme="blue">
                      Request for Quote
                    </Button>
                  </a>
                </Stack>
              </CardFooter>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={20}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/aeris-pcr-thermal-cycler.png"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  Aeris™ Thermal Cycler
                </Heading>

                <UnorderedList>
                  <ListItem fontWeight={"extrabold"}>
                    AERIS-MB + AERIS-B4830
                  </ListItem>
                  <ListItem>Multi-block capability</ListItem>
                  <ListItem>
                    Adjustable hot lid temperature and ramp rate
                  </ListItem>
                  <ListItem>
                    Excellent temperature accuracy and uniformity
                  </ListItem>
                  <ListItem>Can perform standalone operation</ListItem>
                  <ListItem>
                    Software allows variety of PCR conditions, can control up to
                    30 units via one PC
                  </ListItem>
                  <ListItem>
                    Password protection for secure system access
                  </ListItem>
                  <ListItem>
                    Sample capacity: 48 x 0.2 ml + 30 x 0.5 ml
                  </ListItem>
                </UnorderedList>
              </CardBody>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={20}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/aeris-pcr-thermal-cycler.png"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  Aeris™ Thermal Cycler
                </Heading>

                <UnorderedList>
                  <ListItem fontWeight={"extrabold"}>
                    AERIS-MB + AERIS-BG384
                  </ListItem>
                  <ListItem>Multi-block capability</ListItem>
                  <ListItem>
                    Adjustable hot lid temperature and ramp rate
                  </ListItem>
                  <ListItem>
                    Excellent temperature accuracy and uniformity
                  </ListItem>
                  <ListItem>Can perform standalone operation</ListItem>
                  <ListItem>
                    Software allows variety of PCR conditions, can control up to
                    30 units via one PC
                  </ListItem>
                  <ListItem>
                    Password protection for secure system access
                  </ListItem>
                  <ListItem>Sample capacity: 384 wells</ListItem>
                </UnorderedList>
              </CardBody>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={20}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/aeris-pcr-thermal-cycler.png"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  Aeris™ Thermal Cycler
                </Heading>

                <UnorderedList>
                  <ListItem fontWeight={"extrabold"}>
                    AERIS-MB + AERIS-BD048
                  </ListItem>
                  <ListItem>Multi-block capability</ListItem>
                  <ListItem>
                    Adjustable hot lid temperature and ramp rate
                  </ListItem>
                  <ListItem>
                    Excellent temperature accuracy and uniformity
                  </ListItem>
                  <ListItem>Can perform standalone operation</ListItem>
                  <ListItem>
                    Software allows variety of PCR conditions, can control up to
                    30 units via one PC
                  </ListItem>
                  <ListItem>
                    Password protection for secure system access
                  </ListItem>
                  <ListItem>
                    Sample capacity: 48 x 0.2 ml ( two independent runs )
                  </ListItem>
                </UnorderedList>
              </CardBody>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={20}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/aeris-pcr-thermal-cycler.png"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  Aeris™ Thermal Cycler
                </Heading>

                <UnorderedList>
                  <ListItem fontWeight={"extrabold"}>
                    AERIS-MB + AERIS-B4076
                  </ListItem>
                  <ListItem>Multi-block capability</ListItem>
                  <ListItem>
                    Adjustable hot lid temperature and ramp rate
                  </ListItem>
                  <ListItem>
                    Excellent temperature accuracy and uniformity
                  </ListItem>
                  <ListItem>Can perform standalone operation</ListItem>
                  <ListItem>
                    Software allows variety of PCR conditions, can control up to
                    30 units via one PC
                  </ListItem>
                  <ListItem>
                    Password protection for secure system access
                  </ListItem>
                  <ListItem>Sample capacity: 4 slides ( In-situ )</ListItem>
                </UnorderedList>
              </CardBody>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={20}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/swift-progene-real-time-pcr-thermal-cyclers.png"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  Swift™ ProGene Real-Time PCR Thermal Cycler
                </Heading>

                <UnorderedList>
                  <ListItem fontWeight={"extrabold"}>SWT-PG-96</ListItem>
                  <ListItem>Multi-block capability</ListItem>
                  <ListItem>
                    Offers 6-zone independent temperature control for accurate
                    results
                  </ListItem>
                  <ListItem>
                    Fast heating/cooling rate for faster run time
                  </ListItem>
                  <ListItem>Low energy consumption</ListItem>
                  <ListItem>
                    Built-in computer for stand-alone operation from PCR program
                    input, monitoring, to analysis.
                  </ListItem>
                  <ListItem>Touchscreen display</ListItem>
                  <ListItem>
                    Multiple configurations (quantification, SNP, and HRM
                    analysis)
                  </ListItem>
                  <ListItem>
                    Standard 5 channels plus additional for user customization,
                    allowing specific detection of particular wavelength
                  </ListItem>
                  <ListItem>
                    Automatic insertion and ejection of PCR plates or tubes to
                    the system.
                  </ListItem>
                </UnorderedList>
              </CardBody>
            </Stack>
          </Card>
        </CardBody>
        <CardFooter>
          <Link to={"/Contact"}>
            <Button colorScheme="blue">Contact Us</Button>
          </Link>
        </CardFooter>
      </Card>
    </Container>
  );
}

export default ThermalCyclerMicroplate;
