import React from "react";

import {
  Container,
  Card,
  CardBody,
  Text,
  Heading,
  CardHeader,
  Button,
  CardFooter,
  Image,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

function MiriHumidityMultiroom() {
  return (
    <Container maxW={"container.lg"}>
      <Card>
        <CardHeader>
          <Heading size="lg" bg={"blue.500"} color={"white"} p={5}>
            {" "}
            MIRI® Humidity Multiroom Incubator
          </Heading>
        </CardHeader>
        <CardBody>
          <Card align="center" mb={20}>
            <CardBody>
              <Text pb={10}>
                The MIRI® Humidity is a six chamber multiroom benchtop incubator
                that provides a humidified environment for embryo culture. The
                robust design enables for quick temperature and gas recovery for
                optimal embryo culture while preventing vapor condensation.
              </Text>

              <Link
                to={
                  "https://www.esco-medical.com/products/miri-humidity-multiroom-incubator"
                }
              >
                <Button colorScheme="blue">Get Details</Button>
              </Link>
            </CardBody>
            <CardFooter>
              <Image src="https://www.esco-medical.com/images/upload/Parts-MIRI-Humidity.png"></Image>
            </CardFooter>
          </Card>

          <Card overflow="hidden" variant="outline" mb={10}>
            <CardHeader>
              <Heading color={"blue.500"}>
                Superior Incubation for your Samples
              </Heading>
            </CardHeader>
            <CardBody>
              <Text py="2">
                The Esco MIRI® Humidity Multiroom incubator is the best option
                for securing embryos in your IVF laboratory. With its six (6)
                independent chambers, not only does it provide embryologists a
                bigger capacity to accommodate more embryos. It also ensures
                that environmental parameters are maintained even when the
                neighboring chambers are opened. It also gives the best
                temperature recovery, accuracy, and uniformity in the IVF
                incubator market with its heated lid design. This feature
                ensures that temperature given to your embryos are not only
                sourced from the bottom of the dish but is also given on the top
                with a set point of 37.2 °C to ensure that exactly 37.0 °C is
                given to your samples at the embryo level while providing a
                humidified environment.
              </Text>
            </CardBody>
          </Card>

          <Card overflow="hidden" variant="outline" mb={10}>
            <CardHeader>
              <Heading color={"blue.500"}>
                Cost-effective and Flexibility
              </Heading>
            </CardHeader>
            <CardBody>
              <Text py="2">
                The Esco MIRI® Humidity Multiroom incubator gives you the option
                to use pure N2 and CO2 gases which are cheaper than premixed
                gases and recirculates these gases giving you the best value for
                money. This also gives you the flexibility to change your gas
                concentration when you want to optimize embryo culture protocols
                in your laboratory with just a click of a button. The MIRI®
                Humidity Multiroom incubator is also compatible with the most
                common brands of culture dishes in the market with the
                corresponding capacity per incubator chamber below.
              </Text>
            </CardBody>
          </Card>

          <Card overflow="hidden" variant="outline" mb={10}>
            <CardHeader>
              <Heading color={"blue.500"}>Total Capacity</Heading>
            </CardHeader>
            <CardBody>
              <Text py="2">
                Heating plates customized for several types of dishes:
              </Text>
              <UnorderedList>
                <ListItem>4 x Falcon® ⌀ 50/60 mm</ListItem>
                <ListItem>8 x Falcon® ⌀ 35 mm</ListItem>
                <ListItem>4 x Nunc™ ⌀ 54/60 mm</ListItem>
                <ListItem>8 x Nunc™ ⌀ 35 mm</ListItem>
                <ListItem>4 x VitrolifeDishes</ListItem>
                <ListItem>4 x LifeGlobal® GPS Dishes</ListItem>
                <ListItem>4 x SparMED Oosafe® 4-well Dishes</ListItem>
                <ListItem>4x SparMED Oosafe ⌀ 55/60 mm</ListItem>
                <ListItem>8x SparMED Oosafe ⌀ 35 mm</ListItem>
              </UnorderedList>
            </CardBody>
          </Card>
        </CardBody>
        <CardFooter>
          <Link to={"/Contact"}>
            <Button colorScheme="blue">Contact Us</Button>
          </Link>
        </CardFooter>
      </Card>
    </Container>
  );
}

export default MiriHumidityMultiroom;
