import React from "react";
import {
  Container,
  Heading,
  Card,
  CardHeader,
  CardBody,
  Text,
  CardFooter,
  Button,
  UnorderedList,
  ListItem,
  Image,
  Stack,
} from "@chakra-ui/react";

import GoToTop from "../../GoToTop/GoToTop";
import { Link } from "react-router-dom";

function CellCultureCO2Incubator() {
  return (
    <Container maxW={"container.lg"}>
      <Card mb={20}>
        <CardHeader>
          <Heading size="lg" bg={"blue.500"} color={"white"} p={5}>
            {" "}
            Cell Culture CO₂ Incubator
          </Heading>
        </CardHeader>
        <CardBody>
          <Text mb={10}>
            A CO₂ incubator plays an important role in maintaining a cell
            culture’s sterility, temperature, humidity, and pH—parameters that
            are to maintain the culture’s viability.
          </Text>

          <Text mb={20}>
            Esco CO₂ incubators have top-notch features making it a reliable
            choice to cradle precious cells. We understand that every laboratory
            requires different specifications, hence, various options from
            filter installation, sterilization cycle, UV lamp installation,
            chamber material, external construction material, down to O₂ control
            is available for customers to choose from.
          </Text>

          <Card mb={20}>
            <CardHeader color={"blue.500"} fontWeight={"extrabold"}>
              Must-have features to consider:{" "}
            </CardHeader>
            <CardBody>
              <UnorderedList>
                <ListItem>
                  Fast CO₂, temperature, and humidity recovery without overshoot
                </ListItem>
                <ListItem>
                  Best uniformity and control among competition
                </ListItem>
                <ListItem>
                  VentiFlow™ forced convection accelerates recovery of chamber
                  air to ISO Class 5 Cleanliness after door closing to prevent
                  contamination.
                </ListItem>
                <ListItem>
                  Filtered air circulates across the humidity pan to accelerate
                  the humidifying process.
                </ListItem>
                <ListItem>
                  Air flows gently around culture plates, causing no disturbance
                  to cell culture.
                </ListItem>
                <ListItem>
                  Blower automatically stops when the door is opened, to
                  minimize mixing of chamber and room air.
                </ListItem>
                <ListItem>
                  Comprehensive user-configurable alarms for all the parameters.
                </ListItem>
                <ListItem>
                  CelAlert™ alarm system reminds the user to replace CO₂ tank
                  and ULPA filter.
                </ListItem>
                <ListItem>
                  Intelligent data and event logger records all incubator
                  parameters for on-screen recall.
                </ListItem>
                <ListItem>
                  2 MB built-in flash memory guarantees long term storage of
                  data.
                </ListItem>
                <ListItem>
                  Diagnostic interface and online quick help provide
                  comprehensive solutions to frequently encountered problems.
                </ListItem>
                <ListItem>
                  Ductwork, plenums, and shelves are removable without tools.
                </ListItem>
              </UnorderedList>
            </CardBody>
            <CardFooter></CardFooter>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
            background={"green.500"}
            color={"white"}
          >
            <CardBody>
              <Text mb={10}>
                CO₂ incubator with 90°C MOIST HEAT DECONTAMINATION cycle has
                been proven in deactivating normally resistant fungi, bacterial
                spores and vegetative cells by the Health Protection Agency
                (HPA) in UK. Chamber is cool and dry at the end of full 15-hr
                decontamination cycle. No further wipe down is needed.
              </Text>
            </CardBody>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
            background={"green.500"}
            color={"white"}
          >
            <CardBody>
              <Text mb={10}>
                180°C HIGH HEAT STERILIZATION conforms to the International
                Standards for dry heat sterilization and proven to effectively
                deactivate normally-resistant fungi, bacterial spore and
                vegetative cells. Nontoxic and noncorrosive sterilization that
                completes within 12 hours leaving the chamber cool and dry at
                the end of the cycle.
              </Text>
            </CardBody>
          </Card>

          <Card mb={20}>
            <CardBody>
              <UnorderedList>
                <ListItem>
                  Fast CO₂, temperature, and humidity recovery without overshoot
                </ListItem>
                <ListItem>
                  Best uniformity and control among competition
                </ListItem>
                <ListItem>
                  VentiFlow™ forced convection accelerates recovery of chamber
                  air to ISO Class 5 Cleanliness after door closing to prevent
                  contamination.
                </ListItem>
                <ListItem>
                  Filtered air circulates across the humidity pan to accelerate
                  the humidifying process.
                </ListItem>
                <ListItem>
                  Air flows gently around culture plates, causing no disturbance
                  to cell culture.
                </ListItem>
                <ListItem>
                  Blower automatically stops when the door is opened, to
                  minimize mixing of chamber and room air.
                </ListItem>
                <ListItem>
                  Comprehensive user-configurable alarms for all the parameters.
                </ListItem>
                <ListItem>
                  CelAlert™ alarm system reminds the user to replace CO₂ tank
                  and ULPA filter.
                </ListItem>
                <ListItem>
                  Intelligent data and event logger records all incubator
                  parameters for on-screen recall.
                </ListItem>
                <ListItem>
                  2 MB built-in flash memory guarantees long term storage of
                  data.
                </ListItem>
                <ListItem>
                  Diagnostic interface and online quick help provide
                  comprehensive solutions to frequently encountered problems.
                </ListItem>
                <ListItem>
                  Ductwork, plenums, and shelves are removable without tools.
                </ListItem>
              </UnorderedList>
            </CardBody>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/celculture-co2-incubator.png"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  CelCulture CO₂ Incubator - CO₂ Control
                </Heading>

                <UnorderedList>
                  <ListItem>Direct heat and air jacket</ListItem>
                  <ListItem>Infrared (IR) CO₂ Sensor</ListItem>
                  <ListItem>ULPA Filter</ListItem>
                  <ListItem>ISO Class 5 work zone</ListItem>
                  <ListItem>
                    Validated 90˚C moist heat decontamination cycle
                  </ListItem>
                  <ListItem>
                    FDA-listed, Class II, 510k exempt medical device
                  </ListItem>
                  <ListItem>Available volume: 50 L, 170 L, and 240 L</ListItem>
                </UnorderedList>
              </CardBody>

              <CardFooter>
                <a href="https://www.escolifesciences.com/products/co2-incubator">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </a>
              </CardFooter>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/celculture-co2-incubator.png"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  CelCulture CO₂ Incubator - CO₂ Control, No ULPA Filter
                </Heading>

                <UnorderedList>
                  <ListItem>Direct heat and air jacket</ListItem>
                  <ListItem>Infrared (IR) CO₂ Sensor</ListItem>

                  <ListItem>
                    Validated 90˚C moist heat decontamination cycle
                  </ListItem>
                  <ListItem>
                    FDA-listed, Class II, 510k exempt medical device
                  </ListItem>
                  <ListItem>Available volume: 170 L, and 240 L</ListItem>
                </UnorderedList>
              </CardBody>

              <CardFooter>
                <a href="https://www.escolifesciences.com/products/co2-incubator">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </a>
              </CardFooter>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/celculture-co2-incubator.png"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  CelCulture CO₂ Incubator - CO₂ & O₂ Control
                </Heading>

                <UnorderedList>
                  <ListItem>CCL-_T-_</ListItem>
                  <ListItem>Direct heat and air jacket</ListItem>
                  <ListItem>Infrared (IR) CO₂ Sensor</ListItem>
                  <ListItem>Suppressed Oxygen Control</ListItem>
                  <ListItem>ULPA Filter</ListItem>
                  <ListItem>ISO Class 5 work zone</ListItem>
                  <ListItem>
                    Validated 90˚C moist heat decontamination cycle
                  </ListItem>
                  <ListItem>
                    FDA-listed, Class II, 510k exempt medical device
                  </ListItem>
                  <ListItem>Available volume: 50L, 170 L, and 240 L</ListItem>
                </UnorderedList>
              </CardBody>

              <CardFooter>
                <a href="https://www.escolifesciences.com/products/co2-incubator">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </a>
              </CardFooter>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/celculture-co2-incubator.png"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  CelCulture CO₂ Incubator - CO₂ & O₂ Control, NO ULPA Filter
                </Heading>

                <UnorderedList>
                  <ListItem>CCL-_T-_-NF</ListItem>
                  <ListItem>Direct heat and air jacket</ListItem>
                  <ListItem>Infrared (IR) CO₂ Sensor</ListItem>
                  <ListItem>Suppressed Oxygen Control</ListItem>

                  <ListItem>
                    Validated 90˚C moist heat decontamination cycle
                  </ListItem>
                  <ListItem>
                    FDA-listed, Class II, 510k exempt medical device
                  </ListItem>
                  <ListItem>Available volume: 170 L, and 240 L</ListItem>
                </UnorderedList>
              </CardBody>

              <CardFooter>
                <a href="https://www.escolifesciences.com/products/co2-incubator">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </a>
              </CardFooter>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/celculture-co2-incubator.png"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  CelCulture CO₂ Incubator - CO₂ Control, UV lamp
                </Heading>

                <UnorderedList>
                  <ListItem>CCL-_B-_-UV</ListItem>
                  <ListItem>Direct heat and air jacket</ListItem>
                  <ListItem>Infrared (IR) CO₂ Sensor</ListItem>

                  <ListItem>ULPA Filter</ListItem>
                  <ListItem>ISO Class 5 work zone</ListItem>
                  <ListItem>
                    Validated 90˚C moist heat decontamination cycle
                  </ListItem>
                  <ListItem>UV Lamp</ListItem>
                  <ListItem>
                    FDA-listed, Class II, 510k exempt medical device
                  </ListItem>
                  <ListItem>Available volume: 170 L, and 240 L</ListItem>
                </UnorderedList>
              </CardBody>

              <CardFooter>
                <a href="https://www.escolifesciences.com/products/co2-incubator">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </a>
              </CardFooter>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/celculture-co2-incubator.png"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  CelCulture CO₂ Incubator - CO₂ & O₂ Control, UV Lamp
                </Heading>

                <UnorderedList>
                  <ListItem>CCL-_T-_-UV</ListItem>
                  <ListItem>Direct heat and air jacket</ListItem>
                  <ListItem>Infrared (IR) CO₂ Sensor</ListItem>
                  <ListItem>Suppressed Oxygen Control</ListItem>
                  <ListItem>ULPA Filter</ListItem>
                  <ListItem>ISO Class 5 work zone</ListItem>
                  <ListItem>
                    Validated 90˚C moist heat decontamination cycle
                  </ListItem>
                  <ListItem>UV Lamp</ListItem>
                  <ListItem>
                    FDA-listed, Class II, 510k exempt medical device
                  </ListItem>
                  <ListItem>Available volume: 170 L, and 240 L</ListItem>
                </UnorderedList>
              </CardBody>

              <CardFooter>
                <a href="https://www.escolifesciences.com/products/co2-incubator">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </a>
              </CardFooter>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/celculture-co2-incubator.png"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  CelCulture CO₂ Incubator - CO₂ Control, Flat Door
                </Heading>

                <UnorderedList>
                  <ListItem>CCL-_B-_-FD</ListItem>
                  <ListItem>Direct heat and air jacket</ListItem>
                  <ListItem>Infrared (IR) CO₂ Sensor</ListItem>

                  <ListItem>ULPA Filter</ListItem>
                  <ListItem>ISO Class 5 work zone</ListItem>
                  <ListItem>Flat Door</ListItem>
                  <ListItem>
                    Validated 90˚C moist heat decontamination cycle
                  </ListItem>

                  <ListItem>
                    FDA-listed, Class II, 510k exempt medical device
                  </ListItem>
                  <ListItem>Available volume: 50 L, 170 L, and 240 L</ListItem>
                </UnorderedList>
              </CardBody>

              <CardFooter>
                <a href="https://www.escolifesciences.com/products/co2-incubator">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </a>
              </CardFooter>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/celculture-co2-incubator.png"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  CelCulture CO₂ Incubator - CO₂ Control, No ULPA Filter, Flat
                  Door
                </Heading>

                <UnorderedList>
                  <ListItem>CCL-_B-_-NF-FD</ListItem>
                  <ListItem>Direct heat and air jacket</ListItem>
                  <ListItem>Infrared (IR) CO₂ Sensor</ListItem>

                  <ListItem>Flat Door</ListItem>
                  <ListItem>
                    Validated 90˚C moist heat decontamination cycle
                  </ListItem>

                  <ListItem>
                    FDA-listed, Class II, 510k exempt medical device
                  </ListItem>
                  <ListItem>Available volume: 170 L, and 240 L</ListItem>
                </UnorderedList>
              </CardBody>

              <CardFooter>
                <a href="https://www.escolifesciences.com/products/co2-incubator">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </a>
              </CardFooter>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/celculture-co2-incubator.png"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  CelCulture CO₂ Incubator - CO₂ Control, UV Lamp, Flat Door
                </Heading>

                <UnorderedList>
                  <ListItem>CCL-_B-_-UV-FD</ListItem>
                  <ListItem>Direct heat and air jacket</ListItem>
                  <ListItem>Infrared (IR) CO₂ Sensor</ListItem>

                  <ListItem>ULPA Filter</ListItem>
                  <ListItem>ISO Class 5 work zone</ListItem>
                  <ListItem>Flat Door</ListItem>
                  <ListItem>
                    Validated 90˚C moist heat decontamination cycle
                  </ListItem>
                  <ListItem>UV Lamp</ListItem>
                  <ListItem>
                    FDA-listed, Class II, 510k exempt medical device
                  </ListItem>
                  <ListItem>Available volume: 170 L, and 240 L</ListItem>
                </UnorderedList>
              </CardBody>

              <CardFooter>
                <a href="https://www.escolifesciences.com/products/co2-incubator">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </a>
              </CardFooter>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/celculture-co2-incubator.png"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  CelCulture CO₂ Incubator - CO₂ & O₂ Control Flat Door
                </Heading>

                <UnorderedList>
                  <ListItem>CCL-_T-_-FD</ListItem>
                  <ListItem>Direct heat and air jacket</ListItem>
                  <ListItem>Infrared (IR) CO₂ Sensor</ListItem>
                  <ListItem>Supressed Oxygen Control</ListItem>
                  <ListItem>ULPA Filter</ListItem>
                  <ListItem>ISO Class 5 work zone</ListItem>
                  <ListItem>Flat Door</ListItem>
                  <ListItem>
                    Validated 90˚C moist heat decontamination cycle
                  </ListItem>
                  <ListItem>UV Lamp</ListItem>
                  <ListItem>
                    FDA-listed, Class II, 510k exempt medical device
                  </ListItem>
                  <ListItem>Available volume: 50 L, 170 L, and 240 L</ListItem>
                </UnorderedList>
              </CardBody>

              <CardFooter>
                <a href="https://www.escolifesciences.com/products/co2-incubator">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </a>
              </CardFooter>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/celculture-co2-incubator.png"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  CelCulture CO₂ Incubator - CO₂ & O₂ Control, No ULPA Filter,
                  Flat Door
                </Heading>

                <UnorderedList>
                  <ListItem>CCL-_T-_-NF-FD</ListItem>
                  <ListItem>Direct heat and air jacket</ListItem>
                  <ListItem>Infrared (IR) CO₂ Sensor</ListItem>
                  <ListItem>Supressed Oxygen Control</ListItem>

                  <ListItem>Flat Door</ListItem>
                  <ListItem>
                    Validated 90˚C moist heat decontamination cycle
                  </ListItem>

                  <ListItem>
                    FDA-listed, Class II, 510k exempt medical device
                  </ListItem>
                  <ListItem>Available volume: 170 L, and 240 L</ListItem>
                </UnorderedList>
              </CardBody>

              <CardFooter>
                <a href="https://www.escolifesciences.com/products/co2-incubator">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </a>
              </CardFooter>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/celculture-co2-incubator.png"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  CelCulture CO₂ Incubator - CO₂ & O₂ Control, UV Lamp, Flat
                  Door
                </Heading>

                <UnorderedList>
                  <ListItem>CCL-_T-_-UV-FD</ListItem>
                  <ListItem>Direct heat and air jacket</ListItem>
                  <ListItem>Infrared (IR) CO₂ Sensor</ListItem>
                  <ListItem>Supressed Oxygen Control</ListItem>
                  <ListItem>ULPA Filter</ListItem>
                  <ListItem>ISO Class 5 work zone</ListItem>
                  <ListItem>Flat Door</ListItem>
                  <ListItem>
                    Validated 90˚C moist heat decontamination cycle
                  </ListItem>
                  <ListItem>UV Lamp</ListItem>
                  <ListItem>
                    FDA-listed, Class II, 510k exempt medical device
                  </ListItem>
                  <ListItem>Available volume: 170 L, and 240 L</ListItem>
                </UnorderedList>
              </CardBody>

              <CardFooter>
                <a href="https://www.escolifesciences.com/products/co2-incubator">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </a>
              </CardFooter>
            </Stack>
          </Card>

          {/* Copper Chamber */}

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/COA-2007-F-in%20unit-copper-shelving.png"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  CelCulture CO₂ Incubator - CO₂ Control, Copper Chamber Door
                </Heading>

                <UnorderedList>
                  <ListItem>CCL-_B-_-Cu</ListItem>
                  <ListItem>Direct heat and air jacket</ListItem>
                  <ListItem>Infrared (IR) CO₂ Sensor</ListItem>
                  <ListItem>Copper Chamber</ListItem>
                  <ListItem>ULPA Filter</ListItem>
                  <ListItem>ISO Class 5 work zone</ListItem>

                  <ListItem>
                    Validated 90˚C moist heat decontamination cycle
                  </ListItem>
                  <ListItem>UV Lamp</ListItem>
                  <ListItem>
                    FDA-listed, Class II, 510k exempt medical device
                  </ListItem>
                  <ListItem>Available volume: 50 L, 170 L, and 240 L</ListItem>
                </UnorderedList>
              </CardBody>

              <CardFooter>
                <a href="https://www.escolifesciences.com/products/co2-incubator">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </a>
              </CardFooter>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/COA-2007-F-in%20unit-copper-shelving.png"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  CelCulture CO₂ Incubator - CO₂ & O₂ Control, Copper Chamber
                  Door
                </Heading>

                <UnorderedList>
                  <ListItem>CCL-_T-_-Cu</ListItem>
                  <ListItem>Direct heat and air jacket</ListItem>
                  <ListItem>Infrared (IR) CO₂ Sensor</ListItem>
                  <ListItem>Suppressed Oxygen Control</ListItem>
                  <ListItem>Copper Chamber</ListItem>
                  <ListItem>ULPA Filter</ListItem>
                  <ListItem>ISO Class 5 work zone</ListItem>

                  <ListItem>
                    Validated 90˚C moist heat decontamination cycle
                  </ListItem>
                  <ListItem>UV Lamp</ListItem>
                  <ListItem>
                    FDA-listed, Class II, 510k exempt medical device
                  </ListItem>
                  <ListItem>Available volume: 50 L, 170 L, and 240 L</ListItem>
                </UnorderedList>
              </CardBody>

              <CardFooter>
                <a href="https://www.escolifesciences.com/products/co2-incubator">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </a>
              </CardFooter>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/COA-2007-F-in%20unit-copper-shelving.png"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  CelCulture CO₂ Incubator - CO₂ Control, Copper Chamber, UV
                  Lamp
                </Heading>

                <UnorderedList>
                  <ListItem>CCL-_B-_-Cu-UV</ListItem>
                  <ListItem>Direct heat and air jacket</ListItem>
                  <ListItem>Infrared (IR) CO₂ Sensor</ListItem>

                  <ListItem>Copper Chamber</ListItem>
                  <ListItem>ULPA Filter</ListItem>
                  <ListItem>ISO Class 5 work zone</ListItem>

                  <ListItem>
                    Validated 90˚C moist heat decontamination cycle
                  </ListItem>
                  <ListItem>UV Lamp</ListItem>
                  <ListItem>
                    FDA-listed, Class II, 510k exempt medical device
                  </ListItem>
                  <ListItem>Available volume: 50 L, 170 L, and 240 L</ListItem>
                </UnorderedList>
              </CardBody>

              <CardFooter>
                <a href="https://www.escolifesciences.com/products/co2-incubator">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </a>
              </CardFooter>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/COA-2007-F-in%20unit-copper-shelving.png"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  CelCulture CO₂ Incubator - CO₂ & O₂ Control, Copper Chamber,
                  UV Lamp
                </Heading>

                <UnorderedList>
                  <ListItem>CCL-_T-_-Cu-UV</ListItem>
                  <ListItem>Direct heat and air jacket</ListItem>
                  <ListItem>Infrared (IR) CO₂ Sensor</ListItem>
                  <ListItem>Suppressed Oxygen Control</ListItem>
                  <ListItem>Copper Chamber</ListItem>
                  <ListItem>ULPA Filter</ListItem>
                  <ListItem>ISO Class 5 work zone</ListItem>

                  <ListItem>
                    Validated 90˚C moist heat decontamination cycle
                  </ListItem>
                  <ListItem>UV Lamp</ListItem>
                  <ListItem>
                    FDA-listed, Class II, 510k exempt medical device
                  </ListItem>
                  <ListItem>Available volume: 170 L, and 240 L</ListItem>
                </UnorderedList>
              </CardBody>

              <CardFooter>
                <a href="https://www.escolifesciences.com/products/co2-incubator">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </a>
              </CardFooter>
            </Stack>
          </Card>
        </CardBody>
        <CardFooter>
          <Link to="/Contact">
            <Button colorScheme="blue">Contact us</Button>
          </Link>
        </CardFooter>
      </Card>
      <GoToTop />
    </Container>
  );
}

export default CellCultureCO2Incubator;
