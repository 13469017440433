import React from "react";
import {
  Container,
  Heading,
  Card,
  CardHeader,
  CardBody,
  Text,
  CardFooter,
  Button,
  UnorderedList,
  ListItem,
  Image,
  Stack,
} from "@chakra-ui/react";

import { Link } from "react-router-dom";
import GoToTop from "../../GoToTop/GoToTop";

function TableTopCentrifuge() {
  return (
    <Container maxW={"container.lg"}>
      <Card>
        <CardHeader>
          <Heading size="lg" bg={"blue.500"} color={"white"} p={5}>
            Table-top Ventilated Centrifuge
          </Heading>
        </CardHeader>
        <CardBody>
          <Text mb={10}>
            A laboratory centrifuge is used to separate fluids based on density.
            Samples in a containing vessel are subjected to high-speed rotation
            to achieve separation.
          </Text>

          <Text mb={20}>
            Esco offers the best choice for high performance and superior
            quality centrifuge. It is equipped with maintenance-free motors,
            durable mechanism, and various safety and ergonomic features. Its
            high-quality rotors and adapters are designed to fit all your
            application needs and suit various consumable tubes, strips, and
            plates.
          </Text>

          <Card mb={20}>
            <CardBody>
              <Image
                src="https://www.escolifesciences.com/images/upload/tabletop-centrifuge.png"
                alt="Tabletop centrifuge "
                borderRadius="lg"
              />
              <Stack mt="6" spacing="3">
                <Heading size="md">Tabletop centrifuge </Heading>
                <Text>
                  Tabletop centrifuge is sometimes referred to as benchtop
                  centrifuge. It is a larger type of centrifuge ideal for
                  separating larger liquid samples at high speed (usually more
                  than 4,000 rpm). While micro centrifuges run liquid samples
                  using fixed-angle rotor only, most tabletop centrifuge have
                  another type of rotor called swing-bucket. This type of rotor
                  can spin samples up to 90° angle at lower speed. Tabletop
                  centrifuge is commonly used in running various sample tubes at
                  once.
                </Text>
              </Stack>
            </CardBody>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={20}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/versati-tabletop-ventilated-centrifuge.png"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  Versati Tabletop Ventilated Centrifuge
                </Heading>

                <UnorderedList>
                  <ListItem fontWeight={"extrabold"}>TCV-1500</ListItem>
                  <ListItem>Large capacity liquid samples</ListItem>
                  <ListItem>Ventilated temperature</ListItem>
                  <ListItem>Audio and visual notification system</ListItem>
                  <ListItem>Superior safety features</ListItem>
                  <ListItem>
                    Available fixed-angle and swing-bucket rotors
                  </ListItem>
                  <ListItem>
                    Time-setting spin up to 99 hrs 59 min 10sec
                  </ListItem>
                  <ListItem>Dimensions (WxDxH): 438 x 532 x 374 mm</ListItem>
                </UnorderedList>
              </CardBody>
              <CardFooter>
                <Link to={"/Contact"}>
                  <Button colorScheme="blue">Get Quote</Button>
                </Link>
              </CardFooter>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={20}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/versati-tabletop-refrigerated-centrifuge.png"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  Versati Tabletop Ventilated Centrifuge
                </Heading>

                <UnorderedList>
                  <ListItem fontWeight={"extrabold"}>TCR-1500</ListItem>
                  <ListItem>Large capacity liquid samples</ListItem>
                  <ListItem>Refrigerated (-20°C to 40°C)</ListItem>
                  <ListItem>Audio and visual notification system</ListItem>
                  <ListItem>Superior safety features</ListItem>
                  <ListItem>
                    Available fixed-angle and swing-bucket rotors
                  </ListItem>
                  <ListItem>Dimensions (WxDxH): 715 x 528 x 373 mm</ListItem>
                </UnorderedList>
              </CardBody>
              <CardFooter>
                <Link to={"/Contact"}>
                  <Button colorScheme="blue">Get Quote</Button>
                </Link>
              </CardFooter>
            </Stack>
          </Card>

          <UnorderedList mb={10}>
            <ListItem>
              Maintenance-free, brushless motor that prolongs the lifespan and
              saves maintenance cost
            </ListItem>
            <ListItem>
              Dedicated superior safety features including safety lid lock
              design, imbalance detection system, rotor-auto recognition,
              overspeed and over temperature protection
            </ListItem>
            <ListItem>
              Intelligent microprocessor control system that can display set and
              actual parameters simultaneously
            </ListItem>
            <ListItem>
              Emergency switch function enables manual opening of the lid in
              case of power failure or malfunction
            </ListItem>
            <ListItem>
              Automatic conversion of parameters: rcf to rpm and vice versa;
              Celsius to Fahrenheit and vice versa
            </ListItem>
            <ListItem>
              Real-time modification of parameters during the operation to
              improve your working efficiency
            </ListItem>
            <ListItem>
              Wide variety of available accessories that suit different
              application needs
            </ListItem>
            <ListItem>
              Easy-to-clean external surface coated with Isocide™ to eliminate
              99.9% of surface bacteria within 24 hours of exposure
            </ListItem>
            <ListItem>
              Integrated design of interior centrifuge chamber
            </ListItem>
            <ListItem>
              High-temperature control range for refrigerated models (-20°C to
              +40°C)
            </ListItem>
            <ListItem>
              Fast pre-cooling function to cool the chamber temperature before
              starting the run
            </ListItem>
            <ListItem>
              Environment-friendly CFC- and HCFC-free refrigerants
            </ListItem>
          </UnorderedList>
        </CardBody>
        <CardFooter>
          <Link to={"/Contact"}>
            <Button colorScheme="blue">Contact Us</Button>
          </Link>
        </CardFooter>
      </Card>

      <GoToTop />
    </Container>
  );
}

export default TableTopCentrifuge;
