import React from "react";
import {
  Container,
  ListItem,
  CardFooter,
  Button,
  UnorderedList,
  Card,
  Image,
  Stack,
  Heading,
  CardBody,
  Text,
  CardHeader,
  Divider,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import GoToTop from "../../GoToTop/GoToTop";

function PharmaIsolators() {
  return (
    <Container maxW={"container.lg"}>
      <Card mb={20}>
        <CardHeader>
          <Heading size="lg" bg={"blue.500"} color={"white"} p={5}>
            Pharma Isolators
          </Heading>
        </CardHeader>
        <CardBody>
          <Text mb={10}>
            Isolators are clean air devices providing a complete separation
            between an aseptic process (hazardous/non-hazardous), the technical
            personnel, and the surrounding work environment. Isolators are
            generally used in applications requiring a high degree of protection
            from external elements or contaminants, and they can serve as
            alternatives to sophisticated cleanrooms.
          </Text>

          <Text mb={10}>
            Isolators typically feature built-in air filtration systems capable
            of significantly lowering the particle count in an enclosed area.
            The relatively compact size of the isolator's interior makes it
            easier to clean with gas or vapor sterilizing agents. Isolators can
            be configured to have either positive or negative pressure relative
            to the surrounding environment depending on the application.
          </Text>

          <Text mb={20}>
            Positive pressure isolators provide product protection against
            contaminants, hence maintaining sterility; while negative pressure
            isolators, on the other hand, provide personnel and or environmental
            protection by containing the hazardous or toxic materials inside the
            isolator.
          </Text>

          <Image
            objectFit="cover"
            margin={"auto"}
            src="https://www.escolifesciences.com/images/product-img/full/Isolators%20-%20General%20Image-min.png"
            alt="Pharma Isolators"
          />

          <Divider mb={20} />

          {/* Products */}
          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/Aseptic%20Isolators-min.png"
              alt="Aseptic Isolators"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  Aseptic Isolators
                </Heading>

                <Text mb={10}>
                  Aseptic Isolators are critical for the provision of an
                  isolated ISO Class 5/Grade A environment required for the
                  manufacture of sterile products and for carrying out aseptic
                  processes. These types of isolators can carry out automated
                  pressure hold tests, automated bio-decontamination, and
                  continuous environmental monitoring to ensure the maintenance
                  of the required condition.
                </Text>

                <UnorderedList>
                  <ListItem>Cell Therapy/Bioprocessing/Cell</ListItem>
                  <ListItem>Electronics/Semi-conductors Production</ListItem>
                  <ListItem>Pharmaceutical Manufacturing</ListItem>
                  <ListItem>Research and Development</ListItem>
                  <ListItem>Sterility Testing</ListItem>
                </UnorderedList>
              </CardBody>

              <CardFooter>
                <Link to="/Contact">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </Link>
              </CardFooter>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/Containment%20Isolators-min.png"
              alt="Containment Isolators"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  Containment Isolators
                </Heading>

                <Text mb={10}>
                  Containment Isolators are often designed with a negatively
                  pressured system for the containment of the environment used
                  for processing hazardous products and highly potent active
                  pharmaceutical ingredients (HPAPIs). This type of isolator is
                  typically in turbulent airflow, capable of carrying out
                  automated pressure hold test, with breach compensatory
                  mechanism to prevent the escape of the hazardous product. In
                  this type of system, operator protection is the priority!
                </Text>

                <UnorderedList>
                  <ListItem>HPAPIs and Potent Powder Handling</ListItem>
                  <ListItem>Pharmaceutical Manufacturing</ListItem>
                  <ListItem>Research and Development</ListItem>
                  <ListItem>
                    Weighing and Dispensing of Powders/Excipients
                  </ListItem>
                </UnorderedList>
              </CardBody>

              <CardFooter>
                <Link to="/Contact">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </Link>
              </CardFooter>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/Class%20III%20BSC%20Isolators-min.png"
              alt="Class III BSC-Configured Isolators"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  Class III BSC-Configured Isolators
                </Heading>

                <Text mb={10}>
                  Class III BSC-configured isolators are configured to operate
                  as a Class III Biological Safety Cabinet. A Class III BSC is a
                  closed-system airflow cabinet designed to provide protection
                  from biohazards by maintaining negative pressure inside the
                  cabinet and providing a rigid leak-tight physical barrier
                  between the worker and the source of the biohazard.
                </Text>

                <UnorderedList>
                  <ListItem>
                    Cell Processing/Bioprocessing/Cell Cultures
                  </ListItem>
                  <ListItem>
                    Laboratory containment for handling biological agents of up
                    to level 4
                  </ListItem>
                  <ListItem>Research and Development</ListItem>
                  <ListItem>Virus and Vaccine Production</ListItem>
                </UnorderedList>
              </CardBody>

              <CardFooter>
                <Link to="/Contact">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </Link>
              </CardFooter>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/Isolators%20-%20General%20Image-min.png"
              alt="Hospital Compounding Isolators"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  Hospital Compounding Isolators
                </Heading>
                <Text mb={10}>
                  Isolators are now being increasingly utilized for Pharmacy
                  Compounding applications. In the United States Pharmacopeia,
                  two (2) types of isolators are being described for this
                  process:
                </Text>
                <Text mb={10}>
                  Compounding Aseptic Isolator (CAI) - provides a safe and clean
                  environment for compounding of non-hazardous, sterile drug
                  preparations and IV admixtures in compliance with USP 797
                  criteria.
                </Text>
                <Text mb={10}>
                  Compounding Aseptic Containment Isolator (CACI) - provides a
                  safe and clean environment for compounding of hazardous drug
                  preparations in compliance with USP 797 and 800 criteria. It
                  is suitable for work involving hazardous materials,
                  antineoplastic, or cytotoxic compounding applications.
                </Text>

                <UnorderedList>
                  <ListItem>Chemotherapy/Hazardous Drug Handling</ListItem>
                  <ListItem>Cosmetics/Cosmeceuticals</ListItem>
                  <ListItem>Research and Development</ListItem>
                  <ListItem>Sterile/Aseptic Compounding</ListItem>
                  <ListItem>TPN Formulation and Compounding</ListItem>
                </UnorderedList>
              </CardBody>

              <CardFooter>
                <Link to="/Contact">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </Link>
              </CardFooter>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/Cell%20Processing%20Isolator-min.jpg"
              alt="Cell Processing Isolator (CPI)"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  Cell Processing Isolator (CPI)
                </Heading>

                <Text mb={10}>
                  Cell Processing Isolator (CPI) is an integrated system that
                  combines several types of equipment into one isolation
                  technology. It is designed to isolate the process to ensure
                  operator safety without compromising product quality. It also
                  provides a sterile ISO Class 5/Grade A environment that is
                  required to carry out aseptic processes.
                </Text>

                <UnorderedList>
                  <ListItem>Allogenic Cell Therapy</ListItem>
                  <ListItem>Aseptic Processing</ListItem>
                  <ListItem>Autologous Cell Therapy</ListItem>
                  <ListItem>Cell Banking</ListItem>
                  <ListItem>Cell Processing</ListItem>
                  <ListItem>cGMP Manufacturing</ListItem>
                  <ListItem>Monoclonal Antibody Production</ListItem>
                  <ListItem>Vaccine Research</ListItem>
                  <ListItem>Virus Production</ListItem>
                </UnorderedList>
              </CardBody>

              <CardFooter>
                <Link to="/Contact">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </Link>
              </CardFooter>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/Filling%20Line%20Isolator-min.png"
              alt="Formulation and Filling Line Isolator"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  Formulation and Filling Line Isolator
                </Heading>

                <Text mb={10}>
                  Esco partners with filling line companies to create a
                  client-specific technology, which utilizes cGMP compliant
                  isolators and high-quality filling line
                  accessories/technologies, to ensure product safety and
                  sterility throughout the entire manufacturing cycle.
                </Text>

                <Text>
                  Enclosure systems for this technology can range from open and
                  closed Restricted Access Barrier Systems (o/cRABS) to
                  leak-tight isolation technologies compliant with international
                  GMP standards.
                </Text>
              </CardBody>

              <CardFooter>
                <Link to="/Contact">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </Link>
              </CardFooter>
            </Stack>
          </Card>
        </CardBody>
      </Card>
      <GoToTop />
    </Container>
  );
}

export default PharmaIsolators;
