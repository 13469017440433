import React from "react";
import {
  Container,
  Heading,
  SimpleGrid,
  Box,
  Stack,
  Text,
} from "@chakra-ui/react";

import { Link } from "react-router-dom";

import { FiFacebook, FiInstagram, FiYoutube } from "react-icons/fi";
import { motion } from "framer-motion";
import wave from "../../Assets/wave.svg";

import GoToTop from "../GoToTop/GoToTop";

function Footer() {
  return (
    <Box
      p={[2, 10]}
      backgroundImage={wave}
      backgroundPosition={"bottom"}
      backgroundRepeat={"no-repeat"}
      backgroundSize={"cover"}
    >
      <Container maxW={"container.lg"}>
        <SimpleGrid columns={["1", "3"]} spacing={1}>
          <Box padding={10} color={"white"}>
            <Stack direction={["column"]} spacing={"10"}>
              <Box>
                <Heading mb={5} fontWeight={"light"}>
                  <Link to="/">NVAK</Link>
                </Heading>
                <Text fontSize={"sm"}>
                  Dedication to provide quality technical and management
                  services to our valuable Segments.
                </Text>
              </Box>

              <Box>
                <Stack direction={["row"]} spacing={5}>
                  <a href="https://www.facebook.com/">
                    <Text
                      as={motion.div}
                      whileHover={{ scale: 1.2 }}
                      whileTap={{ scale: 0.9 }}
                    >
                      <FiFacebook size={"2em"} />
                    </Text>
                  </a>
                  <a href="https://www.instagram.com/">
                    <Text
                      as={motion.div}
                      whileHover={{ scale: 1.2 }}
                      whileTap={{ scale: 0.9 }}
                    >
                      <FiInstagram size={"2em"} />
                    </Text>
                  </a>
                  <a href="https://www.youtube.com/">
                    <Text
                      as={motion.div}
                      whileHover={{ scale: 1.2 }}
                      whileTap={{ scale: 0.9 }}
                    >
                      <FiYoutube size={"2em"} />
                    </Text>
                  </a>
                </Stack>
              </Box>
            </Stack>
          </Box>

          <Box color={"white"} padding={10}>
            <Heading mb={5} fontWeight={"light"}>
              Support
            </Heading>
            <Stack direction={"column"}>
              <Box
                as={motion.div}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <Link to="/Products">Products</Link>
              </Box>
              <Box
                as={motion.div}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <Link to="/Services">Services</Link>
              </Box>
              <Box
                as={motion.div}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <Link to="/Contact">Connect with us</Link>
              </Box>
            </Stack>
          </Box>

          <Box color={"white"} padding={10}>
            <Heading fontWeight={"light"} fontSize={"3xl"} mb={5}>
              Find us at
            </Heading>

            <Text fontSize={"sm"}>
              <Text as={"span"} fontWeight={"bold"}>
                REG Office -
              </Text>{" "}
              Plot No. 73, Survey No. 602-605, Flat No. 302 Anaga Residency,{" "}
              <br />
              Osmania University Teacher's Colony, Kapra, Hyderabad, India
              500094
              <br />
              <br />
              N Vamsi Krishna <br />{" "}
              <Text as={"span"} fontWeight={"bold"}>
                Tel
              </Text>{" "}
              : 8179114666
            </Text>
          </Box>
        </SimpleGrid>
        <Box p={1} color={"white"} textAlign={"center"}>
          <Stack direction={["row"]} justifyContent={"center"}>
            <Box>
              <Text>&#169; Copyright</Text>
            </Box>
            <Box>
              <Text>All rights reserved</Text>
            </Box>
          </Stack>
        </Box>
      </Container>
      <GoToTop />
    </Box>
  );
}

export default Footer;
