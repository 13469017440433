import {
  Container,
  Heading,
  Card,
  CardFooter,
  CardBody,
  CardHeader,
  Text,
  Button,
  ListItem,
  Image,
  Stack,
  UnorderedList,
} from "@chakra-ui/react";
import React from "react";

import { Link } from "react-router-dom";

function PcrCabinets() {
  return (
    <Container maxW={"container.lg"}>
      <Card>
        <CardHeader>
          <Heading size="lg" bg={"blue.500"} color={"white"} p={5}>
            {" "}
            PCR Cabinates
          </Heading>
        </CardHeader>
        <CardBody>
          <Text>
            Polymerase Chain Reaction (PCR) cabinet provides HEPA-filtered
            vertical laminar flow to purge the work area of contaminants between
            amplifications and during preparatory procedures.
          </Text>
          <br />
          <Text mb={20}>
            Esco offers premium performance for a price that is competitive with
            conventional “dead” air boxes. Its PCR cabinet’s HEPA-filtered
            laminar flow and UV decontamination technology provides superior
            protection against cross-contamination within the main chamber
            making it the proven solution for contaminant-free PCR.
          </Text>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={20}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/streamline-pcr-cabinet.png"
            />

            <Stack>
              <CardBody>
                <Text>
                  In practice, all polymerase chain reaction (PCR) procedures
                  are conducted in the same room. Under these circumstances, PCR
                  Cabinets are used for reagent preparation and sample
                  preparation to minimize contamination. Because of the high
                  copy number generated during PCR, it is essential to prevent
                  possible contamination of the PCR.
                </Text>
              </CardBody>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={20}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/airstream-pcr-cabinet.png"
            />

            <Stack>
              <CardBody>
                <Text>
                  Polymerase Chain Reaction is fundamental to almost all
                  applications requiring a high copy number of starting material
                  and is used in all laboratories working with DNA and RNA.
                  Because of the high copy number generated during PCR, it is
                  essential to prevent possible contamination of the PCR .
                  Showing 2 results
                </Text>
              </CardBody>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={20}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/streamline-pcr-cabinet.png"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  Streamline® Polymerase Chain Reaction Cabinet
                </Heading>

                <UnorderedList>
                  <ListItem fontWeight={"extrabold"}>SCR-_A_</ListItem>
                  <ListItem>HEPA-filtered laminar flow</ListItem>
                  <ListItem>ISO Class 3 work zone</ListItem>
                  <ListItem>
                    Equipped with German-made ebm-papst® motors with external
                    rotor design
                  </ListItem>
                  <ListItem>UV decontamination technology</ListItem>
                </UnorderedList>
              </CardBody>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={20}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/airstream-pcr-cabinet.png"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  Airstream® Polymerase Chain Reaction Cabinet
                </Heading>

                <UnorderedList>
                  <ListItem fontWeight={"extrabold"}>PCR-_A_</ListItem>
                  <ListItem>
                    Pre-filter and main HEPA filter with a typical efficiency of
                    greater than 99.99% at 0.3 microns
                  </ListItem>
                  <ListItem>
                    Protection against cross-contamination within the main
                    chamber
                  </ListItem>
                  <ListItem>
                    Sentinel™ Silver Microprocessor controller
                  </ListItem>
                  <ListItem>Available sizes: 3 ft, 4 ft</ListItem>
                </UnorderedList>
              </CardBody>
            </Stack>
          </Card>
        </CardBody>
        <CardFooter>
          <Link to={"/Contact"}>
            <Button colorScheme="blue">Contact Us</Button>
          </Link>
        </CardFooter>
      </Card>
    </Container>
  );
}

export default PcrCabinets;
