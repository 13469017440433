import React from "react";
import {
  Card,
  CardBody,
  Container,
  Text,
  Heading,
  Button,
  Box,
  Image,
  Stack,
  CardHeader,
  CardFooter,
  UnorderedList,
  ListItem,
  Divider,
} from "@chakra-ui/react";

import horizontallaminarflow from "../../../Assets/horizontal-laminar-flow-cabinet-airflow.jpg";

import { Link } from "react-router-dom";

import GoToTop from "../../GoToTop/GoToTop";

function LaminarFlowCabinet() {
  return (
    <Container maxW={"container.lg"}>
      <Card mb={20}>
        <CardHeader>
          <Heading size="lg" bg={"blue.500"} color={"white"} p={5}>
            {" "}
            Laminar Flow Cabinet
          </Heading>
        </CardHeader>
        <CardBody>
          <Text mb={20}>
            A laminar flow cabinet keeps a controlled work surface for
            applications requiring a sterile work space whereas contaminant
            access is being prevented by a constant filtered airflow across the
            surface. Esco’s laminar flow cabinet is a product of robust
            engineering combined with strategic ergonomic design. It provides
            long-term reliability and ultimate user comfort making it ideal for
            use in electronics assembly, plant tissue culture, culture media
            preparation, and other non-hazardous applications that need sterile
            environment.
          </Text>

          <Box mb={20}>
            <Heading fontSize={"md"} mb={5}>
              Must-Have Features To Consider:
            </Heading>
            <UnorderedList>
              <ListItem>Low power consumption</ListItem>
              <ListItem>Low noise level</ListItem>
              <ListItem>
                Equipped with an energy-efficient motor/blower
              </ListItem>
              <ListItem>
                Cost-effective ULPA filter (ISO Class 3 work zone) with greater
                than 99.999% efficiency at 0.1-0.3 µm which is 10x better
                compared to HEPA filter
              </ListItem>
              <ListItem>Comfortable lighting and legroom</ListItem>
              <ListItem>
                Intuitive and easy-to-use microprocessor controller
              </ListItem>
              <ListItem>Stable and self-compensating airflow</ListItem>
            </UnorderedList>
          </Box>

          <Divider mb={20} />

          {/* Horizontal */}
          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src={horizontallaminarflow}
              alt="horizontal laminar-flow"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  Horizontal Laminar Flow Cabinet{" "}
                </Heading>

                <Text mb={10}>
                  Horizontal Laminar Flow Cabinet provides a sterile work zone
                  through a purified air that travels in a horizontal,
                  unidirectional stream and leaves the main work chamber across
                  the entire open front of the cabinet.
                </Text>

                <Heading fontSize={"md"} mb={5}>
                  Applications -
                </Heading>
                <UnorderedList>
                  <ListItem>Plant tissue culture</ListItem>
                  <ListItem>Media plate preparation</ListItem>
                  <ListItem>Electronics inspection</ListItem>
                  <ListItem>Medical device assembly</ListItem>
                </UnorderedList>
              </CardBody>

              <CardFooter>
                <Link to="/Contact">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </Link>
              </CardFooter>
            </Stack>
          </Card>

          {/* Vertical */}
          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src={horizontallaminarflow}
              alt="horizontal laminar-flow"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  Vertical Laminar Flow Cabinet
                </Heading>

                <Text mb={10}>
                  Vertical Laminar Flow Cabinet provides sample protection as
                  purified air travels across the working zone of the cabinet in
                  a vertical, unidirectional stream and leaves the main work
                  chamber across the entire open front of the cabinet. Most
                  vertical laminar flow benches also have perforations at the
                  back wall of the work zone which are designed to eliminate air
                  turbulence and the possibility of dead air corners in the work
                  zone.
                </Text>

                <Heading fontSize={"md"} mb={5}>
                  Applications -
                </Heading>
                <UnorderedList>
                  <ListItem>Microbiology (non-pathogenic)</ListItem>
                  <ListItem>Forensics</ListItem>
                  <ListItem>Sterile product compounding</ListItem>
                </UnorderedList>
              </CardBody>

              <CardFooter>
                <Link to="/Contact">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </Link>
              </CardFooter>
            </Stack>
          </Card>

          {/* Products */}
          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/airstream-gen-3-vertical-laminar-flow-cabinet-LVS.png"
              alt="horizontal laminar-flow"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  Airstream® Gen 3 Vertical Laminar Flow Cabinet – Stainless
                  Steel Side Wall, Fixed Sash
                </Heading>

                <UnorderedList>
                  <ListItem fontWeight={"extrabold"}>LVS-_AG-F_</ListItem>
                  <ListItem>ULPA Filter</ListItem>
                  <ListItem>ISO Class 3 work zone</ListItem>
                  <ListItem>
                    DC ECM blower for up to 70% energy savings
                  </ListItem>
                  <ListItem>Low noise at 52.4 dBA</ListItem>
                  <ListItem>Stainless steel side walls</ListItem>
                  <ListItem>Fixed Sash</ListItem>
                  <ListItem>Internal height: 2 ft</ListItem>
                  <ListItem>Available sizes: 3 ft, 4 ft, 5 ft, 6ft</ListItem>
                </UnorderedList>
              </CardBody>

              <CardFooter>
                <Link to="/Contact">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </Link>
              </CardFooter>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/airstream-gen-3-vertical-laminar-flow-cabinet-LVS.png"
              alt="horizontal laminar-flow"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  Airstream® Gen 3 Vertical Laminar Flow Cabinet – Stainless
                  Steel Side Wall, Fixed Sash
                </Heading>

                <UnorderedList>
                  <ListItem fontWeight={"extrabold"}>LVS-_CG-F_</ListItem>
                  <ListItem>ULPA Filter</ListItem>
                  <ListItem>ISO Class 3 work zone</ListItem>
                  <ListItem>
                    DC ECM blower for up to 70% energy savings
                  </ListItem>
                  <ListItem>Low noise at 52.4 dBA</ListItem>
                  <ListItem>Stainless steel side walls</ListItem>
                  <ListItem>Fixed Sash</ListItem>
                  <ListItem>Internal height: 3 ft</ListItem>
                  <ListItem>Available sizes: 4 ft, 6 ft, 8 ft</ListItem>
                </UnorderedList>
                <Text fontWeight={"bold"} mt={6} color={"red.500"}>
                  <a href="https://www.escolifesciences.com/products/laminar-flow-cabinet/airstream-gen-3-vertical-laminar-flow-cabinet">
                    Know more
                  </a>
                </Text>
              </CardBody>

              <CardFooter>
                <Link to="/Contact">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </Link>
              </CardFooter>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/airstream-gen-3-vertical-laminar-flow-cabinet-LVS-sliding.png"
              alt="horizontal laminar-flow"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  Airstream® Gen 3 Vertical Laminar Flow Cabinet – Stainless
                  Steel Side Wall, Sliding Sash
                </Heading>

                <UnorderedList>
                  <ListItem fontWeight={"extrabold"}>LVS-_AG-S_</ListItem>
                  <ListItem>ULPA Filter</ListItem>
                  <ListItem>ISO Class 3 work zone</ListItem>
                  <ListItem>
                    DC ECM blower for up to 70% energy savings
                  </ListItem>
                  <ListItem>Low noise at 52.4 dBA</ListItem>
                  <ListItem>Stainless steel side walls</ListItem>
                  <ListItem>Sliding Sash</ListItem>
                  <ListItem>Internal height: 2 ft</ListItem>
                  <ListItem>Available sizes: 4 ft, 5 ft, 6 ft</ListItem>
                </UnorderedList>
                <Text fontWeight={"bold"} mt={6} color={"red.500"}>
                  <a href="https://www.escolifesciences.com/products/laminar-flow-cabinet/airstream-gen-3-vertical-laminar-flow-cabinet">
                    Know more
                  </a>
                </Text>
              </CardBody>

              <CardFooter>
                <Link to="/Contact">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </Link>
              </CardFooter>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/airstream-gen-3-vertical-laminar-flow-cabinet-LVG.png"
              alt="horizontal laminar-flow"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  Airstream® Gen 3 Vertical Laminar Flow Cabinet – Glass Side
                  Wall, Fixed Sash
                </Heading>

                <UnorderedList>
                  <ListItem fontWeight={"extrabold"}>LVG-_AG-F_</ListItem>
                  <ListItem>ULPA Filter</ListItem>
                  <ListItem>ISO Class 3 work zone</ListItem>
                  <ListItem>
                    DC ECM blower for up to 70% energy savings
                  </ListItem>
                  <ListItem>Low noise at 52.4 dBA</ListItem>
                  <ListItem>Glass side walls</ListItem>
                  <ListItem>Fixed Sash</ListItem>
                  <ListItem>Internal height: 2 ft</ListItem>
                  <ListItem>Available sizes: 3 ft, 4 ft, 5 ft, 6 ft</ListItem>
                </UnorderedList>
              </CardBody>

              <CardFooter>
                <Link to="/Contact">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </Link>
              </CardFooter>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/airstream-gen-3-vertical-laminar-flow-cabinet-LVG-sliding.png"
              alt="horizontal laminar-flow"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  Airstream® Gen 3 Vertical Laminar Flow Cabinet – Glass Side
                  Wall, Sliding Sash
                </Heading>

                <UnorderedList>
                  <ListItem fontWeight={"extrabold"}>LVG-_AG-S_</ListItem>
                  <ListItem>ULPA Filter</ListItem>
                  <ListItem>ISO Class 3 work zone</ListItem>
                  <ListItem>
                    DC ECM blower for up to 70% energy savings
                  </ListItem>
                  <ListItem>Low noise at 52.4 dBA</ListItem>
                  <ListItem>Glass side walls</ListItem>
                  <ListItem>Sliding Sash</ListItem>
                  <ListItem>Internal height: 2 ft</ListItem>
                  <ListItem>Available sizes: 4 ft, 5 ft, 6 ft</ListItem>
                </UnorderedList>
              </CardBody>

              <CardFooter>
                <Link to="/Contact">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </Link>
              </CardFooter>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/airstream-gen-3-vertical-laminar-flow-cabinet-LVG.png"
              alt="horizontal laminar-flow"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  Airstream® Gen 3 Vertical Laminar Flow Cabinet – Glass Side
                  Wall, Fixed Sash
                </Heading>

                <UnorderedList>
                  <ListItem fontWeight={"extrabold"}>LVG-_CG-F_</ListItem>
                  <ListItem>ULPA Filter</ListItem>
                  <ListItem>ISO Class 3 work zone</ListItem>
                  <ListItem>
                    DC ECM blower for up to 70% energy savings
                  </ListItem>
                  <ListItem>Low noise at 55.8 dBA</ListItem>
                  <ListItem>Glass side walls</ListItem>
                  <ListItem>Fixed Sash</ListItem>
                  <ListItem>Internal height: 3 ft</ListItem>
                  <ListItem>Available sizes: 4 ft, 6 ft, 8 ft</ListItem>
                </UnorderedList>
              </CardBody>

              <CardFooter>
                <Link to="/Contact">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </Link>
              </CardFooter>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/airstream-gen-3-horizontal-laminar-flow-cabinet-LHS.png"
              alt="horizontal laminar-flow"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  Airstream® Gen 3 Horizontal Laminar Flow Cabinet – Stainless
                  Steel Side Wall, Fixed Sash
                </Heading>

                <UnorderedList>
                  <ListItem fontWeight={"extrabold"}>LHS-_AG-F_</ListItem>
                  <ListItem>ULPA Filter</ListItem>
                  <ListItem>ISO Class 3 work zone</ListItem>
                  <ListItem>
                    DC ECM blower for up to 70% energy savings
                  </ListItem>
                  <ListItem>Low noise at 55.8 dBA</ListItem>
                  <ListItem>Stainless steel side walls</ListItem>
                  <ListItem>Fixed Sash</ListItem>
                  <ListItem>Internal height: 2 ft</ListItem>
                  <ListItem>Available sizes: 3 ft, 4 ft, 5 ft, 6 ft</ListItem>
                </UnorderedList>
              </CardBody>

              <CardFooter>
                <Link to="/Contact">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </Link>
              </CardFooter>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/airstream-gen-3-horizontal-laminar-flow-cabinet-LHS.png"
              alt="horizontal laminar-flow"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  Airstream® Gen 3 Horizontal Laminar Flow Cabinet – Stainless
                  Steel Side Wall, Fixed Sash
                </Heading>

                <UnorderedList>
                  <ListItem fontWeight={"extrabold"}>LHS-_BG-F_</ListItem>
                  <ListItem>ULPA Filter</ListItem>
                  <ListItem>ISO Class 3 work zone</ListItem>
                  <ListItem>
                    DC ECM blower for up to 70% energy savings
                  </ListItem>
                  <ListItem>Low noise at 55.8 dBA</ListItem>
                  <ListItem>Stainless steel side walls</ListItem>
                  <ListItem>Fixed Sash</ListItem>
                  <ListItem>Internal height: 2.50 ft</ListItem>
                  <ListItem>Available sizes: 3 ft, 4 ft, 5 ft, 6 ft</ListItem>
                </UnorderedList>
              </CardBody>

              <CardFooter>
                <Link to="/Contact">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </Link>
              </CardFooter>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/airstream-gen-3-horizontal-laminar-flow-cabinet-LHS.png"
              alt="horizontal laminar-flow"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  Airstream® Gen 3 Horizontal Laminar Flow Cabinet – Stainless
                  Steel Side Wall, Fixed Sash
                </Heading>

                <UnorderedList>
                  <ListItem fontWeight={"extrabold"}>LHS-_CG-F_</ListItem>
                  <ListItem>ULPA Filter</ListItem>
                  <ListItem>ISO Class 3 work zone</ListItem>
                  <ListItem>
                    DC ECM blower for up to 70% energy savings
                  </ListItem>
                  <ListItem>Low noise at 55.8 dBA</ListItem>
                  <ListItem>Stainless steel side walls</ListItem>
                  <ListItem>Fixed Sash</ListItem>
                  <ListItem>Internal height: 3 ft</ListItem>
                  <ListItem>Available sizes: 4 ft, 6 ft, 8 ft</ListItem>
                </UnorderedList>
              </CardBody>

              <CardFooter>
                <Link to="/Contact">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </Link>
              </CardFooter>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/airstream-gen-3-horizontal-laminar-flow-cabinet-LHG.png"
              alt="horizontal laminar-flow"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  Airstream® Gen 3 Horizontal Laminar Flow Cabinet – Glass Side
                  Wall, Fixed Sash
                </Heading>

                <UnorderedList>
                  <ListItem fontWeight={"extrabold"}>LHG-_AG-F_</ListItem>
                  <ListItem>ULPA Filter</ListItem>
                  <ListItem>ISO Class 3 work zone</ListItem>
                  <ListItem>
                    DC ECM blower for up to 70% energy savings
                  </ListItem>
                  <ListItem>Low noise at 55.8 dBA</ListItem>
                  <ListItem>Glass side walls</ListItem>
                  <ListItem>Fixed Sash</ListItem>
                  <ListItem>Internal height: 2 ft</ListItem>
                  <ListItem>Available sizes: 3 ft ,4 ft, 5 ft, 6 ft</ListItem>
                </UnorderedList>
              </CardBody>

              <CardFooter>
                <Link to="/Contact">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </Link>
              </CardFooter>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/airstream-gen-3-horizontal-laminar-flow-cabinet-LHG.png"
              alt="horizontal laminar-flow"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  Airstream® Gen 3 Horizontal Laminar Flow Cabinet – Glass Side
                  Wall, Fixed Sash
                </Heading>

                <UnorderedList>
                  <ListItem fontWeight={"extrabold"}>LHG-_CG-F_</ListItem>
                  <ListItem>ULPA Filter</ListItem>
                  <ListItem>ISO Class 3 work zone</ListItem>
                  <ListItem>
                    DC ECM blower for up to 70% energy savings
                  </ListItem>
                  <ListItem>Low noise at 55.8 dBA</ListItem>
                  <ListItem>Glass side walls</ListItem>
                  <ListItem>Fixed Sash</ListItem>
                  <ListItem>Internal height: 3 ft</ListItem>
                  <ListItem>Available sizes: 3 ft, 4 ft, 6 ft</ListItem>
                </UnorderedList>
              </CardBody>

              <CardFooter>
                <Link to="/Contact">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </Link>
              </CardFooter>
            </Stack>
          </Card>
        </CardBody>
      </Card>
      <GoToTop />
    </Container>
  );
}

export default LaminarFlowCabinet;
