import {
  Container,
  ListItem,
  CardFooter,
  Button,
  UnorderedList,
  Card,
  Image,
  Stack,
  Heading,
  CardBody,
  Text,
  Box,
  CardHeader,
  Divider,
  TableContainer,
  TableCaption,
  Table,
  Th,
  Td,
  Tr,
  Thead,
  Tbody,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import React from "react";

import GoToTop from "../../GoToTop/GoToTop";

function RefrigeratorsAndFreezers() {
  return (
    <Container maxW={"container.lg"}>
      <Card mb={20}>
        <CardHeader>
          <Heading size="lg" bg={"blue.500"} color={"white"} p={5}>
            Lab Refrigerators and Freezers
          </Heading>
        </CardHeader>
        <CardBody>
          <Text>
            Laboratory refrigerators and freezers provide uniform and stable
            temperature and signal alarms to alert the user of critical
            conditions. This is to ensure that the thousands to millions of
            dollars worth of valuable products won’t go to waste.
          </Text>

          <Text mb={20}>
            Esco HP Series laboratory refrigerators and freezers are designed
            for laboratory use to efficiently store temperature-sensitive
            biological samples, reagents, vaccines, culture media and
            pharmaceutical products that demand critical sample storage
            conditions.
          </Text>

          <Box mb={20}>
            <Heading fontSize={"md"} mb={5}>
              Must-Have Features To Consider:
            </Heading>
            <UnorderedList>
              <ListItem>
                Efficient refrigeration design for optimum cooling performance
              </ListItem>
              <ListItem>Low power consumption for big energy-savings</ListItem>
              <ListItem>
                Forced-air circulation system for better temperature uniformity
                and fast recovery after door open events
              </ListItem>
              <ListItem>
                Intelligent automatic defrost feature that defrosts only when
                needed
              </ListItem>
              <ListItem>Intuitive and easy-to-use control systems</ListItem>
              <ListItem>Insulated door with magnetic gasket seals</ListItem>
              <ListItem>
                Energy-saving LED light that has a longer lifespan than
                fluorescent lights
              </ListItem>
            </UnorderedList>
          </Box>

          <Divider mb={20} />

          {/* Standard Controller */}
          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/upload/standard-controller.png"
              alt="horizontal laminar-flow"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  Standard Controller
                </Heading>

                <Text mb={10}>
                  Standard Controller is a microprocessor controller with a
                  back-lit LCD and alphanumeric display for temperature, date,
                  alarms, and etc. It is easy to use for configuration of
                  temperature set points, alarms, and various other settings. It
                  gives audible and visual alarms for high/low temperature, door
                  open, and power failure.
                </Text>
              </CardBody>

              <CardFooter>
                <Link to="/Contact">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </Link>
              </CardFooter>
            </Stack>
          </Card>

          {/* Touch Screen */}
          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/upload/touchscreen-controller.png"
              alt="horizontal laminar-flow"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  Touchscreen Controller
                </Heading>

                <Text mb={10}>
                  Touchscreen Controller visualizes the functioning status of
                  the unit and chamber the temperature through a live graph. It
                  gives audible and visual alarms for high/low temperature, door
                  open, and power failure. The SD Card and USB slot enable the
                  download of all functional data of the system, important
                  parameters, and technical documents in digital format.
                </Text>
              </CardBody>

              <CardFooter>
                <Link to="/Contact">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </Link>
              </CardFooter>
            </Stack>
          </Card>

          {/* Table */}

          <TableContainer mb={20}>
            <Table variant="striped">
              <TableCaption bg={"blue.500"} color={"white"}>
                Controller Specifications Table
              </TableCaption>
              <Thead>
                <Tr>
                  <Th></Th>
                  <Th>Standard Controller </Th>
                  <Th>Touchscreen Controller</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Keypad buttons, backlit LCD display </Td>
                  <Td>Applicable</Td>
                  <Td>Not Applicable</Td>
                </Tr>
                <Tr>
                  <Td>Touchscreen interface </Td>
                  <Td>Not Applicable</Td>
                  <Td>Applicable</Td>
                </Tr>
                <Tr>
                  <Td>Microprocessor </Td>
                  <Td>Applicable</Td>
                  <Td>Applicable</Td>
                </Tr>
                <Tr>
                  <Td>Visual and audible alarms </Td>
                  <Td>Applicable</Td>
                  <Td>Applicable</Td>
                </Tr>
                <Tr>
                  <Td>Remote alarm output </Td>
                  <Td>Applicable</Td>
                  <Td>Applicable</Td>
                </Tr>
                <Tr>
                  <Td>Digital temperature display </Td>
                  <Td>Applicable</Td>
                  <Td>Applicable</Td>
                </Tr>
                <Tr>
                  <Td>Temperature live graph </Td>
                  <Td>Not Applicable</Td>
                  <Td>Applicable</Td>
                </Tr>
                <Tr>
                  <Td>GSM, ethernet connection </Td>
                  <Td>Not Applicable</Td>
                  <Td>Applicable</Td>
                </Tr>
                <Tr>
                  <Td>USB and SD card slot for data download </Td>
                  <Td>Not Applicable</Td>
                  <Td>Applicable</Td>
                </Tr>
                <Tr>
                  <Td>Key lock </Td>
                  <Td>Applicable</Td>
                  <Td>Not Applicable</Td>
                </Tr>
                <Tr>
                  <Td>Electronic door lock </Td>
                  <Td>Not Applicable</Td>
                  <Td>Applicable</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>

          {/* Products */}
          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/hp-series-lab-combi-standard-controller.png"
              alt="horizontal laminar-flow"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  HP Series Lab Combination And Freezer – Standard Controller
                </Heading>

                <UnorderedList>
                  <ListItem fontWeight={"extrabold"}>HC6-_S-_</ListItem>
                  <ListItem>Forced-air design</ListItem>
                  <ListItem>Intelligent automatic defrost</ListItem>
                  <ListItem>Internal LED Lighting</ListItem>
                  <ListItem>CFC- and HCFC – FREE</ListItem>
                  <ListItem>Touchpad key controller with LCD</ListItem>
                  <ListItem>
                    Available sizes: Refrigerator: 170 L (6.0 cu. ft) Freezer:
                    160 L (5.7 cu. ft., Refrigerator/ 260 L (9.19 cu. ft)
                    Freezer: 265 L (9.77 cu.ft)
                  </ListItem>
                </UnorderedList>
              </CardBody>

              <CardFooter>
                <Link to="/Contact">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </Link>
              </CardFooter>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/hp-series-lab-combi-touchscreen-controller.png"
              alt="horizontal laminar-flow"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  HP Series Lab Combination And Freezer – Touchscreen Controller
                </Heading>

                <UnorderedList>
                  <ListItem fontWeight={"extrabold"}>HC6-_T-_</ListItem>
                  <ListItem>Forced-air design</ListItem>
                  <ListItem>Intelligent automatic defrost</ListItem>
                  <ListItem>Internal LED Lighting</ListItem>
                  <ListItem>CFC- and HCFC – FREE</ListItem>
                  <ListItem>Touchscreen Controller</ListItem>

                  <ListItem>
                    Available sizes: Refrigerator: 170 L (6.0 cu. ft) Freezer:
                    160 L (5.7 cu. ft., Refrigerator/ 260 L (9.19 cu. ft)
                    Freezer: 265 L (9.77 cu.ft)
                  </ListItem>
                </UnorderedList>
                <Text fontWeight={"bold"} mt={6} color={"red.500"}>
                  <a href="https://www.escolifesciences.com/products/laminar-flow-cabinet/airstream-gen-3-vertical-laminar-flow-cabinet">
                    Know more
                  </a>
                </Text>
              </CardBody>

              <CardFooter>
                <Link to="/Contact">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </Link>
              </CardFooter>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/hp-series-lab-freezer-standard-controller.png"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  HP Series Laboratory Freezer – Standard Controller
                </Heading>

                <UnorderedList>
                  <ListItem fontWeight={"extrabold"}>HF2-_S-_</ListItem>
                  <ListItem>Forced-air design</ListItem>
                  <ListItem>Intelligent automatic defrost</ListItem>
                  <ListItem>Internal LED Lighting</ListItem>
                  <ListItem>CFC- and HCFC – FREE</ListItem>
                  <ListItem>Touchpad key controller with LCD</ListItem>
                  <ListItem>
                    Available sizes: 128 L, 347 L, 620 L, and 1355 L
                  </ListItem>
                </UnorderedList>
              </CardBody>

              <CardFooter>
                <Link to="/Contact">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </Link>
              </CardFooter>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/hp-series-lab-freezer-touchscreen-controller.png"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  HP Series Laboratory Freezer – Touchscreen Controller
                </Heading>

                <UnorderedList>
                  <ListItem fontWeight={"extrabold"}>HF2-_T-_</ListItem>
                  <ListItem>Forced-air design</ListItem>
                  <ListItem>Intelligent automatic defrost</ListItem>
                  <ListItem>Internal LED lighting</ListItem>
                  <ListItem>CFC- and HCFC – FREE</ListItem>
                  <ListItem>Touchscreen controller</ListItem>
                  <ListItem>Available sizes: 347 L, 620 L, and 1355 L</ListItem>
                </UnorderedList>
              </CardBody>

              <CardFooter>
                <Link to="/Contact">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </Link>
              </CardFooter>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/hp-series-lab-freezer-standard-controller.png"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  HP Series Laboratory Freezer – Standard Controller
                </Heading>

                <UnorderedList>
                  <ListItem fontWeight={"extrabold"}>HF3-_S-_</ListItem>
                  <ListItem>Forced-air design</ListItem>
                  <ListItem>Intelligent automatic defrost</ListItem>
                  <ListItem>CFC- and HCFC – FREE</ListItem>
                  <ListItem>Touchpad key controller with LCD</ListItem>
                  <ListItem>Available sizes: 360 L and 544 L</ListItem>
                </UnorderedList>
              </CardBody>

              <CardFooter>
                <Link to="/Contact">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </Link>
              </CardFooter>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/hp-series-lab-freezer-touchscreen-controller.png"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  HP Series Laboratory Freezer – Touchscreen Controller
                </Heading>

                <UnorderedList>
                  <ListItem fontWeight={"extrabold"}>HF3-_T-_</ListItem>
                  <ListItem>Forced-air design</ListItem>
                  <ListItem>Intelligent automatic defrost</ListItem>
                  <ListItem>CFC- and HCFC – FREE</ListItem>
                  <ListItem>Touchscreen controller</ListItem>
                  <ListItem>Available sizes: 360 L and 544 L</ListItem>
                </UnorderedList>
              </CardBody>

              <CardFooter>
                <Link to="/Contact">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </Link>
              </CardFooter>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/hp-series-lab-refrigerator-standard-controller.png"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  HP Series Lab Refrigerator – Standard Controller
                </Heading>

                <UnorderedList>
                  <ListItem fontWeight={"extrabold"}>HR1-_S-_</ListItem>
                  <ListItem>Forced-air Design</ListItem>
                  <ListItem>Triple-pane Glass Door</ListItem>
                  <ListItem>Key Lock</ListItem>
                  <ListItem>Intelligent Automatic Defrost</ListItem>
                  <ListItem>Internal LED Lighting</ListItem>
                  <ListItem>Touchpad key controller with LCD</ListItem>
                  <ListItem>
                    Available sizes: 128L, 347L, 620L, and 1355L
                  </ListItem>
                </UnorderedList>
              </CardBody>

              <CardFooter>
                <Link to="/Contact">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </Link>
              </CardFooter>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="contain"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.escolifesciences.com/images/product-img/full/hp-series-lab-refrigerator-touchscreen-controller.png"
            />

            <Stack>
              <CardBody>
                <Heading size="md" mb={5} color={"blue.500"}>
                  HP Series Lab Refrigerator – Touchscreen Controller
                </Heading>

                <UnorderedList>
                  <ListItem fontWeight={"extrabold"}>HR1-_T-_</ListItem>
                  <ListItem>Forced-air Design</ListItem>
                  <ListItem>Triple-pane Glass Door</ListItem>
                  <ListItem>Electronic Door Lock</ListItem>
                  <ListItem>Intelligent Automatic Defrost</ListItem>
                  <ListItem>Internal LED Lighting</ListItem>
                  <ListItem>Touchscreen Controller</ListItem>
                  <ListItem>Electronic Door Lock</ListItem>
                  <ListItem>Available sizes: 347L, 620L, and 1355L</ListItem>
                </UnorderedList>
              </CardBody>

              <CardFooter>
                <Link to="/Contact">
                  <Button variant="solid" colorScheme="blue">
                    Get Details
                  </Button>
                </Link>
              </CardFooter>
            </Stack>
          </Card>
        </CardBody>
      </Card>
      <GoToTop />
    </Container>
  );
}

export default RefrigeratorsAndFreezers;
