import React from "react";
import {
  Container,
  Heading,
  Card,
  CardHeader,
  CardBody,
  Text,
  CardFooter,
  Button,
} from "@chakra-ui/react";

import { Link } from "react-router-dom";

import GoToTop from "../../GoToTop/GoToTop";

function LaboratoryShaker() {
  return (
    <Container maxW={"container.lg"}>
      <Card>
        <CardHeader>
          <Heading size="lg" bg={"blue.500"} color={"white"} p={5}>
            {" "}
            Laboratory Shaker
          </Heading>
        </CardHeader>
        <CardBody>
          <Text>
            Exceptional choice for versatile and reliable shaking application
          </Text>
          <Text>
            The OrbiCult™ Shaker have cutting edge technologies and robust
            mechanism suited for a wide range of options to meet an accurate and
            precise output. Provided with flexible accessories, intuitive
            interface and environmental friendly design – making sure that Esco
            laboratory shakers deliver outstanding feature and excellent
            performance.
          </Text>
        </CardBody>
        <CardFooter>
          <Link to={"/Contact"}>
            <Button colorScheme="blue">Contact us</Button>
          </Link>
        </CardFooter>
      </Card>
      <GoToTop />
    </Container>
  );
}

export default LaboratoryShaker;
