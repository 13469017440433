import {
  Container,
  Card,
  CardHeader,
  Heading,
  CardBody,
  Text,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import React from "react";

import GoToTop from "../../GoToTop/GoToTop";

function Protection() {
  return (
    <Container maxW={"container.lg"}>
      <Card mb={20}>
        <CardHeader>
          <Heading size="lg" bg={"blue.500"} color={"white"} p={5}>
            Remote Monitering Datalogging Programming
          </Heading>
        </CardHeader>
        <CardBody>
          <Text fontWeight={"extrabold"} mb={10} color={"blue.500"}>
            Remote Monitoring, Datalogging, Programming Software
          </Text>
          <Text mb={20}>
            Esco Voyager® is a PC-based software package developed for the
            remote monitoring, datalogging, and programming / device
            configuration of Esco controlled environment laboratory equipment.
            Compatible equipment includes laboratory ovens and microbiology
            incubators. Voyager® interfaces with individual Esco equipment over
            RS485 using the EscoBUS communications protocol. Up to 16 devices of
            equipment may be interfaced to a single PC.
          </Text>

          <Heading fontSize={"lg"} mb={5}>
            Key Features:
          </Heading>
          <UnorderedList>
            <ListItem>Remote Monitoring</ListItem>
            <ListItem>Remote Programming and Equipment Configuration</ListItem>
            <ListItem>Datalogging and Graph Functions</ListItem>
            <ListItem>Alarm</ListItem>
          </UnorderedList>
        </CardBody>
      </Card>

      <Card mb={20}>
        <CardHeader>
          <Heading size="lg" bg={"blue.500"} color={"white"} p={5}>
            Intelligent Remote Monitering Application
          </Heading>
        </CardHeader>
        <CardBody>
          <Text fontWeight={"extrabold"} mb={10} color={"blue.500"}>
            iRMAP is a fee-based service for any Esco Product that is Voyager
            compatible.
          </Text>
          <Text mb={20}>
            In the event of issues that could threaten customer samples, an
            iRMAP monitoring team member will contact the customer or an
            assigned customer contact until the situation is resolved. The iRMAP
            team member will continue to reach their contact(s) until the
            samples are safe. iRMAP in conjunction with the Predictive Failure
            Monitoring System can also monitor the health of the freezer and
            proactively dispatch service to correct pending and future freezer
            issues that could threaten customer samples
          </Text>
        </CardBody>
      </Card>

      <Card mb={20}>
        <CardHeader>
          <Heading size="lg" bg={"blue.500"} color={"white"} p={5}>
            Wireless Monitoring System
          </Heading>
        </CardHeader>
        <CardBody>
          <Text mb={20}>
            Esco PROtect is a truly wireless redundant monitoring system.
            Operating 24/7, it will send real time local and remote alarms
            notifications. Esco will help you to PROtect your samples, save you
            on costs and comply with regulatory requirements in your lab!
          </Text>

          <Heading fontSize={"lg"} mb={5}>
            Complies to following standards:
          </Heading>
          <UnorderedList>
            <ListItem>ISO 17025</ListItem>
            <ListItem>ISO 15189</ListItem>
            <ListItem>21 CFR part 11</ListItem>
          </UnorderedList>
        </CardBody>
      </Card>

      <Card mb={20}>
        <CardHeader>
          <Heading size="lg">Features</Heading>
        </CardHeader>
        <CardBody>
          <UnorderedList>
            <ListItem>Real Time Monitoring System</ListItem>
            <UnorderedList>
              <ListItem>
                Wireless Technology that protects your samples 24/7 with
                continuous data logging function and immediate alarm
                notifications
              </ListItem>
              <ListItem>
                Centralize monitoring for all your equipment (any brands and any
                models):
              </ListItem>
              <UnorderedList>
                <ListItem>
                  -Temperature for refrigerators, freezers, cold chambers,
                  cryogenic tanks and -80 freezers
                </ListItem>
                <ListItem>-Temperature and CO2 for ovens/incubators</ListItem>
                <ListItem>
                  -Temperature and humidity for climatic chamber, ambient area
                </ListItem>
              </UnorderedList>
              <ListItem>
                Measurement and recording of physical parameters such as
                temperature, CO2, and humidity
              </ListItem>
              <ListItem>Direct reading on the display</ListItem>
              <ListItem>
                Compared to Wi-Fi, radio frequency has longer battery life and
                longer transmission distance.
              </ListItem>
              <ListItem>
                Using radio frequency, repeaters can be installed to extend the
                distance which cannot be easily done by some Wi-Fi system.
              </ListItem>
            </UnorderedList>

            <ListItem>Alarm Capabilities –Securing Your Samples</ListItem>
            <UnorderedList>
              <ListItem>Avoid loss of products.</ListItem>
              <ListItem>Real time alarm management</ListItem>
              <ListItem>Visual and audible alert</ListItem>
              <ListItem>Remote alarm notification via E-mail.</ListItem>
            </UnorderedList>

            <ListItem>Simple and Cost Effectiveness</ListItem>
            <UnorderedList>
              <ListItem>
                Fast Installation, commissioning and maintenance.
              </ListItem>
              <ListItem>
                Can centrally monitor integrity of samples even during a power
                outage or electronic failure of refrigerator, freezer,
                incubator, oven, etc.
              </ListItem>
              <ListItem>
                Accurate and efficient compared to manual data readings.
              </ListItem>
              <ListItem>
                Reduce labor cost for manual data readings. Manual data readings
                are less accurate, less reliable and time consuming. Saves you
                time and money.
              </ListItem>
              <ListItem>
                Readily accessible through the display or monitoriing software
                through PC.
              </ListItem>
              <ListItem>
                Multi-site installation. Multiple buildings can be monitored
                accros town, another country.
              </ListItem>
              <ListItem>
                Interchangeable digital probes with embedded memory to
                facilitate maintenance procedures.
              </ListItem>
              <ListItem>Modern and eco-friendly.</ListItem>
            </UnorderedList>

            <ListItem>Traceability and Security – Quality Assurance</ListItem>
            <UnorderedList>
              <ListItem>
                Comply with quality assurance procedures: ISO 17025 and ISO
                15189 norms, Good laboratory practices and local regulations
                recommend the use of tools to guarantee the reliability of your
                temperature controlled equipment (ultra-low temperature
                freezers, lab freezers, lab refrigerators, lab ovens, lab
                incubators, refrigerated incubators, CO2 incubators, etc).
              </ListItem>
              <ListItem>
                Audit trail traceability for conformance to 21 CFR part 11
              </ListItem>
              <ListItem>
                Local regulations or recommendations within Quality Management
                Programs
              </ListItem>
            </UnorderedList>
            <ListItem>
              Software System and PC Interface •Remote visualization of data
            </ListItem>
            <UnorderedList>
              <ListItem>
                Downloaded database is presented in easy to understand reports
                or graphs.
              </ListItem>
              <ListItem>
                Protect software enables to visualize recorders located on the
                installation map and to manage your alarm.
              </ListItem>
            </UnorderedList>
          </UnorderedList>
        </CardBody>
      </Card>
      <GoToTop />
    </Container>
  );
}

export default Protection;
