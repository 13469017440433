import {
  Container,
  Card,
  CardBody,
  Text,
  Heading,
  CardHeader,
  Button,
  CardFooter,
  Image,
  Stack,
  Box,
  Divider,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
import React from "react";

import { Link } from "react-router-dom";

function MiniMiriHumidity() {
  return (
    <Container maxW={"container.lg"}>
      <Card>
        <CardHeader>
          <Heading size="lg" bg={"blue.500"} color={"white"} p={5}>
            Mini MIRI® Humidity Incubator
          </Heading>
        </CardHeader>
        <CardBody>
          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={20}
          >
            <Image
              objectFit="cover"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://nvakins.in/wp-content/uploads/2022/02/miri-multiroom-incubator-product-image-min-hd-600x582.png"
              alt="MIRI® Multiroom Incubator"
            />

            <Stack>
              <CardBody>
                <Text py="2">
                  The Mini MIRI® Humidity Incubator is a humidified mini
                  benchtop incubator built on the robust and reliable MIRI®
                  design. The compact design and direct heat regulation help
                  prevent vapor condensation and further translate to faster
                  temperature and gas recovery.
                </Text>
              </CardBody>
            </Stack>
          </Card>

          <Box mb={20}>
            <Heading size={"md"} mb={20} color={"blue.500"}>
              Parts of the MIRI® Multiroom Incubator
            </Heading>
            <Image
              src="https://www.esco-medical.com/images/upload/Mini-miri-humidity-parts-min.png"
              mb={20}
            ></Image>
          </Box>

          <Divider mb={20}></Divider>

          <Box mb={20}>
            <Heading size={"md"} mb={10} color={"blue.500"}>
              Recirculating Gas For Minimal Gas Consumption
            </Heading>
            <Text>
              The Mini MIRI® Humidity Incubator is a humidified mini bench top
              incubator that uses either premixed gases or pure gas (N2 and CO2)
              inserts that pass through a tri-gas mixing chamber to ensure
              precise gas concentrations before entering the incubator chambers.
              Afterward, this gas recirculates and goes again through the gas
              mixing chamber which helps in minimizing gas consumption.
            </Text>
            <br />
            <Image
              src="https://www.esco-medical.com/images/upload/recirculating-gas-for-minimal-gas-consumption-min.jpg"
              mb={20}
            ></Image>
          </Box>

          <Divider mb={20}></Divider>

          <Box mb={20}>
            <Heading size={"md"} mb={10} color={"blue.500"}>
              Data Logger Software
            </Heading>
            <Text>
              The Mini MIRI® Humidity Incubator comes with a data logger
              software that monitors the temperature, gas concentration, gas
              input pressure, gas flow rates, and alarms. All real-time
              parameters can be viewed conveniently in graphs when the Mini
              MIRI® is connected to a PC and the data logger software can also
              monitor multiple incubators at the same time. Weekly reports can
              be exported for the users’ convenience.
            </Text>
            <br />
            <Image
              src="https://www.esco-medical.com/images/upload/data-logger-software-min.jpg"
              mb={10}
            ></Image>
            <Image
              src="https://www.esco-medical.com/images/upload/data-logger-software-alarm-min.jpg"
              mb={10}
            ></Image>
            <Image
              src="https://www.esco-medical.com/images/upload/data-logger-software-temperatures-min.jpg"
              mb={20}
            ></Image>
          </Box>

          {/* Table One */}
          <TableContainer mb={20}>
            <Table variant="simple">
              <TableCaption bg={"blue.500"} color={"white"}>
                General Specifications
              </TableCaption>
              <Thead>
                <Tr>
                  <Th>Specifications</Th>
                  <Th>Mini MIRI® Dry</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Overall Dimensions (W x D x H) </Td>
                  <Td>525 x 420 x 220 mm (20.7 x 16.5 x 8.7″)</Td>
                </Tr>

                <Tr>
                  <Td>Weight </Td>
                  <Td>22 kg</Td>
                </Tr>

                <Tr>
                  <Td>Power Supply </Td>
                  <Td>115 VAC or 230 VAC, 50/60 Hz</Td>
                </Tr>

                <Tr>
                  <Td>Power Consumption </Td>
                  <Td>90 W</Td>
                </Tr>

                <Tr>
                  <Td>Temperature Control Range </Td>
                  <Td>25 - 40 °C</Td>
                </Tr>

                <Tr>
                  <Td>N2 Gas Consumption </Td>
                  <Td> 12 L/hr</Td>
                </Tr>

                <Tr>
                  <Td>CO2 Gas Consumption </Td>
                  <Td>2 L/hr</Td>
                </Tr>

                <Tr>
                  <Td>Gas Pressure </Td>
                  <Td>0.4 to 0.6 bar</Td>
                </Tr>

                <Tr>
                  <Td>Shipping Weight </Td>
                  <Td>32 kg</Td>
                </Tr>

                <Tr>
                  <Td>Shipping Dimensions </Td>
                  <Td>700 x 600 x 470 mm (27.6 x 23.6 x 18.5 “)</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>

          {/* Table Two */}
          <TableContainer mb={20}>
            <Table variant="simple">
              <TableCaption bg={"blue.500"} color={"white"}>
                Ordering Information
              </TableCaption>
              <Thead>
                <Tr>
                  <Th>Item Code </Th>
                  <Th>Model Code </Th>
                  <Th>Description</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td></Td>
                  <Td></Td>
                  <Td>Unit</Td>
                </Tr>
                <Tr>
                  <Td>2070155 </Td>
                  <Td> MRI-MINI-H-8</Td>
                  <Td>Mini MIRI® Humidified, 230V, 50/60Hz</Td>
                </Tr>
                <Tr>
                  <Td>2070156</Td>
                  <Td>MRI-MINI-H-9 </Td>
                  <Td>Mini MIRI® Humidified, 115V, 50/60Hz</Td>
                </Tr>
                <Tr>
                  <Td>2070157 </Td>
                  <Td>MRI-MINI-H-SS-8 </Td>
                  <Td>
                    Mini MIRI® Humidified with SAFE Sens for pH monitoring,
                    230V, 50/60 Hz
                  </Td>
                </Tr>
                <Tr>
                  <Td>2050158</Td>
                  <Td>MRI-MINI-H-SS-9 </Td>
                  <Td>
                    Mini MIRI® Humidified with SAFE Sens for pH monitoring,
                    115V, 50/60 Hz
                  </Td>
                </Tr>
                <Tr>
                  <Td></Td>
                  <Td></Td>
                  <Td>Accessories</Td>
                </Tr>
                <Tr>
                  <Td>1081277</Td>
                  <Td>TBA</Td>
                  <Td>
                    SAFE Sens SV2 Sensor, Pack of 10 pieces (shelf-life 12
                    months)
                  </Td>
                </Tr>
                <Tr>
                  <Td>1081278</Td>
                  <Td>TBA</Td>
                  <Td>SAFE Sens QC2 Alignment Tool</Td>
                </Tr>
                <Tr>
                  <Td>1320191</Td>
                  <Td>TBA</Td>
                  <Td>
                    SAFE Sens TrakStation, a tablet with SAFE Sens Software, for
                    pH monitoring.
                  </Td>
                </Tr>
                <Tr>
                  <Td>1320003</Td>
                  <Td>TBA</Td>
                  <Td>Insert for Falcon® Dishes</Td>
                </Tr>
                <Tr>
                  <Td>1320004</Td>
                  <Td>TBA</Td>
                  <Td>Insert for Nunc™ Dishes</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </CardBody>
        <CardFooter>
          <Link to={"/Contact"}>
            <Button colorScheme="blue">Contact Us</Button>
          </Link>
        </CardFooter>
      </Card>
    </Container>
  );
}

export default MiniMiriHumidity;
