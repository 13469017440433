import {
  Container,
  Card,
  CardHeader,
  Heading,
  CardBody,
  Text,
  CardFooter,
  Button,
  UnorderedList,
  ListItem,
  Box,
  Stack,
  SimpleGrid,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

import GoToTop from "../../GoToTop/GoToTop";

function LexiconTemperatureFreezer() {
  return (
    <Container maxW={"container.lg"}>
      <Card mb={20}>
        <CardHeader>
          <Heading size="lg" bg={"blue.500"} color={"white"} p={5}>
            {" "}
            Lexicon® II Ultra-Low Temperature Freezer
          </Heading>
        </CardHeader>
        <CardBody>
          <Text>
            Ultra-Low Temperature (ULT) Freezers (-50°C To -86°C) Are Widely
            Used In Scientific Research For Long-Term Storage Of Samples. As ULT
            Freezers Are Often Operated At -80°C Continuously For Years,
            Reliability Is Of Paramount Importance To The Researchers.
          </Text>
          <br />
          <Text mb={20}>
            Constructed from high-quality proven components with
            energy-efficient refrigeration design, Esco Lexicon® II ULT freezers
            provide top notch protection that can withstand the test of time to
            guarantee the integrity of your samples. Superior performance,
            lower-energy costs, better protection
          </Text>

          <Box mb={20}>
            <Heading fontSize={"md"} mb={5}>
              Ultra-Low Freezer is for Storage And Preservation of:
            </Heading>
            <UnorderedList>
              <ListItem>Biological Samples</ListItem>
              <ListItem>DNA, RNA, Protein cell extracts</ListItem>
              <ListItem>Enzymes</ListItem>
              <ListItem>Reagents</ListItem>
              <ListItem>Vaccines</ListItem>
              <ListItem>Viral specimens</ListItem>
              <ListItem>Human cells</ListItem>
              <ListItem>Tissue samples</ListItem>
              <ListItem>Other blood samples</ListItem>
            </UnorderedList>
          </Box>

          <Card mb={20}>
            <Stack
              direction={["column", "row"]}
              justifyContent={"center"}
              spacing={10}
              p={10}
            >
              <a href="https://www.escolifesciences.com/form/request-for-quotation/40">
                <Button colorScheme={"blue"} variant={"solid"}>
                  Request for Quote
                </Button>
              </a>
              <a href="https://www.escolifesciences.com/form/cold-storage-product-form">
                <Button colorScheme={"blue"} variant={"ghost"}>
                  Online Product Form
                </Button>
              </a>
            </Stack>
          </Card>

          <SimpleGrid
            spacing={4}
            templateColumns={[
              "repeat(auto-fill, minmax(200px, 1fr))",
              "repeat(auto-fill, minmax(250px, 1fr))",
            ]}
          >
            <Card>
              <CardHeader>
                <Heading size="md" color={"blue.500"}>
                  {" "}
                  Superior Sample Protection
                </Heading>
              </CardHeader>
              <CardBody>
                <UnorderedList>
                  <ListItem>Fast cool down</ListItem>
                  <ListItem>Fast temperature recovery</ListItem>
                  <ListItem>Excellent uniformity</ListItem>
                  <ListItem>
                    Extended warm-up time during power failure
                  </ListItem>
                </UnorderedList>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <Heading size="md" color={"blue.500"}>
                  {" "}
                  Modern Refrigeration Components
                </Heading>
              </CardHeader>
              <CardBody>
                <UnorderedList>
                  <ListItem>High-performance reliable compressors</ListItem>
                  <ListItem>
                    Maximum efficiency in even the most extreme ambient
                    conditions
                  </ListItem>
                </UnorderedList>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <Heading size="md" color={"blue.500"}>
                  Energy Saving
                </Heading>
              </CardHeader>
              <CardBody>
                <UnorderedList>
                  <ListItem>Low power consumption</ListItem>
                </UnorderedList>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <Heading size="md" color={"blue.500"}>
                  {" "}
                  High-Quality Construction
                </Heading>
              </CardHeader>
              <CardBody>
                <UnorderedList>
                  <ListItem>Stainless steel interior</ListItem>
                  <ListItem>Electrogalvanized steel exterior</ListItem>
                  <ListItem>Rounded corners for optimum cleanability</ListItem>
                  <ListItem>Triple gasket seal</ListItem>
                </UnorderedList>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <Heading size="md" color={"blue.500"}>
                  Isocide™ Antimicrobial Powder Coating
                </Heading>
              </CardHeader>
              <CardBody>
                <UnorderedList>
                  <ListItem>
                    Eliminates 99.9% of surface bacteria within 24 hours of
                    exposure
                  </ListItem>
                </UnorderedList>
              </CardBody>
            </Card>
          </SimpleGrid>
        </CardBody>
        <CardFooter>
          <Link to={"/Contact"}>
            <Button colorScheme="blue">Contact us</Button>
          </Link>
        </CardFooter>
      </Card>
      <GoToTop />
    </Container>
  );
}

export default LexiconTemperatureFreezer;
