import {
  Container,
  Heading,
  Card,
  CardHeader,
  CardBody,
  Text,
  CardFooter,
  Button,
  Box,
  UnorderedList,
  ListItem,
  SimpleGrid,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

import GoToTop from "../../GoToTop/GoToTop";

function AnimalResearchWorkstation() {
  return (
    <Container maxW={"container.lg"}>
      <Card mb={20}>
        <CardHeader>
          <Heading size="lg" bg={"blue.500"} color={"white"} p={5}>
            Animal Research Workstation
          </Heading>
        </CardHeader>
        <CardBody>
          <Text mb={20}>
            Laboratory animal workstation plays an important role in providing
            operator and environmental protection during animal handling for
            clinical researches. This workstation protects the operator from
            viruses, bacteria, fungi, parasites, hazardous substances, toxins,
            allergens, anesthetic gases, and physical injuries.
            <br />
            Esco’s animal research workstations are top-grade, ELISA-verified
            containment. Its low noise level, energy-efficient DC ECM blower,
            and ULPA filter make it the leading animal workstation in the
            market.
          </Text>

          <Box mb={20}>
            <Heading fontSize={"md"} mb={5}>
              Must-Have Features To Consider:
            </Heading>
            <UnorderedList>
              <ListItem>
                Low noise level for quiet operation, comfortable for the user
                and animals
              </ListItem>
              <ListItem>
                Equipped with an energy-efficient motor/blower
              </ListItem>
              <ListItem>
                Cost-effective ULPA filter (ISO class 3 work zone) with greater
                than 99.999% efficiency at 0.1-0.3 μm which is 10x better
                compared to HEPA filter
              </ListItem>
              <ListItem>
                Intuitive and easy-to-use microprocessor-based controller
              </ListItem>
              <ListItem>
                ELISA-verified containment that provides greater than 99%
                protection against allergens
              </ListItem>
              <ListItem>
                Monitors real-time airflow that alerts user if airflow is
                insufficient
              </ListItem>
              <ListItem>Comfortable leg-room</ListItem>
            </UnorderedList>
          </Box>

          <Box mb={20}>
            <Text mb={10}>
              <Text as={"span"} color={"blue.500"} fontWeight={"extrabold"}>
                Bedding disposal animal workstations
              </Text>{" "}
              are specifically designed to protect the personnel and the lab
              environment from exposure to allergens and unpleasant odors. The
              industry-exclusive hydraulic height-adjustable stand allows the
              work surface height to be adjusted to user preference therefore
              minimizing strain during repetitive operations. An integrated
              waste container enables direct disposal of waste items within the
              work zone offering simpler, safer, and more productive cage
              cleaning and bedding disposal procedures.
            </Text>

            <Text mb={10}>
              <Text as={"span"} color={"blue.500"} fontWeight={"extrabold"}>
                Dual access animal workstations
              </Text>{" "}
              aim to provide operator comfort and prevent the lab animals from
              being agitated during cage transfer. This equipment has a legroom
              on both sides to allow two operators to work while doing cage
              transfer comfortably. It also has an internal work zone is divided
              into two by an air curtain that allows transfer of animals from a
              “dirty” to a “clean” area.
            </Text>

            <Text>
              <Text as={"span"} color={"blue.500"} fontWeight={"extrabold"}>
                Universal animal workstations
              </Text>{" "}
              employ two independently balanced ULPA filtration modules to
              protect animals inside the enclosure from exposure to airborne
              particulates/ambient contamination, as well as, the operator from
              exposure to allergens and other potentially hazardous materials.
              It has an ergonomically sloped front design and integrated carbon
              filters to remove emitted odors which provides operator protection
              during operations.
            </Text>
          </Box>

          <SimpleGrid
            spacing={4}
            templateColumns={[
              "repeat(auto-fill, minmax(200px, 1fr))",
              "repeat(auto-fill, minmax(250px, 1fr))",
            ]}
          >
            <Card>
              <CardHeader>
                <Heading size="md" color={"blue.500"}>
                  {" "}
                  VIVA® Dual Access Animal Workstations
                </Heading>
              </CardHeader>
              <CardBody>
                <UnorderedList>
                  <ListItem>Lowest energy</ListItem>
                  <ListItem>Lowest noise at ≤ 53 dBA</ListItem>
                  <ListItem>Legroom in both sides</ListItem>
                  <ListItem>ELISA tested</ListItem>
                  <ListItem>Available size: 4ft and 5ft</ListItem>
                </UnorderedList>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <Heading size="md" color={"blue.500"}>
                  {" "}
                  VIVA® Bedding Disposal Animal Workstation
                </Heading>
              </CardHeader>
              <CardBody>
                <UnorderedList>
                  <ListItem>ELISA-verified containment</ListItem>
                  <ListItem>
                    ULPA Filter and carbon filter to absorb odor
                  </ListItem>
                  <ListItem>Motorized height stand</ListItem>
                  <ListItem>Integrated waste bin</ListItem>
                  <ListItem>Available size: 4ft</ListItem>
                </UnorderedList>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <Heading size="md" color={"blue.500"}>
                  {" "}
                  VIVA® Universal Animal Workstations
                </Heading>
              </CardHeader>
              <CardBody>
                <UnorderedList>
                  <ListItem>ELISA-verified containment</ListItem>
                  <ListItem>
                    ULPA Filter and carbon filter to absorb odor
                  </ListItem>
                  <ListItem>Biosafety cabinet class II containment</ListItem>
                  <ListItem>ISO Class 3 work zone</ListItem>
                  <ListItem>Available size: 4ft and 6ft</ListItem>
                </UnorderedList>
              </CardBody>
            </Card>
          </SimpleGrid>
        </CardBody>
        <CardFooter>
          <Link to={"/Contact"}>
            <Button colorScheme="blue">Contact Us</Button>
          </Link>
        </CardFooter>
      </Card>

      <GoToTop />
    </Container>
  );
}

export default AnimalResearchWorkstation;
