import React from "react";

import {
  Container,
  Card,
  CardBody,
  Text,
  Heading,
  CardHeader,
  Button,
  CardFooter,
  Image,
  Stack,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";

import { Link } from "react-router-dom";
import GoToTop from "../../GoToTop/GoToTop";

function CultureCoin() {
  return (
    <Container maxW={"container.lg"}>
      <Card>
        <CardHeader>
          <Heading size="lg" bg={"blue.500"} color={"white"} p={5}>
            {" "}
            CultureCoin® - Embryo Culture Dish for the MIRI® TL
          </Heading>
        </CardHeader>
        <CardBody>
          <Card align="center" mb={20}>
            <CardBody>
              <Text mb={10}>
                The Esco Medical CultureCoin® is an IVF culture dish designed
                specifically for the MIRI® TL. The MIRI® TL is Esco Medical’s
                time-lapse incubator that captures real-time images of
                developing embryos which can be viewed as time-lapse videos.The
                CultureCoin® is a single-use, gamma-sterilized culture dish that
                has oxygen plasma-treated surfaces for effective prevention of
                bubble formation. Its ergonomic design makes it easy for
                embryologists to load a total of 14 embryos while being able to
                safely and securely handle them inside the incubator. This
                culture well features a 300µm, optically clear area for embryo
                placement which is optimized for microscopy.
              </Text>
              <Link to={"https://www.esco-medical.com/products/culture-coin"}>
                <Button colorScheme="blue">Get Details</Button>
              </Link>
            </CardBody>
            <CardFooter>
              <Image
                src="https://www.esco-medical.com/images/product-image/culture-coin.png"
                alt="CultureCoin"
              ></Image>
            </CardFooter>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Stack>
              <CardBody>
                <Heading size={"md"} color={"blue.500"} mb={5}>
                  How to use the CultureCoin®
                </Heading>
                <UnorderedList>
                  <ListItem>
                    Unpack the IVF culture dish inside a workstation or laminar
                    flow hood to provide an aseptic environment.
                  </ListItem>
                  <ListItem>
                    Leave the dishes inside the workstation with lids on for
                    off-gassing for 12 hours.
                  </ListItem>
                  <ListItem>
                    Pre-warm the dishes on a heated stage or inside a CO2
                    incubator prior to filling with pre-equilibrated media.
                  </ListItem>
                  <ListItem>
                    Start filling all culture wells with approximately 25µL of
                    pre-equilibrated IVF culture media in the workstation. The
                    user also has an option to fill the wash wells with
                    approximately 23µL of culture media.
                  </ListItem>
                  <ListItem>
                    Immediately fill the culture area with a layer of suitable
                    mineral or paraffin oil. Do not overfill.
                  </ListItem>
                  <ListItem>
                    Check for any visible air bubbles under the microscope and
                    remove them with a stripper tip.
                  </ListItem>
                  <ListItem>
                    Put the lid on the culture dish and equilibrate for 4 hours
                    inside a CO2 incubator.
                  </ListItem>
                  <ListItem>
                    Check the culture area again for air bubbles after
                    equilibration. Remove any bubbles with a stripper tip.
                  </ListItem>
                  <ListItem>
                    Load embryos in the culture wells. Make sure to place the
                    embryos very precisely at the center of the culture well and
                    not on the side of the slope.
                  </ListItem>
                  <ListItem>
                    Incubate the CultureCoin® containing the embryos in the
                    MIRI® TL.
                  </ListItem>
                </UnorderedList>
              </CardBody>
            </Stack>
          </Card>
        </CardBody>
        <CardFooter>
          <Link to={"/Contact"}>
            <Button colorScheme="blue">Contact Us</Button>
          </Link>
        </CardFooter>
      </Card>
      <GoToTop />
    </Container>
  );
}

export default CultureCoin;
