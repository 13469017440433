import React from "react";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Image,
  Heading,
  Text,
  VStack,
  Box,
} from "@chakra-ui/react";

import logo from "../../Assets/logo-blue.png";

import { BiMenuAltLeft } from "react-icons/bi";
import { GiSelfLove } from "react-icons/gi";
import { Link } from "react-router-dom";

import { BsWhatsapp } from "react-icons/bs";

function Header() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  return (
    <>
      <Box>
        <Link to="/">
          <Image
            src={logo}
            alt="NVAK LOGO"
            htmlWidth={200}
            marginTop={"-50px"}
          />
        </Link>

        <Button
          ref={btnRef}
          colorScheme="blue"
          onClick={onOpen}
          top={7}
          right={4}
          position={"fixed"}
          zIndex={"overlay"}
          backgroundColor={"#125076"}
        >
          Menu &nbsp;
          <BiMenuAltLeft />
        </Button>
        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>
              <Link to="/" onClick={onClose}>
                <Heading as="h3" size="lg">
                  NVAK
                </Heading>
              </Link>
              <Text fontSize="xs">BIOTECH INSTRUMENTS</Text>
            </DrawerHeader>

            <DrawerBody>
              <VStack alignItems={"flex-start"} spacing={5}>
                <Link to="/">
                  <Button
                    onClick={onClose}
                    variant={"outline"}
                    colorScheme={"blue"}
                  >
                    Home
                  </Button>
                </Link>

                <Link to="/Products">
                  <Button
                    onClick={onClose}
                    variant={"ghost"}
                    colorScheme={"blue"}
                  >
                    Products
                  </Button>
                </Link>

                <Link to="/Services">
                  <Button
                    onClick={onClose}
                    variant={"ghost"}
                    colorScheme={"blue"}
                  >
                    Services
                  </Button>
                </Link>

                <Link to="/Contact">
                  <Button
                    onClick={onClose}
                    variant={"ghost"}
                    colorScheme={"blue"}
                  >
                    Contact
                  </Button>
                </Link>
              </VStack>
            </DrawerBody>

            <DrawerFooter fontSize={"xs"}>
              Made with &nbsp; <GiSelfLove /> &nbsp; by &nbsp;
              <a href="https://degtora.com/" style={{ color: "#ff904d" }}>
                Degtora Technologies
              </a>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>

        <Button
          colorScheme={"whatsapp"}
          bottom={4}
          right={4}
          position={"fixed"}
          zIndex={"overlay"}
          borderRadius={"full"}
        >
          <a
            href="https://wa.me/+918179114666"
            target="_blank"
            rel="noopener noreferrer"
          >
            <BsWhatsapp size={30} />
          </a>
        </Button>
      </Box>
    </>
  );
}

export default Header;
