import {
  Container,
  Card,
  CardBody,
  Text,
  Heading,
  CardHeader,
  Button,
  CardFooter,
  Image,
  Stack,
  SimpleGrid,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

function MiriTimeLapse() {
  return (
    <Container maxW={"container.lg"}>
      <Card>
        <CardHeader>
          <Heading size="lg" bg={"blue.500"} color={"white"} p={5}>
            {" "}
            MIRI® Time-Lapse Incubator
          </Heading>
        </CardHeader>
        <CardBody>
          <Card align="center" mb={20}>
            <CardHeader>
              <Heading size="md">
                {" "}
                MIRI® TL Viewer Software And MIRI® TL Server
              </Heading>
            </CardHeader>
            <CardBody>
              <Text>
                Exponentially increase image and data storage capacity by adding
                the MIRI® TL Data Server. Combine MIRIM® TL Viewer software
                platform to review, annotate and compare morphokinetic
                parameters of embryos, as well as export data for retrospective
                analysis.
              </Text>
            </CardBody>
            <CardFooter>
              <Image src="https://www.esco-medical.com/images/upload/Miri-TL6-and-Viewer-053119.png"></Image>
            </CardFooter>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="cover"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://nvakins.in/wp-content/uploads/2022/02/miri-time-lapse-incubator-front-min-hd.png"
              alt="MIRI® Time-Lapse Incubator"
            />

            <Stack>
              <CardBody>
                <Text py="2">
                  The MIRI® Time-Lapse Incubator is a multiroom incubator with a
                  built-in camera and microscope. Designed and manufactured in
                  EU, the MIRI® Time-Lapse Incubator provides high quality
                  time-lapse images of embryos developing in “real-time” without
                  having to remove the embryos from the safety of the incubation
                  chamber for manual microscopy. Time-lapse embryo monitoring
                  provides detailed morphokinetic data throughout embryo
                  development, which is not available on routine spot
                  microscopic evaluation. This allows all important events to be
                  observed, helping to identify healthy embryos with the highest
                  probability of implantation, with the aim of achieving higher
                  pregnancy rates.
                </Text>
              </CardBody>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="cover"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.esco-medical.com/images/upload/complete-independent-chamber-500px-min.png"
              alt="Completely Independent Chambers
              "
            />

            <Stack>
              <CardBody>
                <Heading size="md">Completely Independent Chambers</Heading>
                <Text py="2">
                  6 (TL6) / 12 (TL12) independent chambers with precise
                  temperature and gas regulation: Opening any chamber does not
                  affect the environment in other chambers.
                </Text>
              </CardBody>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="cover"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.esco-medical.com/images/upload/time-lapse-monitoring-500px-min.png"
              alt="Time-Lapse Monitoring"
            />

            <Stack>
              <CardBody>
                <Heading size="md">Time-Lapse Monitoring</Heading>
                <Text py="2">
                  Time-lapse video generated from images allows objective and
                  reliable scoring and annotation of embryos for better
                  prediction of future developmental and implantation potential.
                </Text>
              </CardBody>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="cover"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.esco-medical.com/images/upload/heated-lid-500px-min.png"
              alt="Heated Lid"
            />

            <Stack>
              <CardBody>
                <Heading size="md">Heated Lid</Heading>
                <Text py="2">
                  Excellent uniformity between the top and bottom lid.Heated
                  chamber lids prevent condensation, enhance temperature
                  regulation/recovery, and ensure temperature uniformity between
                  the top and bottom of the chamber.
                </Text>
              </CardBody>
            </Stack>
          </Card>

          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={10}
          >
            <Image
              objectFit="cover"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://www.esco-medical.com/images/upload/user-friendly-interface-500px-min.png"
              alt="Superior Gas System"
            />

            <Stack>
              <CardBody>
                <Heading size="md">Superior Gas System</Heading>
                <Text py="2">
                  Built-in gas mixer for tri-gas, an advanced gas regulation
                  system, and HEPA-VOC filtered recirculation allows precise gas
                  level control and a lower gas consumption. UV-sterilization of
                  recirculating gas ensures biological safety of embryos.
                </Text>
              </CardBody>
            </Stack>
          </Card>

          <SimpleGrid
            spacing={4}
            templateColumns={[
              "repeat(auto-fill, minmax(200px, 1fr))",
              "repeat(auto-fill, minmax(250px, 1fr))",
            ]}
            mb={20}
          >
            <Card>
              <CardHeader>
                <Heading size="md">Fast Temperature And Gas Recovery</Heading>
              </CardHeader>
              <CardBody>
                <UnorderedList>
                  <ListItem>Temperature recovery less than 1 min</ListItem>
                  <ListItem>Gas recovery less than 3 mins.</ListItem>
                </UnorderedList>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <Heading size="md">
                  {" "}
                  Easy External Gas And Temperature Validation
                </Heading>
              </CardHeader>
              <CardBody>
                <Text>
                  Gas sampling ports for each chamber and independent PT-1000
                  sensors allow external quality evaluation of gas levels and
                  temperature in each chamber.
                </Text>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <Heading size="md"> User Friendly Interface</Heading>
              </CardHeader>
              <CardBody>
                <Text>
                  Touchscreen control panel with intuitive menu to manage
                  configuration of the device
                </Text>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <Heading size="md">The CultureCoin®</Heading>
              </CardHeader>
              <CardBody>
                <Text>
                  Specially designed culture dish, the CultureCoin, allows each
                  chamber to culture 14 embryos, giving a total capacity of 84
                  embryos for the TL6 and 168 embryos for the TL12.
                </Text>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <Heading size="md"> Continues PH Monitoring</Heading>
              </CardHeader>
              <CardBody>
                <Text>
                  Optional SAFE Sens integration for continuous pH monitoring
                  without opening the chamber.
                </Text>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <Heading size="md"> MIRI® TL Datalogger</Heading>
              </CardHeader>
              <CardBody>
                <Text>
                  Continually monitors and documents gas flow, gas concentration
                  and pressure, temperature regulation.
                </Text>
              </CardBody>
            </Card>
          </SimpleGrid>

          <Card align="center">
            <CardHeader>
              <Heading size="md">
                {" "}
                Time-Lapse Technology: Addressing IVF Risks
              </Heading>
            </CardHeader>
            <CardBody>
              <Text>
                Time-lapse technology is a current system for the culture and
                imaging of embryos in vitro in the practice of Clinical
                Embryology. Risks associated with In Vitro fertilization (IVF)
                like multiple pregnancies and the related mother and neonate
                death rate has increased the past few years.
              </Text>
              <br />
              <Text>
                While more stringent policies on embryo transfer have eradicated
                some of these risks, the impact on the occurrence of twins
                remains to be a concern. It is said that a twin gestation can be
                evaded through a single embryo transfer only. Conversely, the
                conventional way of selecting the embryo’s morphology is not
                sufficiently and effectively analytical and predictive to permit
                routine single transfer of embryo hence, novel screening tools
                are necessary.
              </Text>
              <br />
              <Text>
                Through the use of a time-lapse incubator, a continuous,
                non-invasive way of monitoring embryos is possible without the
                removal of the embryo from maintained, optimum culture
                conditions. With this, an IVF practitioner is able to observe
                information concerning cleavage patterns, morphologic
                deviations, and the dynamics of embryo development. The constant
                monitoring could support the identification of embryos with
                higher/ better implantation potential. Such technologic
                advancement allows the unbiased selection of the embryo(s) for
                transfer.
              </Text>
            </CardBody>
          </Card>

          <Card align="center" mb={20}>
            <CardHeader>
              <Heading size="md">
                {" "}
                Closer look at The MIRI® TL Incubator
              </Heading>
            </CardHeader>
            <CardBody>
              <Text>
                Esco Medical’s MIRI® TL Incubator boasts off features that
                provides stable environment, improved embryo selection, improved
                quality management, and a better quality control assay in the
                IVF laboratory.
              </Text>
              <br />
              <UnorderedList>
                <ListItem>
                  Unique Incubation Environment: With 6/ 12 individual chambers
                  wherein one patient can be designated per chamber to avoid
                  cross-contamination. The time-lapse in a chamber continues
                  undisturbed even if the neighboring chambers are opened. Also,
                  one can set individual temperature set-point for each chamber.
                </ListItem>
                <ListItem>
                  Excellent Temperature Uniformity and Regulation: It has one
                  independent temperature sensor for external validation (MIRI®
                  GA), controlling each chamber with 3 individual sensors that
                  gives an extremely accurate temperature.
                </ListItem>
                <ListItem>
                  The MIRI® TL has a built-in tablet that has 128 Gb space
                  capacity to store time-lapse images in the event of a faulty
                  connection between the MIRI® TL and the Viewer Software.
                </ListItem>
                <ListItem>
                  Solid Validation System: Each chamber has built-in PT1000
                  validation sensor that is separate from system circuitry for
                  connection of external temperature measuring device. Six (6)
                  gas sampling ports allow sampling of gases easily via an
                  external gas analyzer. It even has a built-in pH measurement
                  system.
                </ListItem>
                <ListItem>
                  Excellent Recovery Time: The MIRI® TL has excellent recovery
                  rates:
                  <UnorderedList>
                    <ListItem> Gas – less than 3 minutes</ListItem>
                    <ListItem>Temp – less than a minute</ListItem>
                  </UnorderedList>
                </ListItem>
                <ListItem>
                  Built-in gas mixer: The MIRI® TL has a built-in gas mixer
                  which allows users to change culture media without ordering/
                  trying costly pre-mixed gas. One can use either premixed gas
                  or pure gas.
                </ListItem>
                <ListItem>
                  Other excellent features include: Assist Function in the
                  software registers the first three divisions automatically.
                  This is a unique feature of the MIRI® TL User-friendliness of
                  MIRI® TL software provides flexibility and versatility to the
                  embryologists to add or edit events or sub-events. A special
                  dish for MIRI® TL use only, called the CultureCoin®, can
                  accommodate 14 embryos. Embryos are also easier to load using
                  the CultureCoin®.
                </ListItem>
              </UnorderedList>
            </CardBody>
          </Card>

          <Card align="center" mb={20}>
            <CardHeader>
              <Heading size="md"> The Silent Embryo Hypothesis</Heading>
            </CardHeader>
            <CardBody>
              <Text>
                Baumann et al said that “the Quiet Embryo Hypothesis proposes
                that viable preimplantation embryos operate at metabolite or
                nutrient turnover rates distributed within lower ranges than
                those of their less viable counterparts”.
              </Text>
              <br />
              <Text>
                The MIRI® TL is a multi-room incubator with a built-in
                microscope and camera that continuously captures images of
                embryo developments until the day of transfer without any
                disturbance. This enables a detailed scoring of the embryos
                cultured, for better prediction of developmental and
                implantation potential.
              </Text>
              <br />
              <Text>
                Conventional assessment of embryo selection in IVF is often
                limited to static observations at pre-defined time intervals,
                which requires the embryo to be taken out from the incubator.
                With the MIRI® TL, the dynamic process of embryo development can
                be observed without disturbances to culture conditions. Hence,
                this supports the Silent Embryo Hypothesis, which observes that
                embryos develop better if less disturbed.
              </Text>
            </CardBody>
          </Card>

          <Heading mb={20} size={"lg"}>
            Simple Guide On How To Operate The MIRI® TL
          </Heading>

          <Card mb={20}>
            <CardHeader>
              <Heading size="md">I. CultureCoin® Preparation</Heading>
            </CardHeader>
            <CardBody>
              <UnorderedList mb={10}>
                <ListItem>
                  Preparation of the CultureCoin®, the media, and oil must be
                  done a day before loading the embryos.
                </ListItem>
                <ListItem>
                  Before loading the CultureCoin® with the media and oil, see to
                  it that you have pre-equilibrated the following inside a
                  humidified incubator:
                  <ListItem>
                    The culture media and the CultureCoin® for 4 to 6 hours
                  </ListItem>
                  <ListItem>The Overlay oil for 1 to 2 days.</ListItem>
                </ListItem>

                <ListItem>
                  Always ensure that you work inside an IVF Workstation. Load
                  the CultureCoin® with the 25ul of media under the microscope.
                  (Do not inject the media directly on the bottom of the well.)
                </ListItem>
                <ListItem>
                  Fill it on its wall and remove any visible bubbles from the
                  media with a stripper tip.
                </ListItem>
                <ListItem>
                  Cover the media with 3 – 3.5 ml of pre-heated oil.
                </ListItem>
                <ListItem>
                  Put the CultureCoin® with the lid inside an incubator and
                  leave it for more than 12 hours for equilibration.
                </ListItem>
              </UnorderedList>
            </CardBody>
          </Card>

          <Card mb={20}>
            <CardHeader>
              <Heading size="md">II. Loading Of The Embryos</Heading>
            </CardHeader>
            <CardBody>
              <UnorderedList mb={10}>
                <ListItem>
                  After equilibration, check for air bubbles in the culture
                  wells. Remove any bubbles with a stripper tip.
                </ListItem>
                <ListItem>
                  Load the embryos into the center of the filled microwells with
                  a plastic stripper tip.
                </ListItem>

                <ListItem>Place the embryo at the center of the well.</ListItem>
                <ListItem>
                  Once done, carefully put the embryo loaded CultureCoin® inside
                  an unoccupied MIRI® TL chamber.
                </ListItem>
                <ListItem>
                  Select patient then select/deselect active positions and start
                  Time-Lapse when ready.
                </ListItem>
                <ListItem>
                  Put the CultureCoin® with the lid inside an incubator and
                  leave it for more than 12 hours for equilibration.
                </ListItem>
              </UnorderedList>
            </CardBody>
          </Card>

          <Card mb={20}>
            <CardHeader>
              <Heading size="md">III. Embryo Annotation</Heading>
            </CardHeader>
            <CardBody>
              <UnorderedList mb={10}>
                <ListItem>
                  On the MIRI® TL Viewer Home Screen, select “TimeLapses” and
                  look for the patient to open time-lapse videos. A revolver
                  view with the time-lapse video of each of the14 wells in the
                  CultureCoin® will be displayed.
                </ListItem>
                <ListItem>
                  Select an embryo to annotate. The embryo will be maximized at
                  the center. The annotation system is structured around the
                  “events” that are located on the left of the wheel.
                </ListItem>

                <ListItem>
                  You can also use the ASSIST tool to automatically annotate the
                  first 4 cleavage events.
                </ListItem>
              </UnorderedList>
            </CardBody>
          </Card>

          <Card mb={20}>
            <CardHeader>
              <Heading size="md">IV. Selecting The Most Viable Embryos</Heading>
            </CardHeader>
            <CardBody>
              <UnorderedList mb={10}>
                <ListItem>
                  The MIRI® TL Viewer software is equipped with sophisticated
                  tools to help you evaluate and select the most viable embryo
                  after completely annotating all embryos.
                </ListItem>
                <ListItem>
                  The Compare Tool will let you do a side-by-side comparison of
                  two embryos. Two embryo videos can be played independently or
                  linked together for comparison.
                </ListItem>

                <ListItem>
                  The “Summary” tool will allow you to compare all embryos in an
                  informative and graphical way. It will let you compare embryo
                  developmental timings and which one develops closely with the
                  ideal timings.
                </ListItem>
                <ListItem>
                  Once the selection process has been completed or a decision
                  has been made, click the green check icon in the upper left
                  corner. After clicking it, a drop-down list of the desired
                  status will appear.
                </ListItem>
                <ListItem>
                  Assign a corresponding color that indicates the decision in
                  each well: Green for “transfer”, Red for “discard”, and Blue
                  for “freeze”. The colored ring will appear around the well to
                  indicate the decision made. You can also click the “Dish Map”
                  icon to visually map the decision that has been made.
                </ListItem>
              </UnorderedList>
            </CardBody>
          </Card>
        </CardBody>
        <CardFooter>
          <Link to={"/Contact"}>
            <Button colorScheme="blue">Contact Us</Button>
          </Link>
        </CardFooter>
      </Card>
    </Container>
  );
}

export default MiriTimeLapse;
