import {
  Container,
  Heading,
  Card,
  CardHeader,
  CardBody,
  Text,
  CardFooter,
  Button,
  UnorderedList,
  ListItem,
  Box,
  SimpleGrid,
} from "@chakra-ui/react";
import React from "react";

import { Link } from "react-router-dom";

import GoToTop from "../../GoToTop/GoToTop";

function Centrifuge() {
  return (
    <Container maxW={"container.lg"}>
      <Card>
        <CardHeader>
          <Heading size="lg" bg={"blue.500"} color={"white"} p={5}>
            Centrifuge
          </Heading>
        </CardHeader>
        <CardBody>
          <Text mb={20}>
            A laboratory centrifuge is used to separate fluids based on density.
            Samples in a containing vessel are subjected to high-speed rotation
            to achieve separation.
          </Text>

          <Box mb={20}>
            <Heading fontSize={"md"} mb={5}>
              Must-Have Features To Consider:
            </Heading>
            <UnorderedList>
              <ListItem>
                Maintenance-free, brushless motor that prolongs the lifespan and
                saves maintenance cost
              </ListItem>
              <ListItem>
                Dedicated superior safety features including safety lid lock
                design, imbalance detection system, rotor-auto recognition,
                overspeed and over temperature protection
              </ListItem>
              <ListItem>
                Intelligent microprocessor control system that can display set
                and actual parameters simultaneously
              </ListItem>
              <ListItem>
                Emergency switch function enables manual opening of the lid in
                case of power failure or malfunction
              </ListItem>
              <ListItem>
                Automatic conversion of parameters: rcf to rpm and vice versa;
                Celsius to Fahrenheit and vice versa
              </ListItem>
              <ListItem>
                Real-time modification of parameters during the operation to
                improve your working efficiency
              </ListItem>
              <ListItem>
                Wide variety of available accessories that suit different
                application needs
              </ListItem>
              <ListItem>
                Easy-to-clean external surface coated with Isocide™ to eliminate
                99.9% of surface bacteria within 24 hours of exposure
              </ListItem>
              <ListItem>
                Integrated design of interior centrifuge chamber
              </ListItem>
              <ListItem>
                High-temperature control range for refrigerated models (-20°C to
                +40°C)
              </ListItem>
              <ListItem>
                Fast pre-cooling function to cool the chamber temperature before
                starting the run
              </ListItem>
              <ListItem>
                Environment-friendly CFC- and HCFC-free refrigerants
              </ListItem>
            </UnorderedList>
          </Box>

          <Box mb={20}>
            <Text mb={10}>
              <Text as={"span"} color={"blue.500"} fontWeight={"extrabold"}>
                Micro centrifuge
              </Text>{" "}
              Micro centrifuge is a compact type of centrifuge ideal for
              separating small liquid samples at high speed (usually more than
              6,000 rpm). Most micro centrifuge have available PCR strips rotor,
              adapters for 0.2 ml PCR tubes, and microhematocrit rotor.
            </Text>

            <Text mb={10}>
              <Text as={"span"} color={"blue.500"} fontWeight={"extrabold"}>
                Tabletop centrifuge
              </Text>{" "}
              Tabletop centrifuge is sometimes referred to as benchtop
              centrifuge. It is a larger type of centrifuge ideal for separating
              larger liquid samples at high speed (usually more than 4,000 rpm).
              While micro centrifuges run liquid samples using fixed-angle rotor
              only, most tabletop centrifuge have another type of rotor called
              swing-bucket. This type of rotor can spin samples up to 90° angle
              at lower speed. Tabletop centrifuge is commonly used in running
              various sample tubes at once.
            </Text>
          </Box>

          <SimpleGrid
            spacing={4}
            templateColumns={[
              "repeat(auto-fill, minmax(200px, 1fr))",
              "repeat(auto-fill, minmax(350px, 1fr))",
            ]}
          >
            <Card>
              <CardHeader>
                <Heading size="md" color={"blue.500"}>
                  {" "}
                  Versati™ Micro Ventilated Centrifuge
                </Heading>
              </CardHeader>
              <CardBody>
                <UnorderedList>
                  <ListItem>Small capacity liquid samples</ListItem>
                  <ListItem>Ambient temperature</ListItem>
                  <ListItem>Audio and visual notification system</ListItem>
                  <ListItem>Superior safety features</ListItem>
                  <ListItem>
                    Time-setting spin up to 99 hrs 59 min 10sec
                  </ListItem>
                  <ListItem>Dimensions (WxDxH): 280 x 389 x 304 mm</ListItem>
                </UnorderedList>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <Heading size="md" color={"blue.500"}>
                  {" "}
                  Versati™ Micro Refrigerated Centrifuge
                </Heading>
              </CardHeader>
              <CardBody>
                <UnorderedList>
                  <ListItem>Small capacity liquid samples</ListItem>
                  <ListItem>Refrigerated (-20°C to 40°C)</ListItem>
                  <ListItem>Audio and visual notification system</ListItem>
                  <ListItem>Superior safety features</ListItem>
                  <ListItem>
                    Time-setting spin up to 99 hrs 59 min 10sec
                  </ListItem>
                  <ListItem>Dimensions (WxDxH): 280 x 563 x 294 mm</ListItem>
                </UnorderedList>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <Heading size="md" color={"blue.500"}>
                  {" "}
                  Versati™ Tabletop Ventilated Centrifuge
                </Heading>
              </CardHeader>
              <CardBody>
                <UnorderedList>
                  <ListItem>Large capacity liquid samples</ListItem>
                  <ListItem>Ventilated temperature</ListItem>
                  <ListItem>Audio and visual notification system</ListItem>
                  <ListItem>Superior safety features</ListItem>
                  <ListItem>
                    Available fixed-angle and swing-bucket rotors
                  </ListItem>
                  <ListItem>
                    Time-setting spin up to 99 hrs 59 min 10sec
                  </ListItem>
                  <ListItem>Dimensions (WxDxH): 438 x 532 x 374 mm</ListItem>
                </UnorderedList>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <Heading size="md" color={"blue.500"}>
                  {" "}
                  Versati™ Tabletop Refrigerated Centrifuge
                </Heading>
              </CardHeader>
              <CardBody>
                <UnorderedList>
                  <ListItem>Large capacity liquid samples</ListItem>
                  <ListItem>Refrigerated (-20°C to 40°C)</ListItem>
                  <ListItem>Audio and visual notification system</ListItem>
                  <ListItem>Superior safety features</ListItem>
                  <ListItem>
                    Available fixed-angle and swing-bucket rotors
                  </ListItem>
                  <ListItem>Dimensions (WxDxH): 715 x 528 x 373 mm</ListItem>
                </UnorderedList>
              </CardBody>
            </Card>
          </SimpleGrid>
        </CardBody>
        <CardFooter>
          <Link to={"/Contact"}>
            <Button colorScheme="blue">Contact Us</Button>
          </Link>
        </CardFooter>
      </Card>

      <GoToTop />
    </Container>
  );
}

export default Centrifuge;
